.carPropertyGroupCheckboxes {
    margin-bottom: 32px;
}
.carPropertyGroupCheckboxesHeading {
    margin: 0px 0 8px 0;
}

.carPropertyGroupCheckboxesElement {
    margin-bottom: 8px;
}
.carPropertyGroupCheckboxesElement__text {
    margin-left: 8px;
    padding-top: 1px;
}
