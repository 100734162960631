.blockingReservationInfo {
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid;
}
.blockingReservationInfo__heading {
    padding-bottom: 8px;
}
.blockingReservationInfo__body {
}
