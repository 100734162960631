.carImage {
    min-width: 64px;
    width: 128px;
    max-width: 100%;
    min-height: 72px;
    object-fit: contain;
    height: 72px;
}

.carImage--big {
    width: 128px;
    height: 96px;
}

/*MOBILE NARROW*/
@media (max-width: 300px) {
    .carImage {
        display: none;
    }
}
