.adminEditUserModal {
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.adminEditUserModal h4 {
    text-align: center;
}
