.startAndEndDateAndTimeSelectDropDown {
    cursor: default;
}

.startAndEndDateAndTimeSelectDropDown__toggleButton {
    height: 24px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    padding: 0 8px;
}

.startAndEndDateAndTimeSelectDropDown__dropdown {
    position: absolute;
    margin-top: 24px;
    border-radius: 5px;
    float: right;
}

.startAndEndDateAndTimeSelectDropDown__content {
    position: relative;

    border-radius: 5px;
    padding: 1px 8px 4px 8px;
    min-width: 320px;
}

.startAndEndDateAndTimeSelectDropDown__dateAndTime {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0 4px 0;
}

.startAndEndDateAndTimeSelectDropDown__reset,
.startAndEndDateAndTimeSelectDropDown__clear {
    position: absolute;
    top: 8px;
}

.startAndEndDateAndTimeSelectDropDown__clear {
    left: 12px;
}

.startAndEndDateAndTimeSelectDropDown__reset {
    right: 12px;
}

.startAndEndDateAndTimeSelectDropDown__clickOutsideDetector {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.startAndEndDateAndTimeSelectDropDown__selectors {
    display: flex;
}

.startAndEndDateAndTimeSelectDropDown__dateSelect {
    position: relative;
    width: 176px;
    height: 40px;
    margin-right: 8px;
    border: 1px solid;
    border-radius: 4px;
}

.startAndEndDateAndTimeSelectDropDown___timeSelect {
    height: 40px;
    width: 116px;
    border: 1px solid;
    border-radius: 4px;
}
