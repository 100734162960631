:root {
    --button_primary: #5e7f02;
    --button_primary__dark: #4e6900;

    --redButton: #b13838;
    --redButton__hover: #932e2e;

    --purple__accent: #51289d;
    --purple--lighter: #693bae;
    --purple-softer: #7a54b6;
    --purple__accentTransparent: #51289d40;

    --purple_button: var(--purple--lighter);
    --purple_button--hover: #583098;

    --black: #333333;
    --light-pink: #ffa4a6;
    --red__dark: #b13838;
    --red__darker: #932e2e;
    --red: #b41717;
    --bright-red: #ff0000;
    --red-semi-transparent: #8c0000ee;
    --red-transparent: #8c000030;
    --orange__darker: #a8571c;
    --orange__dark: #db7300;
    --orange__light: #fad4b0;
    --orange__text: #4a2f29;
    --yellow__dark: #b38600;
    --yellow: #cc9e00;
    --bright-yellow: #ffe434;

    --grey: #b2b2b2;
    --grey__mediumDark: #939393;
    --grey__dark: #656565;
    --grey__darker: #3d4d5b;
    --grey__mediumLight: #e0dfdf;
    --grey__light: #f2f2f2;
    --grey__lighter: #f7f7f7;
    --grey__transparent: rgba(94, 94, 111, 0.1);
    --grey__semitransparent: rgba(94, 94, 111, 0.2);

    --halfOpaqueWhite: rgba(255, 255, 255, 0.5);
    --white__transparent: rgba(255, 255, 255, 0.1);

    --typographyBlack: rgba(0, 0, 0, 0.87);
    --typographyBlack__Softer: rgba(0, 0, 0, 0.7);
    --typographyBlack__MediumEmphasis: rgba(0, 0, 0, 0.6);
    --typographyWhite: #ffffff;
    --typographyWhite__MediumEmphasis: rgba(255, 255, 255, 0.7);
    --associated_info_text: #696969;

    --text_available_green_transparent: #5d7f0135;
    --text_available_green: #5d7f01;
    --background_available_green: #8bbe00;
    --background_light_green: #dcebb3;
    --background_lighter_green: #eeffd9;

    --links_and_clickables-transparent: #1c6eb14d;
    --links_and_clickables-light: #0070c8;
    --links_and_clickables: #016cc5;
    --links_and_clickables__hover: #01569e;

    --medium-light-blue: rgba(32, 152, 251, 0.2);
    --light-blue: rgba(32, 152, 251, 0.1);
    --lighter-blue: rgba(32, 152, 251, 0.05);
    --lightest-blue: rgba(32, 152, 251, 0.02);

    --medium-blue: var(--links_and_clickables-light);
    --blueish-white: #ecf7ff;

    --disabled_background: rgba(94, 94, 111, 0.1);

    --soft-border: rgba(0, 0, 0, 0.05);
    --soft-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.12);

    --vipps-orange-60: #db460f;
    --vipps-orange-50: #ff5b24;
    --vipps-orange-30: #ff985f;

    --soft-green: #d3e7b5;
    --soft-red: #e4b2b2;
    --soft-orange: #e4b972;
    --soft-yellow: #f4e1a6;

    --softer-green: #e1edd1;
    --softer-red: #eccccb;
    --softer-orange: #edd4a2;
    --softer-yellow: #f8ecd1;

    --soft-yellow-hover: #e3cc8f;

    --alert_error: #b41717;
}
