.editUser {
    position: relative;
    padding: 16px 20px;
    margin: 0 auto;
    max-width: 600px;

    display: flex;
    flex-direction: column;
}

.editUser .closeModalButton {
    position: absolute;
    top: -12px;
    left: -16px;
}

.editUser__header {
    text-align: center;
    margin-bottom: 20px;
}

.editUser__inputField {
    min-height: 76px;
    margin-top: 20px;
}

.editUser__passwordInput {
    margin-top: 12px;
}

@media (min-width: 450px) {
    .editUser {
        padding: 16px 32px;
    }
}

@media (min-width: 1136px) {
    .editUser {
        z-index: 0;
    }
}

@media (max-width: 1136px) {
    .editUser .closeModalButton {
        top: -12px;
        left: -16px;
    }
}
