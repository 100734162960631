.info-block {
    background-color: var(--light-blue);
    border-radius: 8px;
    width: 100%;
}

.warning-block {
    background-color: var(--orange__light);
    border: 1px solid var(--orange__dark);
    border-radius: 8px;
    width: 100%;
}

.info-block__close,
.info-block__spacer {
    position: relative;
    top: 1rem;
    left: 1rem;
}

.info-block__children {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
}

.block__children--noPaddingTop {
    padding-top: 0;
}

@media screen and (max-width: 330px) {
    .info-block__children {
        padding: 1rem;
    }
}
