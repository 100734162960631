.searchPageDesktopTimeAndDateSelectors {
    display: flex;
    margin-bottom: 2px;
}
/*dateAndTimeSelectPage_dateAndTime__selectors*/

.searchPageDesktopTimeAndDateSelectors__dateSelect {
    position: relative;
    width: 11rem;
    height: 2.5rem;
    margin-right: 8px;
    border: 1px solid;
    border-radius: 4px;
    background: white;
}

.searchPageDesktopTimeAndDateSelectors__timeSelect {
    height: 2.5rem;
    width: 7.25rem;
    border: 1px solid;
    border-radius: 4px;
    background: white;
}

.searchPageDesktopTimeAndDateSelectors__default {
    border-color: #b2b2b2;
}

.searchPageDesktopTimeAndDateSelectors__focused {
    border-color: #0375d4;
}

.searchPageDesktopTimeAndDateSelectors__disabled {
    border-color: #e6e6e6;
    background: #eeeeee;
}
