.reservationMessage {
    margin-top: 8px;
    padding: 0.75rem;
    display: flex;
    justify-content: space-evenly;
    text-decoration: none;
    border-radius: 4px;
    flex-direction: column;
}

.reservationMessage:hover,
.reservationMessage {
    color: var(--typographyBlack__MediumEmphasis);
}

.reservationMessage--red {
    background-color: var(--softer-red) !important;
}

.reservationMessage--red:hover {
    background-color: var(--soft-red) !important;
}

.reservationMessage--orange {
    background-color: var(--softer-orange) !important;
}

.reservationMessage--orange:hover {
    background-color: var(--soft-orange) !important;
}

.reservationMessage--yellow {
    background-color: var(--soft-yellow) !important;
    color: var(--typographyBlack) !important;
}

.reservationMessage--yellow:hover {
    background-color: var(--soft-yellow-hover) !important;
}

.reservationMessage__heading {
    font-weight: bold;
    display: flex;
}

.reservationMessage__body {
    margin-top: 4px;
}
