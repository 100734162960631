.oldTerminal {
    display: flex;
    height: 28px;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;

    border-color: var(--links_and_clickables-light);
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
}

@media (max-width: 340px) {
    .oldTerminal svg {
        height: 20px;
        width: 20px;
    }
}
