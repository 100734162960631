.pictureWithText {
    position: relative;
    border-radius: 5px;
}

.pictureWithText .pictureWithText__thumbNail,
.pictureWithText figure {
    width: 100%;
}

.pictureWithText--small figure,
.pictureWithText--small .pictureWithText__input,
.pictureWithText--small .pictureWithText__thumbNail {
    width: 150px;
}

.pictureWithText--small .pictureWithText__input {
    height: 32px;
}

.pictureWithText--small .pictureWithText__input .inputField__input {
    height: 30px;
}

.pictureWithText--small .pictureWithText__thumbNail,
.pictureWithText--medium .pictureWithText__thumbNail {
    height: 150px;
}

.pictureWithText__thumbNail {
    object-fit: cover;
    cursor: pointer;
}

.pictureWithText__text {
    width: 150px;
    text-wrap: normal;
    text-align: center;
}

.pictureWithText figcaption.pictureWithText__text {
    border: 1px solid var(--grey);
    border-radius: 0 0 5px 5px;
    margin-top: -4px;
    padding: 4px 2px;
}

.pictureWithText__buttonsWrapper {
    position: absolute;
    top: 0;
    right: 0;
}

.pictureWithText__deleteButton,
.pictureWithText__downloadButton {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    width: 40px;
    background-color: var(--halfOpaqueWhite);
}

.pictureWithText__bottomButton {
    border-radius: 0 0 0 5px;
}

.pictureWithText__deleteButton:hover,
.pictureWithText__downloadButton:hover {
    background-color: var(--typographyWhite);
}

.closeFullscreenViewButton {
    position: absolute;
    top: 0;
    left: 0;
}

.pictureWithText__fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 4;
    overflow: auto;
}

.pictureWithText__fullScreenImgWrapper {
    position: relative;
    max-width: 100vw;
    max-height: 100vh;
}

.pictureWithText__fullScreenImgWrapper img {
    max-width: 100%;
    max-height: 100%;
}

.pictureWithText__input .inputField__helpText {
    display: none;
}

.pictureWithTextDeleteConfirmationModal__content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 2rem;
}

.pictureWithTextDeleteConfirmationModal__picture,
.pictureWithTextDeleteConfirmationModal__picture img {
    max-height: 220px;
    max-width: 400px;
}

.pictureWithTextDeleteConfirmationModal__buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    align-self: flex-end;
}

.pictureWithTextDeleteConfirmationModal__buttonWrapper .secondaryButton {
    margin-right: 4px;
}

.pictureWithTextDeleteConfirmationModal__buttonWrapper .secondaryButton,
.pictureWithTextDeleteConfirmationModal__buttonWrapper .primaryButton {
}

.pictureWithText__uploadOverlay {
    background-color: rgba(255, 255, 255, 0.5);
}

.pictureWithText__description {
    margin-top: 1rem;
}

.pictureWithText__uploadOverlay,
.pictureWithText__uploadAnimation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@media (min-width: 1136px) {
    .pictureWithText--medium figure,
    .pictureWithText--medium .pictureWithText__input,
    .pictureWithText--medium .pictureWithText__thumbNail {
        width: 234px;
    }
}
