.sharedReservation__loaderWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.sharedReservation__loader {
    height: 200px;
    width: 200px;
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .sharedReservationPage {
        position: relative;
        margin: auto;
        right: unset;
    }
}

/*MOBILE*/
@media (max-width: 1136px) {
    .header--showingSharedReservation {
        display: none;
    }
}
