.loadingPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 48px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .loadingPage {
        top: 176px;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
