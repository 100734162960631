.adminPersonRoleComponent__radioGroup {
    display: flex;
    align-items: center;
    /*padding: 16px 0;*/
    flex-wrap: wrap;
}

.adminPersonRoleComponent__radioGroup > label {
    margin-right: 1.5rem;
}
