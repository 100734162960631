.inputField {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: relative;
}

.inputField--widthUnset {
    width: unset;
}

.inputField--marginLeft {
    margin-left: 16px;
}

.inputField__label {
    position: absolute;
    top: -7px;
    left: 5px;
    background: white;
    padding: 0 4px;
    font-family: Lato;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 100%;
    letter-spacing: 0.4px;
    color: var(--associated_info_text);
}

.inputField__static .inputField__label,
.inputField__static .inputFieldSelector__menu {
    z-index: 1;
}

.inputField__input {
    height: 56px;
    width: 100%;
}

.inputField__input input::placeholder,
.inputField__input textarea::placeholder {
    color: #b2b2b2;
    opacity: 0.6; /* Firefox */
    line-height: 1.125rem;
    font-size: var(--14px);
    padding-top: 4px;
}

.inputField__input input:-ms-input-placeholder,
.inputField__input textarea:-ms-input-placeholder {
    color: #b2b2b2;
}

.inputField__input
    input::-ms-input-placeholder
    .inputField__input
    textarea::-ms-input-placeholder {
    color: #b2b2b2;
}

.inputField__input--small {
    height: 46px;
}

.inputField__input--big {
    height: 88px;
}

.inputField__input--big .inputFieldSelector__value-container {
    height: 88px;
}

.inputField__input--textArea {
    height: 88px;
}

.inputField__input--bigTextArea {
    height: 400px;
}

.inputField_select {
}
.inputField__text {
    width: 100%;
    height: 100%;
    border: 1px solid;
    border-radius: 4px;
    padding-left: 8px;
    outline: none;
    padding-top: 4px;
    line-height: 1.125rem;
}

.inputField__text--readOnly {
    background-color: var(--disabled_background);
}

.inputField__number--noSpinner::-webkit-outer-spin-button,
.inputField__number--noSpinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.inputField__number--noSpinner[type='number'] {
    -moz-appearance: textfield;
}

.inputField__text--area {
    padding-top: 16px;
    line-height: 1.75rem;
}

.inputField__date {
    width: 176px;
    height: 100%;
    border: 1px solid;
    border-radius: 4px;
    outline: none;
    position: relative;
    cursor: pointer;
}

.inputFieldDateInput {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /*padding-left: 8px;*/
    z-index: 1;
}

.inputFieldDateInput__icon {
    position: absolute;
    left: 16px;
}

.inputFieldDateInput > input {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 4px;
    padding-left: 40px;
    outline: none;
    background-color: unset;
}

.inputFieldDateSelect {
    position: absolute;
    top: 8px;
    left: 0;
}

.inputField__time {
    width: 114px;
    height: 100%;
    border: 1px solid;
    border-radius: 4px;
    outline: none;
    position: relative;
    cursor: pointer;
}

.inputFieldTimeSelect {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.inputFieldTimeSelect__icon {
    padding-left: 16px;
}

.inputFieldTimeSelect__selectWrapper {
    width: 100%;
    height: 100%;
}

.inputField__helpText {
    margin: 8px 0 0 8px;
    height: 12px;
}

/*react-select overrides*/
.inputFieldTimeSelectSelector__control {
    border: none !important;
    box-shadow: none !important;
    background: none !important;
    height: 100%;
}

.inputFieldTimeSelectSelector__value-container {
    font-size: var(--16px);
    line-height: 1.375rem;
    letter-spacing: 0.5px;
    padding: 0 0 0 10px !important;
}
.inputFieldTimeSelectSelector__indicators {
}
.inputFieldTimeSelectSelector__indicator-separator {
    display: none;
}

.inputFieldTimeSelectSelector__dropdown-indicator {
    padding-left: 0 !important;
}
.inputFieldTimeSelectSelector__menu {
    top: 32px !important;
    left: -20px !important;
    border-radius: 0px !important;
    width: 114px !important;
    z-index: 3 !important;
}
.inputFieldTimeSelectSelector__menu-list {
}
.inputFieldTimeSelectSelector__option {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 31px !important;

    /*font-family: Lato;*/
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.4px;
    color: rgb(51, 51, 51);
    font-size: var(--16px) !important;
}

.inputFieldTimeSelectSelector__select {
    width: 84px;
    border: none !important;
    /*border-radius: 0px;*/
}

/* React dates overrides */

.inputFieldDateSelect .SingleDatePicker_picker {
    left: 0 !important;
    top: 48px !important;
    z-index: 3;
}
/*To overwrite react-select styling*/
.inputField__select {
    height: 100%;
}
.inputFieldSelector__control {
    /*cursor: text !important;*/
    cursor: pointer !important;
    height: 100% !important;
    /*border-color: #B2B2B2!important;*/
}
.inputFieldSelector__control:hover {
    border-color: #737272 !important;
}

.inputFieldSelector__control--is-focused {
    box-shadow: 0 0 0 1px #016cc5 !important;
}

.inputFieldSelector__control--is-focused:hover {
    border-color: #016cc5 !important;
}

.inputFieldSelector__menu {
    z-index: 3 !important;
}

.inputFieldSelector__menu-list {
    max-height: 200px !important;
}

.inputFieldSelector__indicator {
    cursor: pointer;
}

.inputFieldSelector__placeholder {
    color: #b2b2b2 !important;
    opacity: 0.6 !important; /* Firefox */
    line-height: 1.125rem !important;
    font-size: var(--14px) !important;
    padding-top: 4px !important;
}
