.offlineAlertWrapper {
    position: fixed;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;

    display: flex;
    justify-content: center;
    z-index: 10000;
}

.offlineAlert {
    /*background-color: var(--blueish-white);*/
    background-color: var(--red-semi-transparent);
    padding: 0.5rem 1rem;
    border-radius: 8px;
    box-shadow: 0 14px 14px rgba(0, 0, 0, 0.55);
    text-align: center;
}

.offlineAlert p {
    color: var(--typographyWhite) !important;
}
