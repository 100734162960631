.profileMenuButton {
    position: relative;
    padding: 0.2rem;
}

.profileMenuButton:focus-visible {
    outline-color: white;
}

.profileMenuButton .membershipStateIndicator {
    position: absolute;
    top: -1px;
    right: -1px;
}

.profileMenuButton__desktopArrow {
    position: absolute;
    right: -20px;
    top: 26px;
}

.profileMenu {
    position: fixed;
    top: 45px;
    right: 10px;
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.25);
}

.profileMenu__triangle {
    position: fixed;
    top: 42px;
    right: 28px;
    background-color: white;
    height: 8px;
    width: 8px;
    transform: rotate(45deg);
}

.profileMenuOption__membershipName {
    text-align: left;
    max-width: calc(100% - 40px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.profileMenuOption__membershipName--nonActive {
    width: 50%;
}

.profileMenuOption__noMembershipWarning {
    background-color: white;
    padding: 16px;
}

.profileMenuOption svg {
    margin: 0 8px;
}

.profileMenuOption__logoutLoginButtonText {
    margin-left: 32px;
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .profileMenu {
        top: 60px;
    }

    .profileMenu__triangle {
        top: 57px;
    }
}
