.closeModalButton {
    width: 3rem;
    height: 3rem;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
    border-radius: 80px;
    z-index: 1;
}

.closeModalButton:hover {
    background-color: transparent;
}

.closeModalButton:hover path {
    fill: var(--black);
}
