.adminProducts {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adminProducts th:not(.active) {
    text-align: left;
}

.adminProducts__products {
    column-gap: 8px;
}

.adminProducts th,
.adminProducts td {
    padding: 8px;
}

.adminProducts td {
    line-height: 16px;
}

.adminProduct__data {
    border: 1px solid;
    border-radius: 4px;
}

.adminProduct__data:not(:last-child) {
    border-bottom: none;
}

.adminProducts__products .active {
    position: relative;
}

.adminProducts__products .vatPercent {
    text-align: right;
}

.adminProducts__products .active > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
