.adminBillingLine {
    height: 40px;
    border-radius: 4px;
    width: 100%;
    margin: 4px 0;
}

.adminBillingLine--header,
.adminBillingLine__content {
    display: flex;
    width: calc(100% - 40px);
}

.adminBillingLine--header {
    margin-left: 40px;
}

.adminBillingLine--cancelField {
    width: 40px;
    height: 40px;
    display: flex;
}

.adminBillingLine__button {
    cursor: pointer;
    background-color: transparent;
    border-radius: 4px;

    align-items: center;
    justify-content: center;
}

.adminBillingLine__quantity .adminBillingLine__element__readOnly,
.adminBillingLine__element--vatPercent {
    justify-content: flex-end;
}

.adminBillingLine__button:hover {
    background-color: pink;
}

.adminBillingLine {
    display: flex;
}

.adminBillingLine__element {
    display: flex;
    align-items: center;
    padding: 0 8px;
}

.adminBillingLine__element--invoiced {
    padding-right: 16px;
}

.adminBillingLine__element--invoiced a {
    margin-bottom: 0;
}

.adminBillingLine__element--productNumber {
    flex: 0 0;
    min-width: 102px;
}

.adminBillingLine__element--description {
    flex: 10 10;
}

.adminBillingLine__element--quantity {
    flex: 1 1;
}

.adminBillingLine__element--price {
    flex: 2 2;
}

.adminBillingLine__element--vatPercent {
    flex: 1 1;
}

.adminBillingLine__element--sum {
    flex: 2 2;
}

.adminBillingLine__element--author {
    flex: 2;
    overflow: hidden;
}

.adminBillingLine__element--invoiced {
    flex: 2 2;
}

.adminBillingLine__element--sum,
.adminBillingLine__element--price,
.adminBillingLine__element--quantity,
.adminBillingLine__element--invoiced {
    justify-content: flex-end;
}

.adminBillingLine__inputPrefix {
    margin-right: 4px;
}

.adminBillingLine__inputSuffix {
    margin-left: 4px;
}

.adminBillingLine__invoiceIdIndicator {
    height: 16px;
    margin-right: 24px;
}

.adminBillingLine__element--invoiced .adminBillingLine__input,
.adminBillingLine__element--price .adminBillingLine__input,
.adminBillingLine__element--vatPercent .adminBillingLine__input,
.adminBillingLine__element--quantity .adminBillingLine__input {
    text-align: right;
}

.adminBillingLine__element input[type='number']::-webkit-inner-spin-button,
.adminBillingLine__element input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.adminBillingLine__optionInput,
.adminBillingLine__optionInput .inputField__input,
.adminBillingLine__optionInput
    .inputField__input
    .inputField__select
    .inputFieldSelector__control {
    min-height: 33px;
    height: 33px;
}

.adminBillingLine__optionInput
    .inputField__input
    .inputField__select
    .inputFieldSelector__control
    .inputFieldSelector__indicators
    .inputFieldSelector__dropdown-indicator {
    width: 30px;
}

.adminBillingLine__optionInput
    .inputField__input
    .inputField__select
    .inputFieldSelector__menu {
    z-index: 1 !important;
    width: 300px;
}

.adminBillingLine__input {
    margin: 0;
    width: 100%;
    padding: 0 4px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px;
    outline: none;
}

.adminBillingLine__invoicedIndicator {
    margin-right: 16px;
}

.adminBillingLine__optionInput--problem .inputFieldSelector__control,
.adminBillingLine__optionInput--problem .inputFieldSelector__control:hover {
    border-color: red;
}

.adminBillingLine__optionInput--problem
    .inputFieldSelector__control--is-focused {
    box-shadow: 0 0 0 1px red;
}

.Select.is-focused:not(.is-open) {
    border-color: #6ecc39;
}
