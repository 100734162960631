.animatedChevron {
    display: flex;
    justify-content: center;
    align-items: center;
}

.animatedChevron svg {
    transition: transform 300ms linear;
}

.animatedChevron--open svg {
    transform: rotateX(180deg);
}

.animatedChevron--white svg path {
    fill: var(--typographyWhite);
}

.animatedChevron--charcoal svg path {
    fill: var(--grey__darker);
}
