.fuelLevel {
    padding: 4px 4px;
    max-height: 28px;

    font-size: var(--15px);
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;

    border-color: var(--links_and_clickables-light);
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
}

.fuelLevel svg path {
    height: 14px;
    width: 14px;
    margin-top: -1px;
    fill: var(--links_and_clickables-light);
}

@media (max-width: 340px) {
    .fuelLevel {
        font-size: 0.8rem;
    }

    .fuelLevel svg {
        height: 12px;
        width: 12px;
    }
}
