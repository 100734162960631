.inviteMemberModal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.inviteMemberModal__header {
    align-self: center;
}

.inviteMemberModal__infoText {
    margin-top: 8px;
}

.inviteMemberModal__input {
    margin-top: 16px;
}

.inviteMemberModal__buttonsWrapper {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: flex-end;
    gap: 1.5rem;
}
