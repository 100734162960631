.flashMessage {
    position: fixed;
    top: 0;
    right: 0;

    width: 300px;

    z-index: 9001;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 48px 20px 20px 20px;
    border-radius: 0 0 0 5px;
    opacity: 1;
    transition: right 500ms, opacity 500ms;

    color: white;
    font-weight: bold;
}

.flashMessage__closeButton {
    position: absolute;
    top: 4px;
    left: 4px;
    background-color: white;
    width: 30px;
    height: 30px;
    border-radius: 3px;
}

.flashMessage.hidden {
    right: -300px;
    opacity: 0;
}
