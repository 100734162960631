.closableViewWithButtonArea {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.closableViewWithButtonArea__heading {
    min-height: 48px;
}

.closableViewWithButtonArea__rightHeaderContent {
    position: absolute;
    top: 0;
    right: 16px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closableViewWithButtonArea__title {
    text-align: center;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closableViewWithButtonArea__content {
    flex: 1;
    overflow: auto;
    padding: 0 1rem 1rem 1rem;
}

.closableViewWithButtonArea__buttonArea {
    margin-top: 2rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 16px;
    background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 1)
    );

    z-index: 3;
    pointer-events: none;
}

.closableViewWithButtonArea__buttonArea > * {
    pointer-events: auto;
}
