.reservationSwapCar {
    padding: 16px 20px 0 20px;
    width: 100%;
    background: white;
}

.reservationSwapCar--admin {
    padding: 64px 32px 0 32px;
}

.reservationSwapCarHeader {
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 32px;
    margin-bottom: 5px;
}

.reservationSwapCarHeader__backButton {
    text-decoration: underline;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.reservationSwapCarContent {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 'filters carList carList settings';
}

.reservationSwapCarContent__settings {
    grid-area: settings;
}

.reservationSwapCarContent__filters {
    grid-area: filters;
}

.reservationSwapCarContent__carList {
    grid-area: carList;
}

.reservationSwapCar_carsOverview p {
    margin-bottom: 1.5rem;
}

.reservationSwapCarOptionsWrapper {
    width: 100%;
    flex: 1;
    margin-top: 1rem;
}

.reservationSwapCarOption {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 2rem 0;
}

.reservationSwapCar_alertMember {
    margin: 20px 0;
}

.reservationSwapCarAlertMember__checkWrapper {
    display: flex;
}

.reservationSwapCarAlertMember__checkLabel {
    margin-left: 8px;
}

.reservationSwapCar_alertMember .inputField {
    margin-top: 16px;
    width: calc(100% - 20px);
}

.reservationSwapCarOptionText {
    margin-bottom: 16px;
}

.reservationSwapCarOptionText__input {
    background: #ffffff;
    box-sizing: border-box;

    border: 1px solid #cccccc;

    border-radius: 4px;
    width: calc(100% - 20px);
    height: 80px;
}

.reservationSwapCar .primaryButton {
    margin-bottom: 20px;
}

.swapCar__loaderWrapper {
    height: 50px;
    width: 50px;
    margin: 0 auto;
}

.reservationSwapCar .carList__listItemsWrapper {
    margin: 0;
    padding: 0;
}

.reservationSwapCar .desktopSearchParameters {
    padding: 1rem 10px;
    margin: 0;
}

.reservationSwapCar .carList__carLocationHeader--sticky {
    top: 0;
}

.radioButtonLabel {
    padding-top: 2px;
    cursor: pointer;
}

.reservationSwapCar__filtersButton {
    margin-left: 20px;
}

.reservationSwapCar__filtersButton svg {
    margin-right: 5px;
}

.swapCar__filterButtonContainer {
    margin-bottom: 16px;
}

.reservationSwapCarContent .carList__listItemsWrapper {
    padding-bottom: 40px;
}

.swapCarFeedbackModal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
}

.swapCarWaiverModal__content {
    background-color: var(--typographyWhite);
    margin: 1rem;
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}

.swapCarWaiverModal__content h5 {
    margin-bottom: 1rem;
}

.swapCarWaiverModal__buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.swapCarWaiverModal__buttons > button {
    margin: 0 8px 20px 8px;
}

.swapCar__problemForm {
    position: relative;
    max-height: 500px;
    overflow: hidden;
    margin-bottom: 20px;
    margin-top: -32px;
}

.swapCar__problemForm .closableViewWithButtonArea {
    position: relative;
}

.swapCar__problemForm .closableViewWithButtonArea__content {
    padding: 0;
    max-height: 500px;
}

.swapCar__problemForm .editReservationMessage {
    margin: 0 12px;
}

.swapCar__problemForm .editReservationMessage .button_4 {
    margin-bottom: 16px;
}

@media (min-width: 1136px) {
    .reservationSwapCarContent {
        overflow: auto;
    }

    .reservationSwapCarContent__settingsWrapper {
        top: 0;
    }

    .reservationSwapCar .carList__listItemsWrapper {
        min-height: calc(100vh - 144px);
        margin-bottom: 8px;
    }

    .reservationSwapCar--admin .carList__listItemsWrapper {
        min-height: calc(100vh - 192px);
    }
}

@media (max-width: 1136px) {
    .reservationSwapCar {
        padding: 0.8rem 0 0 0;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
    }

    .reservationSwapCarHeader {
        margin-bottom: 0;
    }

    .reservationSwapCarHeader__backButton {
        left: 16px;
    }

    .reservationSwapCarContent__filters,
    .reservationSwapCarContent__settings {
        padding: 0 32px;
    }

    .swapCar__confirmButtonContainer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 80px;

        background: linear-gradient(
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 1)
        );
    }

    .reservationSwapCarContent .carList__listItemsWrapper {
        padding-bottom: 80px;
    }

    .reservationSwapCarContent {
        grid-template-columns: 1fr;
        grid-template-areas:
            'settings'
            'filters'
            'carList';
    }

    .reservationSwapCar_alertMember .inputField,
    .reservationSwapCarOptionText__input {
        width: 100%;
    }
}
