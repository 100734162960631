.calendarNavArrow {
    background: white;
}

.calendarNavArrow--right {
}

.calendarWrapper {
    display: flex;
    justify-content: center;
}
