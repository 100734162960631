.popupCarIcon {
    width: 140px;
    height: 50px;
}

.mapChooserContent {
    position: fixed;
    height: 100%;
    width: 100%;
}

.mapChooserContent__cityBikesButton {
    position: absolute;
    top: 2px;
    right: 120px;
    z-index: 1;

    height: 38px;
    width: 38px;
    padding: 0;

    outline: none;

    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
}

.mapChooserContent__cityBikesButton:hover {
    background-color: white;
    border: 2px solid var(--links_and_clickables);
}

.mapChooserContent__cityBikesButton svg path {
    fill: var(--links_and_clickables-light);
}

.mapChooserContent__cityBikesButton svg {
    height: 25px;
    width: 25px;
}

.mapChooserContent__cityBikesButton:hover svg path {
    fill: var(--links_and_clickables);
}

.mapChooserContent__cityBikesButton--on {
    border: 2px solid var(--links_and_clickables-light);
}

.mapChooserContent__cityBikesButton--off svg path {
    fill: var(--grey);
}

.mapChooserContent__cityBikesButton--off:hover svg path {
    fill: var(--grey__mediumDark);
}

.leaflet-div-icon {
    background: none !important;
    border: none !important;
}

.carMarker {
    /*background-color: rgba(255, 105, 180, 0.55);*/
    width: 50px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.carMarker svg {
    z-index: 1;
    position: absolute;
    bottom: -6px;
}

.carMarker svg.partiallyAvailable .first,
.carMarker svg.unavailable .first {
    fill: #e2c500;
}

.carMarker svg.unavailable .second {
    fill: #e2c500;
}

.carMarker__marker {
    position: relative;
    top: 18px;
    left: 0;
    width: 40px;
}

.carMarker__img {
    width: 50px !important;
    margin-top: -10px !important;
    z-index: 201 !important; /*leaflet svgs are 200*/
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .mapChooserContent {
        position: static;
        height: 100%;
        width: 100%;
    }

    .carMarker svg.selected .strokePath {
        stroke: var(--links_and_clickables-light);
        stroke-width: 3;
    }
}
