.linkPartnerSelect {
    padding: 16px 0;
    width: 600px;
    max-width: calc(100vw - 64px);
}

.linkPartnerSelect p {
    margin-bottom: 1rem;
}

.linkPartnerSelect .checkBox__container {
    margin-top: 8px;
}

.linkPartnerSelect .checkBoxWrapper {
    margin-left: 2px;
    margin-right: 14px;
}
