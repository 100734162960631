.adminNewEntityLink svg {
    height: 12px;
    width: 12px;
}

.adminNewEntityLink svg path {
    fill: var(--links_and_clickables);
}

.adminNewEntityLink__wrapper {
    display: inline-block;
}
