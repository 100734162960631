.cable path {
    fill: var(--purple-softer);
}

.cable svg {
    width: 3rem;
    height: 3rem;
}

.cable {
    display: flex;
    align-items: flex-end;
}

@media screen and (max-width: 330px) {
    .cable svg {
        max-width: 120px;
    }
}

.titleFuel {
    margin-left: 1rem;
}

.titleFuel p {
    font-family: 'Lato', sans-serif;
    display: flex;
    align-items: center;
    font-size: 20px;
}

.titleHeaderWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
