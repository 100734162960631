/*TODO, dette er helt likt bookingPage*/
.reservationPage {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3;
    padding: 3rem 2rem 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reservationPage > .closeModalButton {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
}

.bookingPageCarInfo .carInfo {
    height: 100%;
}

.reservationPage__contentWrapper {
    flex: 1;
}

.ultraHd {
    z-index: 4;
}

.verticalAdapt--topPadding {
}

.reservationPage__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reservationPage_topContent,
.reservationPage__bottomContent {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.reservationPage_topContent {
    flex: 3;
    padding: 16px 0 8px 0;
    width: 100%;
    justify-content: space-between;
}

.reservationPage__bottomContent {
    flex: 2;
    padding: 8px 0 20px 0;
    flex-direction: column;
}

.reservationPageHeading {
    max-width: 148px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.reservationPage__editbtn {
    position: absolute;
    right: 1rem;
    cursor: pointer;
    color: var(--grey__darker);
    z-index: 2;
    text-decoration: none;
}

.reservationPage__editbtn svg path {
    fill: var(--grey__darker);
}

.reservationPage__editbtn:hover svg path {
    fill: var(--links_and_clickables__hover);
}

.reservationPage__editbtn svg {
    width: 2rem;
    height: 2rem;
}

.reservationPage__editbtn p {
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    letter-spacing: 0.5px;
    margin: 0;
}

.reservationPageHeading__licensePlate {
    margin-top: 4px;
    margin-left: 8px;
}

.reservationPageClose {
    position: absolute;
    top: 20px;
    left: 20px;
}

.messageAlertButtonWrapper {
    position: relative;
}

.messageAlertButtonContainer {
    position: absolute;
    top: 0;
}

.carImageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 346px;
}

.reservationPage__statusInfo {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.reservationPage-status {
    color: var(--typographyBlack);
    font-size: 0.8rem;
    border-radius: 4px;
    line-height: 1rem;
    display: flex;
    align-items: center;
    padding: 2px 5px;
}

.reservation-status-ongoing {
    background-color: var(--soft-green);
}

.reservation-status-future {
    background-color: var(--softer-green);
}

.reservation-status-done {
    background-color: var(--grey__mediumLight);
}

.reservation-status-canceled {
    background-color: var(--soft-red);
}

.reservationPage__firstTimeLockButtonsContainer {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.reservationPage__lockButtons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--links_and_clickables);
    border-radius: 8px;
    border: 1px solid var(--links_and_clickables);
    overflow: hidden;
}

.reservationPage__noMemberCard {
    display: flex;
    flex-direction: column;
    background-color: var(--softer-yellow);
    padding: 8px;
    border-radius: 10px;
    gap: 8px;
}

.reservationPage__lockButtonWrapper,
.reservationPage__unlockButtonWrapper {
    width: 100%;
}

.reservationPage__locationInfo {
    max-width: 228px;
    text-align: center;
    margin-bottom: 0.75rem;
}

.reservationPage__infobuttons > * {
    display: block;
}

.reservationPage__carInfoButton {
    border: 1px solid var(--links_and_clickables);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 346px;
    padding: 0.5rem;
    border-radius: 8px;
    color: var(--links_and_clickables);
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0.75rem;
    margin-right: 10px;
    background-color: var(--links_and_clickables);
    color: white;
}

.reservationPage__carInfoButton svg {
    margin-right: 2rem;
    width: 1.5rem;
    height: 1.5rem;
    fill: white;
}

.reservationPage__carInfoButton svg > path {
    fill: white;
}

.reservationPage__carInfoButton:hover,
.reservationPage__carInfoButton:focus-visible {
    background-color: var(--links_and_clickables__hover);
}

.reservationPage__reservationNote {
    width: calc(100% - 40px);
    max-width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    margin-top: 0.75rem;
}

.reservationPage__reservationNoteText {
    font-style: italic;
    color: var(--grey__dark);
}

.reservationPage__timeAndPriceWrapper {
    margin-bottom: 0.75rem;
    width: 100%;
    max-width: 346px;
}

.reservationPage__priceEstimate {
    margin-top: 3px;
}

.reservationPage__priceEstimateText {
    margin-top: 1px;
}

.reservationPage__timeWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.reservationPage__timeWrapperElement {
    display: flex;
}

.reservationPage__priceAndQuickExtendWrapper {
    width: 83px;
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 8px;
}

.reservationPage__buttonsWrapper {
    width: 100%;
    max-width: 346px;
}

.reservationPage__buttons {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: var(--grey__light);
    border-radius: 8px;
    padding: 0.5rem;
}

.reservationPage__status {
    display: flex;
    align-items: center;
    height: 21px;
}

.reservationPage__statusText {
    margin-left: 7px;
}

.o-flexContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
}

.reservationPage__closeWrapper {
    color: #0872ca;
}

.reservationPage__close {
    fill: transparent;
    stroke: dodgerblue;
    stroke-linecap: round;
    stroke-width: 3;
}

.reservationPage__carProperties {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: bold;
    justify-content: space-evenly;
    /*margin: 0 20%;*/
    padding: 1em;
}

.reservationPage__carPropertyListItem {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: lighter;
    white-space: nowrap;
}

.reservationPage__carPropertyListItemIcon {
    fill: rgb(138, 190, 1);
    height: 1rem;
    width: 1rem;
}

.reservationPage__carPropertyListItemText {
    padding-bottom: 0.5rem;
    padding-left: 0.2rem;
    /* padding-right:1rem; */
}

.reservationPage__loaderWrapper {
    margin: auto;
    padding: 2px;
    height: 21px;
    width: 21px;
}

.reservationPage__carImage {
    max-height: 20rem;
    max-width: 25rem;
}

.reservationWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
}

.reservationPage__reservationInfo {
    flex: 1;
}

.reservationPage__time {
    display: flex;
    justify-content: space-between;
    margin: 0 20px 20px;
}

.reservationPage__priceEstimateText,
.reservationPage__timeWrapperElement .o-timeElement__info {
    transition: color 1500ms;
}

.reservationPage__priceEstimateText--highLight,
.reservationPage__timeWrapperElement--highLight .o-timeElement__info {
    transition: color 0ms;
    color: white !important;
}

.reservationPageImportantInfo {
    padding: 8px 16px;
    margin: 16px;
    border: 1px solid;
    width: 100%;
}

.reservationPageImportantInfo__heading {
    margin-bottom: 8px;
}

.reservationSubPage__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.reservationBills {
    position: relative;
    padding: 20px;
    z-index: 2;
    background-color: #fff;
}

.reservationBills .closeModalButton {
    position: absolute;
    top: 0;
    left: 0;
}

.reservationBills__header {
    text-align: center;
    margin-bottom: 20px;
}

.unlockCarModal__content :not(.confirmAndCancelButtons) .primaryButton {
    margin-top: 24px;
    white-space: break-spaces;
    overflow-wrap: anywhere;
}

.reservationPage__helpButton {
    background-color: var(--links_and_clickables);
    color: white;
    border: 1px solid var(--links_and_clickables);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    border-radius: 8px;
    font-size: 0.81rem;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0.75rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    text-decoration: none;
    gap: 0.5rem 1rem;
    text-transform: uppercase;
}

.reservationPage__helpButton svg {
    width: 1.5rem;
    height: 1.5rem;
}

.reservationPage__helpButton path {
    fill: white;
}

.reservationPage__helpButton:hover,
.reservationPage__helpButton:focus-visible {
    background-color: var(--links_and_clickables__hover);
    color: white;
}

/*!*MOBILE,LOW SCREEN*!*/
/*@media (max-height: 600px) and (max-width: 1136px) {*/
/*    .reservationPage_topContent {*/
/*        padding: 16px 0 8px 0;*/
/*    }*/
/*    .reservationPage__bottomContent {*/
/*        padding: 8px 0 20px 0;*/
/*    }*/
/*}*/

/*DESKTOP*/
@media (min-width: 1136px) {
    .reservationPage {
        position: absolute;
        top: unset;
        right: 64px;
        bottom: unset;
        left: unset;
        width: 440px;
        border: 1px solid rgb(174 174 174 / 20%);
        z-index: 1;
        margin-top: 3rem;
        border-radius: 4px;
        height: fit-content;
        max-height: calc(100vh - 7rem);
    }

    .reservationPage__content {
        min-height: 665px;
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        position: static;
    }

    .reservationSubPage__header {
        position: absolute;
    }
}

/*NARROW SCREEN*/
@media (max-width: 370px) {
    .unlockCarModal__content {
        padding: 8px 0;
    }
}

/*VERY NARROW SCREEN*/
@media (max-width: 340px) {
    .reservationPage__locationInfo {
        max-width: 200px;
    }
}

/*DESKTOP, VERY LOW SCREEN*/
@media (min-width: 1136px) {
    .verticalAdapt {
        flex: unset;
        overflow: unset;
        height: fit-content;
        max-height: calc(100vh - 7rem);
        padding: 1rem;
    }

    .verticalAdapt--scrollable {
        overflow: auto;
    }
}
