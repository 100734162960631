.adminBillingSummary {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adminBillingSummary .dateAndTimeSelectPage_dateSelect {
    margin: 0;
}

.adminBillingSummary__check {
    margin-top: 16px;
}

.adminBillingSummary__header {
    margin-bottom: 28px;
}

.adminBillingSummary__info {
    margin-bottom: 12px;
}

.adminBillingSummary__infoPoint {
    margin-top: 8px;
}

.adminBillingSummaryInfoPoint {
    display: flex;
}

.adminBillingSummaryInfoRow__value {
    text-align: right;
    padding-left: 6px;
    font-weight: bold;
}

.adminBillingSummary__mainButtons {
}

.adminBillingSummary__mainButton {
    display: inline-block;
    margin: 12px 8px 0 8px;
}

.billingOverviewModal {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    text-align: center;
}

.billingOverviewModal .closeModalButton {
    position: absolute;
    top: 0;
    left: 0;
}

.billingOverviewModal__buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.billingOverviewModal__buttonWrapper button {
    margin: 4px;
}

.adminBillingSummary .runWorking {
    margin: 16px 0;
}

.adminBillingSummary .loaderContainer {
    height: 40px;
    width: 40px;
    margin: 20px auto 0 auto;
}

.adminBillingSummary .checkBoxWrapper input {
    z-index: 0;
}

.adminBillingSummary .errorMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
}

.adminBillingSummary .errorMessage > * {
    margin-bottom: 8px;
}

.adminBillingSummary .errorMessage .code {
    margin-bottom: 8px;
    font-family: monospace !important;
    border: 1px solid;
    padding: 4px 8px;
}

.adminBillingSummary .invoiceRunMessage {
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 8px;
}

.adminBillingSummary .invoiceRunMessage__entityLink {
    display: block;
    margin: 8px 0;
}

@media (max-width: 419px) {
    .billingOverviewModal__buttonWrapper {
        flex-direction: column;
    }
}
