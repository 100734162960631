.adminListLoader {
    height: 200px;
    width: 200px;
    margin: 100px auto;
}

.adminList {
    position: relative;
    background-color: white;
    max-height: calc(100vh - 60px); /* TODO bedre måte å regne ut dette på */
    display: flex;
    flex-direction: column;
}

.adminList__header {
    font-weight: bold;
    position: sticky;
    z-index: 1;
}

.adminList__textFilterInput {
    width: 100%;
    border: none;
}

.adminList__headerRow {
    display: flex;
}

.adminListBooleanFilterInput {
    display: flex;
    padding-top: 8px;
}

.adminListBooleanFilterInput__checkBox {
    margin-right: 16px;
}

.adminListBooleanFilterInput__checkBox .checkBoxWrapper {
    margin-right: 4px;
}

.sortingIndicator {
    position: absolute;
    width: 0.8rem;
    height: 1rem;
    float: right;
    justify-content: right;
    top: 0.5rem;
    right: 0.3rem;
}

.adminListHeaderCell {
    flex-shrink: 0;
    display: flex;
    position: relative;
    text-align: left;
    font-size: 16px;
    padding-inline: 0.2rem;
    background-color: #b2b2b2;
    padding-bottom: 0.6rem;
}

.adminListHeaderCell.sortable {
    cursor: pointer;
}

.adminListHeaderCell--sorted {
    background-color: #ac9494;
}

.adminListHeaderCell--filtered {
    background-color: #9694ac;
}

.adminListHeaderCell--sorted.adminListHeaderCell--filtered {
    background-color: #a494ac;
}

.adminListHeaderCell--sortedDesc .sortingIndicator,
.adminListHeaderCell--sortedAsc .sortingIndicator {
    background-color: #333;
}

.adminListHeaderCell--sortedDesc .sortingIndicator {
    -webkit-clip-path: polygon(50% 100%, 100% 0, 0 0);
    clip-path: polygon(50% 100%, 100% 0, 0 0);
}

.adminListHeaderCell--sortedAsc .sortingIndicator {
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.adminList a,
.adminList a:hover,
.adminList a:active {
    display: inline-block;
    color: inherit;
    padding: 0;
    text-decoration: none;
}

.adminList__rowWrapper {
    width: 100%;
}
.adminList__rowWrapper:hover {
    background-color: var(--grey__semitransparent);
}

.adminList__row {
    display: flex;
    cursor: pointer;
}

.adminList__grayRow {
    background-color: var(--grey__transparent);
}

.adminList__row--expandableRow {
    min-height: 34px;
    margin-top: 8px;
    width: 100%;
}

.adminListCell {
    padding: 8px 7px 8px 5px;

    border-right: 1px #ddd solid;

    overflow: hidden;
    text-overflow: ellipsis;

    text-align: left;
    font-size: 16px;
    white-space: nowrap;
    flex-shrink: 0;
}

.adminListCell--alignRight {
    text-align: right;
}

.adminListLoaderContainer {
    top: 200px;
    width: 100vw;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.adminListLoader {
    height: 80px;
    width: 80px;
}

.adminListRowLoader {
    width: 99vw;
    justify-content: center;
    display: flex;
    overflow: hidden;
}

.bookingPageCarInfo__feedbackContainer {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bookingPageCarInfo__feedbackContainer p {
    margin: 20px;
}

.bookingPageCarInfo__feedbackContainer .spinnerContainer {
    height: 150px;
    width: 150px;
}

.adminListRowExpansion {
    width: 100%;
    min-width: 800px;
    background-color: var(--grey__light);
}

.adminListExpansionFooter {
    position: relative;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.adminListExpansionFooter__collapseButton {
    width: 96px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    border-radius: 4px 4px 0 0;
    background-color: #3a3a4f;
}

.adminListExpansionFooter__collapseButton:hover {
    background-color: #2f2f43;
}

.adminListExpansionFooter__sum {
    position: absolute;
    right: 16px;
    bottom: 16px;
    padding: 0 8px;
    display: flex;
    font-weight: bold;
}

.nonColumnFilters {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    padding: 7px 0 5px 20px;
    background-color: #fff;
}

.nonColumnFilter {
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.nonColumnFilter a {
    margin-left: 5px;
}

.nonColumnFilter a,
.nonColumnFilter a:hover,
.nonColumnFilter a:active {
    display: flex;
    align-items: center;
    justify-content: center;
}

.adminList__searchInitializer {
    top: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
}

@media (min-width: 1136px) {
    .adminList__header {
        padding-top: 50px;
    }
}

@media (max-width: 1136px) {
    .adminList {
        max-width: 100vw !important;
    }
}

.adminExportContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: row-reverse;
    padding: 1rem;
}

.adminList__scroller {
    overflow: auto;
    flex: 1;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.adminList__scroller::-webkit-scrollbar {
    display: none; /* Safari, Chrome */
}
