.vippsConnection,
.vippsConnectionContent {
    display: flex;
    align-items: center;
}

.vippsConnectionContent {
    position: relative;
}

.vippsConnection > svg {
    margin-right: 8px;
}

.vippsConnection.notConnected > svg rect {
    fill: lightgrey;
}

.vippsConnection .disconnectVippsButton {
    margin-left: 8px;

    border-radius: 4px;
    height: 30px;
    width: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.vippsConnection .disconnectVippsButton svg path {
    fill: white;
}

.disconnectVippsModalButtons button {
    margin: 16px 0;
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .disconnectVippsModalButtons {
        display: flex;
    }

    .disconnectVippsModalButtons button {
        margin: 0 8px;
    }
}
