.adminPageHelpHeader {
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.adminPageHelpEditHeader {
    font-size: 26px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.adminPageHelpSubHeader {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.adminPageHelpEditing {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-weight: 400;
}
.adminPageHelpEditSubHeader {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-weight: 400;
    /* padding-bottom: 50px; */
}

.adminPageHelpHeaderWrapper {
    flex: 2 1;
}

.adminPageHelpHeaderRest {
    flex: 1 1;
}

.adminPageHelpContentContent {
    font-size: 15px;
    margin-top: 1.5rem;
    /* height: 500px; */
}

.adminPageInput {
    margin-bottom: 12px;
    margin-top: 17px;
}

.adminPageHelpContentWrapper {
    flex: 1 1;
    width: 100%;
    max-width: calc(100% / 3);
    margin-right: 2rem;
    margin-left: 2rem;
    margin-top: 2rem;
}

.adminEditHelpWrapperLeft {
    flex: 2 1;
}
.adminEditHelpWrapperRight {
    flex: 2 1;
    max-width: 400px;
}

.adminPageHelpEditButton {
    background-color: transparent;
    border: 1px solid var(--links_and_clickables);
    border-radius: 8px;
    color: var(--links_and_clickables);
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    flex: 1 1;
    padding: 20px 0;
}

.adminPageHelpEditButtonRest {
    flex: 2 1;
}

.adminPageHelp__box {
    /* border: 1px solid black;  */
    padding: 12.5px 15px;
    font-size: 15px;
}

.adminPageHelp__box:hover {
    background-color: #70707f;
    cursor: pointer;
    color: white;
}

.adminPageHelp__boxWhite {
    background-color: white;
}

.adminPageHelp__boxGrey {
    background-color: #efefef;
}

.adminPageHelp__boxFilter {
    background-color: #3a3a4f;
    color: white;
}

.adminPageHelp__boxChoosen {
    background-color: #016cc5;
    color: white;
}

.adminPageEditButton {
    background-color: var(--button_primary);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem;
    border-radius: 4px;
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    align-items: center;
}

.adminPageEditButton:hover {
    background-color: var(--button_primary__dark);
    color: white;
}

.adminPageEditButton svg {
    width: 30px;
    height: 25px;
}

.adminPageEditingBox {
    background-color: #b2b2b2;
    border-radius: 2px;
    width: fit-content;
    color: white;
    padding: 10px 15px;
}

.adminPageEditingWrapper {
    display: flex;
    gap: 10px;
    align-items: center;
}

.actionButtonsWrapper {
    display: flex;
    border: 1px solid #b2b2b2;
    padding: 15px 10px;
    margin-bottom: 25px;
    border-radius: 4px;
}

.overrideAdminPageInput {
    margin-bottom: 0;
}
.notChosenActionButton {
    color: #b2b2b2 !important;
}

.notChosenActionButton svg path {
    fill: #b2b2b2 !important;
}

.notChosenActionButton:hover {
    color: #016cc5 !important;
}

.notChosenActionButton:hover svg path {
    fill: #016cc5 !important;
}
.adminButton_grid {
    background: transparent;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #016cc5;
    text-decoration: none;
    width: 33.3%;
}

.adminButton_grid:hover {
    color: #01569e;
    background-color: transparent;
}

.adminButton_grid:hover path {
    fill: #01569e;
    padding: 10px 15px;
}

.adminPageEditingWrapper {
    display: flex;
    gap: 10px;
    align-items: center;
}

.actionButtonsWrapper {
    display: flex;
    border: 1px solid #b2b2b2;
    padding: 15px 10px;
    margin-bottom: 25px;
    border-radius: 4px;
}

.overrideAdminPageInput {
    margin-bottom: 0;
}
.notChosenActionButton {
    color: #b2b2b2 !important;
}

.notChosenActionButton svg path {
    fill: #b2b2b2 !important;
}

.notChosenActionButton:hover {
    color: #016cc5 !important;
}

.notChosenActionButton:hover svg path {
    fill: #016cc5 !important;
}
.adminButton_grid {
    background: transparent;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #016cc5;
    text-decoration: none;
    width: 33.3%;
}

.adminButton_grid:hover {
    color: #01569e;
    background-color: transparent;
}

.adminButton_grid:hover path {
    fill: #01569e;
}

.bulletPointInputField {
    height: fit-content;
    background-color: red;
    padding: 5px;
}
