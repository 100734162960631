.reservationChange {
    display: flex;
}

.collapsibleElement__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collapsibleElement__headerContent {
    width: 100%;
}

.collapsibleElement__collapseButton,
.collapsibleElement__collapseButton:hover,
.collapsibleElement__collapseButton:active {
    background-color: rgba(0, 0, 0, 0);
}

.collapsibleElement__collapseButton {
    position: absolute;
    top: 0;
    right: 0;
    height: 28px;
    width: 28px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.collapsibleElement__collapseButton svg {
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s; /* Safari */

    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
}

.collapsibleElement__collapseButton--collapsed svg {
    transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
}

.collapsibleElement__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    transition: height 0.5s;
    -webkit-transition: height 0.5s; /* Safari */
}
