.invalidSearchOption {
    color: red;
    padding: 8px;
    cursor: help;
}

.extraSearchOptions {
    display: flex;
    align-items: center;
    padding: 5px 0 5px 20px;
    min-height: 50px;
    max-height: 50px;
    background: white;
    position: fixed;
    width: 100vw;
    z-index: 3;
}

.extraSearchOption {
    margin-left: 8px;
    overflow-y: visible;
    min-height: 40px;
    max-height: 40px;
    z-index: 1;
}

.extraSearchOptionContent {
    position: absolute;
    z-index: 2;
    min-height: 40px;
    background-color: white;
    border: 1px solid var(--links_and_clickables);
}

.extraSearchOptionContent:hover {
    border: 1px solid var(--links_and_clickables__hover);
}

.extraSearchOptionContent .extraSearchOptionHeader {
    border-radius: 4px;
}

.extraSearchOption__open .extraSearchOptionHeader {
    border-radius: 4px 4px 0 0;
}

.extraSearchOptionHeader {
    cursor: pointer;
    height: 40px;
    align-items: center;
    justify-content: unset;
    padding: 0 16px;
    text-align: left;
}

.extraSearchOptionHeaderWithoutValue {
    color: var(--links_and_clickables);
}

.extraSearchOptionHeaderWithoutValue:hover {
    color: var(--links_and_clickables__hover);
}

.extraSearchOptionHeaderWrapper {
    background-color: var(--links_and_clickables);
}

.extraSearchOptionHeaderWithValue {
    color: white;
}
.extraSearchOptionHeaderWithValue:hover {
    background-color: var(--links_and_clickables__hover);
}

.extraSearchOption .adminListBooleanFilterInput {
    padding: 16px;
    flex-direction: column;
}

.extraSearchOption .adminListBooleanFilterInput label:first-child {
    margin-bottom: 16px;
}

.invalidSearchOption,
.resetButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.resetButton {
    top: 5px;
    right: 5px;

    border-radius: 4px;
    height: 30px;
    width: 30px;
    background-color: var(--links_and_clickables);
}

.resetButton:hover {
    background-color: var(--links_and_clickables__hover);
}

.extraSearchOption .inputField {
    width: auto;
    margin: 16px 16px 0 16px;
    min-width: 250px;
}

.extraSearchOption__clickOutsideDetector {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.extraSearchOptionContent__headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.resetButton svg > path {
    fill: white;
}
