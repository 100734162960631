.simpleMessageModal {
    padding: 20px;
    text-align: center;
    z-index: 10;
}

.simpleMessageModal__header {
    margin-bottom: 20px;
}

.simpleMessageModal .primaryButton {
    margin: 0 auto;
}
