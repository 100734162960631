.AdminCarImage__dropZonesContainer {
    position: relative;
    --dropSize: 200px;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    border: 1px solid #b2b2b2;
    border-radius: 4px;

    width: var(--dropSize);
    height: 200px;
}

.AdminCarImage__dropZonesContainer:hover {
    border-color: #737272;
}

.AdminCarImage__dropZonesContainer > label {
    text-transform: capitalize;
    position: absolute;
    top: -9px;
    left: 10px;
    padding: 0 4px;
    background-color: var(--typographyWhite);
    z-index: 0;
}

.AdminCarImage__dropZone,
.AdminCarImage .imageUploader {
    height: var(--dropSize);
    width: var(--dropSize);
    position: relative;
}

.AdminCarImage__dropZone {
    height: var(--dropSize);
    width: var(--dropSize);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 4px;

    cursor: pointer;
    z-index: 1;
}

.AdminCarImage__dropZone:hover {
    background-color: #008eff11;
}

.AdminCarImage__dropZone > svg {
    height: 40px;
    width: 40px;
    fill: #3a3a4f;
}
