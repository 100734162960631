.adminReservationListElementNoLink,
.adminReservationListElement {
    display: flex;
    height: 40px;
    border-radius: 4px;
    border: 1px solid;
    text-decoration: none;
    margin-bottom: 8px;
    grid-column: span 2;
}

.adminReservationListSubHeader {
    grid-column: span 2;
    margin-bottom: 8px;
}

.adminReservationListElement:hover {
    -webkit-box-shadow: 0px 0px 8px 0px rgba(1, 108, 197, 0.8);
    -moz-box-shadow: 0px 0px 8px 0px rgba(1, 108, 197, 0.8);
    box-shadow: 0px 0px 8px 0px rgba(1, 108, 197, 0.8);
}

.adminReservationListElement__timeContainer,
.adminReservationListElement__ownerId,
.adminReservationListElementTime {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.adminReservationListElement__ownerId {
    flex: 1;
}

.adminReservationListElement__timeContainer {
    flex: 3;
}

.adminReservationListElement__state {
    min-width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.adminReservationListElement__organization {
    display: flex;
    align-items: center;
    border-left: 1px solid var(--grey);
}
.adminReservationListElement__organization_logo {
    display: flex;
    align-items: center;
}

.adminReservationListElementTime--start {
    flex: 1;
    padding-right: 1rem;
}

.adminReservationListElementTime--end {
    flex: 1;
}

.adminReservationSummaryLinkElement {
    display: flex;
    align-items: center;
    padding-left: 16px;
}

@media (max-width: 500px) {
    .adminReservationListElement__state {
        display: none;
    }

    .adminReservationListElement__organization {
        display: none;
    }
}
