.adminDamage .adminPageHeader {
    margin-bottom: 4px;
}

.adminDamage__subheader {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
}

.adminDamage__subheader p,
.adminDamage__headerButtonWrapper {
    display: inline-block;
}

.adminDamage__headerButtonWrapper button {
    margin-top: 4px;
}

.adminDamage__deleteButton:hover,
.adminDamage__deleteButton {
    background-color: unset;
}

.adminDamage__deleteButton:hover svg path {
    fill: #911616;
}

.adminDamageNewReservationLink {
    display: block;
    margin-bottom: 16px;
}

.adminDamage .pictureWithText__thumbNail {
    border: 1px solid var(--grey__mediumLight);
}

.adminDamageStatus__checkWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 8px 0;
}

.adminDamage__checkBox {
    margin: 0 16px 16px 0;
    display: flex;
    align-items: center;
}

.adminDamage__checkBox .checkBoxWrapper {
    margin-right: 4px;
}

.adminDamage__reservationsButton {
    display: inline-block;
    margin-left: -16px;
    margin-bottom: 16px;
}

.infoElement .adminNewEntityLink {
    margin-left: 16px;
}

.adminDamage__picturesWrapper {
    display: flex;
}

.adminDamage__pictureCheckWrapper {
    display: inline-block;
    margin-bottom: 8px;
}

.adminDamage__pictureWrapper .checkBoxWrapper {
    margin-right: 8px;
}

.adminDamage__pictureWrapper {
    margin: 8px;
}

.adminDamage__datePicker {
    position: relative;
    margin-bottom: 24px;
}

.adminDamageDatePicker__header {
    position: absolute;
    top: -8px;
    left: 8px;
    z-index: 1;
    background-color: #fff;
    padding: 0 4px;
}

.adminDamage__datePicker .DateInput_input {
    height: 56px;
    border-radius: 5px;
    border: 1px solid var(--grey);
}

.adminDamage .DateInput_input__focused {
    border: 2px solid var(--links_and_clickables);
}

.adminDamage .DateInput {
    opacity: 1 !important;
}

.adminDamage .SingleDatePickerInput {
    opacity: 1;
    border: none;
    z-index: 999;
}

.adminDamage .SingleDatePicker_picker {
    top: 56px !important;
    left: 1px !important;
}

.adminDamage .DateInput_fang {
    display: none;
}

.adminDamageAddPictureComponent input {
    position: absolute;
    height: 54px;
    border-radius: 5px;
}

.adminDamageAddPictureComponent {
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.adminDamageAddPictureComponent .button_2 {
    width: 110px;
    padding-bottom: 4px;
}

.adminDamageAddPictureComponent .button_2__text {
    margin-top: 2px;
}

@media (max-width: 1136px) {
    .adminDamage__subheader {
        flex-direction: column;
        align-items: center;
    }

    .adminDamage__picturesWrapper {
        flex-direction: column;
    }

    .adminDamage__pictureWrapper {
        margin-bottom: 8px;
    }

    .adminDamageAddPictureComponent,
    .adminDamage__pictures,
    .pictureWithText--medium .pictureWithText__thumbNail {
        width: 90vw;
    }

    .adminDamage__headerButtonWrapper button {
        margin: 8px 0 0 0 !important;
    }

    .adminDamage__causedByInput {
        margin-top: 34px;
    }
}

@media (min-width: 1136px) {
    .adminDamage .adminPageContent__left {
        flex: 3;
    }

    .adminDamage .adminPageContent__right {
        flex: 2;
    }

    .adminDamage {
        margin-top: 48px;
        min-height: calc(100vh - 104px);
    }

    .adminDamage__picturesWrapper {
        flex-wrap: wrap;
    }

    .adminDamageAddPictureComponent {
        width: 234px;
        min-height: 150px;
    }

    .adminDamageStatus__timeAndCausedBy {
        display: flex;
    }

    .adminDamage__datePicker {
        margin-right: 8px;
    }

    .adminDamage__datePicker .DateInput_input {
        margin-right: 2px;
    }

    .adminDamage .DateInput_input__focused {
        margin-right: 0;
    }

    .adminDamage__pictures .pictureWithText {
        margin: 0 8px 8px 0;
    }
}
