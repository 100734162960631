.membershipMembers {
    margin-bottom: 8px;
    width: 100%;
}

.membershipMembers__header {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}

.membershipMembers__headerText {
    display: inline-block;
}

.membershipMembers .addButtonWithPlus {
    cursor: pointer;
    min-width: unset;
    padding: 0 8px 0 6px;
}

.membershipMembers__table {
    margin: 1rem 0;
    width: 100%;
}

.membershipMembers__table > tr > th {
    padding: 0.7rem 0.5rem;
    text-align: start;
    font-weight: 600;
    background-color: var(--light-blue);
}

.membershipMembers__table > tr > th:first-child {
    border-radius: 4px 0 0 0;
}

.membershipMembers__table > tr > th:last-child {
    border-radius: 0 4px 0 0;
}

.membershipMembers__table > tr:last-child > td:first-child {
    border-radius: 0 0 0 4px;
}

.membershipMembers__table > tr:last-child > td:last-child {
    border-radius: 0 0 4px 0;
}

.membershipMembers__table > tr > td {
    padding: 0.7rem 0.5rem;
    text-align: start;
    vertical-align: middle;
}

.membershipMembers__table > tr:nth-child(odd) > td {
    background-color: var(--lighter-blue);
}
.membershipMembers__table > tr:nth-child(even) > td {
    background-color: var(--lightest-blue);
}

.memberListElement__name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;
}

.memberListElement__name > svg > path {
    fill: var(--purple-softer);
}

.memberListElement__name > svg {
    width: 2rem;
}

.memberListElement__removeButton,
.memberListElement__removeButton:hover {
    background-color: rgba(0, 0, 0, 0);
}

.memberListElement__removeButton:hover svg {
    fill: var(--links_and_clickables__hover);
}

.membershipMembers__defaultDriverExplanation {
    margin-top: 8px;
}

@media (max-width: 600px) {
    .memberListElement__name > svg {
        display: none;
    }
}
