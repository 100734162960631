.forgotPasswordPage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: white;
    z-index: 3;

    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.forgotPasswordPage__contentWrapper {
    width: 100%;
    max-width: 400px;
}

.forgotPasswordPage__contentWrapper--loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgotPasswordPage__loaderWrapper {
    height: 40vw;
    max-height: 400px;
    width: 40vw;
    max-width: 400px;
}

.forgotPasswordPage__header {
    text-align: center;
    margin-bottom: 8px;
}

.forgotPasswordPage__inputField {
    margin-top: 20px;
}

.editProfile__saveButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgotPasswordPage__resultMessage {
    min-height: 350px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.forgotPasswordPage__text {
    margin-top: 16px;
}

.forgotPasswordPage__buttonWrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
