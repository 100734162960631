.locationPage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background: white;
}

.parkingInfo__description {
    max-height: 280px;
    overflow: auto;
}

.locationPage__content {
    height: 100%;
    width: 100%;
    padding-top: 1rem;

    display: flex;
    flex-direction: column;
}

@media (max-height: 800px) {
    .parkingInfo__description {
        max-height: 180px;
    }
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .locationPage {
        top: 56px;
        z-index: 1;
    }
    .locationPage__mapContainer {
        position: static;
        height: 80%;
        width: 100%;
    }
}
