.selectDrivers__shareReservationContent {
    margin: 16px 20px;
}

.selectDrivers__driverPhoneNo {
    padding-left: 0.5em;
}

.shareReservation__inputField {
    margin-bottom: 16px;
}

.selectDrivers__shareReservationContent .inputField__text {
    padding: 4px 8px;
}

.selectDrivers__buttonWrapper {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 16px;
}

.selectDrivers__shareButton {
    text-decoration: none;
    color: var(--links_and_clickables-light);
    margin-bottom: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.selectDrivers__shareButtonText {
    padding: 0 0 3px 4px;
}
