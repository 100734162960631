.editNotifications {
    padding-left: 25px;
    padding-bottom: 30px;
}

.editNotifications__enableAll {
    display: flex;
    margin-right: 16px;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.editNotifications__enableAllText {
    padding-left: 1rem;
}

.editNotifications__devicesHeader {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    font-weight: bold;
}

.editNotifications__devices {
    tr {
        height: 1.6rem;
    }
    td,
    th {
        border-bottom: 1px solid gray;
    }
    th {
        text-align: left;
    }
    th:not(:first-child) {
        padding-left: 20px;
    }
    td:not(:first-child) {
        padding-left: 20px;
    }
    td {
        vertical-align: middle;
    }
    button {
        background-color: unset;
        padding: 2px;
    }
    button:hover svg path {
        fill: #911616;
    }
}

.editNotifications__noDevices {
    font-style: oblique;
    font-size: small;
}

.editProfile {
    background-color: white;
    position: relative;
}

.editUserPassword {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.editProfile__saveButtonWrapper {
    width: 100%;
    height: 88px;
}

.editProfile__inputField {
    margin-bottom: 16px;
}

.editProfile__subPage .primaryButton {
    margin: 0 auto;
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .editProfile {
        z-index: 0;
    }

    .editProfile .headerWithNavigation .closeModalButton {
        display: none;
    }

    .editProfile__subPage {
        margin: 0 auto;
        padding-top: 40px;
    }
}

/*MOBILE*/
@media (max-width: 1136px) {
    .editProfile {
        z-index: 2;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .editProfile__subPage {
        max-height: calc(100vh - 100px);
        overflow: auto;
    }
}
