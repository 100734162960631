.dotted-line-container {
    display: inline;
    box-sizing: border-box;
}

.dotted-line-button {
    border: none;
    display: inline;
    font-size: 100%;
    font-family: inherit;
    border: 0;
    background: none;
    color: var(--grey__darker);
    border-color: var(--grey__darker);
    border-bottom-style: dotted;
    border-bottom-width: 2px;
    font-style: inherit;
    font-weight: inherit;
}
