.carLoginLogoutPlaceholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
}

.carLoginLogoutPlaceholder label {
    margin-top: 4px;
}

.carLoginLogoutButton {
    padding: 0.5rem;

    margin-bottom: 0.5rem;
    border-radius: 8px;
    color: white;
    text-transform: uppercase;
}

.carLoginLogoutButton span {
    margin-bottom: -0.2rem;
}

.carLoginLogoutButton svg {
    fill: white;
    height: 2rem;
}

.carLoginLogoutButton.testFunction {
    background-color: mediumpurple;
}

.carLoginLogoutButton.loginReservation {
    background-color: #59d759;
}

.carLoginLogoutButton.logoutReservation {
    background-color: hotpink;
}
