.coronaPopup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 4;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

    text-align: center;

    padding: 20px;
}

.coronaPopupContent {
    /*background-color: hotpink;*/

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.coronaLanguageButton {
    position: absolute;
    top: 8px;
    left: 16px;
}

.coronaPopup svg {
    height: 80px;
}

.coronaPopupContent p {
    margin: 16px 0;
}

.coronaPopupContent > * {
    margin: 8px 0;
}

.coronaButton {
    height: unset;
    padding: 16px 32px;
    font-size: 24px;
}

/*NOT TINY SCREEN*/
@media (min-height: 600px) and (min-width: 350px) {
    .coronaPopupContent > * {
        margin: 16px 0;
    }

    .coronaPopup svg {
        height: 160px;
    }
}

/*LARGE SCREEN*/
@media (min-width: 1000px) {
    .coronaPopupContent {
        position: relative;
        padding: 40px;
        border-radius: 8px;

        /*box-shadow: -2px 0 4px rgba(0, 0, 0, 0.25);*/
        box-shadow: 0 0 8px 10px rgba(238, 238, 238, 1);
    }
}
