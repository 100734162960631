.join__disclaimer {
    text-align: center;
    margin-top: 16px;
}

.join__disclaimer a:not(:hover) {
    text-decoration: none;
}

.join__loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.join__loader {
    height: 160px;
    width: 160px;
}

/*
    userJoin
*/

.userJoin {
    padding-bottom: 40px;
}

.userJoin,
.userJoin__content,
.userJoin__vippsLogoLinkContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userJoin {
    padding: 80px 40px;
}
.userJoin__vippsLogoLinkContainer {
    justify-content: center;
    margin-bottom: 16px;
}

.userJoin__heading {
    text-align: center;
}

.userJoin__or {
    width: 100%;
    margin: 22px 0;
    display: flex;
    align-items: center;
    /*justify-content: center;*/
}

.userJoin__error button {
    margin: 20px auto;
}

/*
    InvitationJoin
*/

.invitationJoin {
    display: flex;
    justify-content: center;
    padding: 40px 20px;

    text-align: center;
}

.invitationJoin__heading {
    margin-bottom: 20px;
}

.invitationJoin__acceptAndCancelButtons {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.invitationJoin .primaryButton,
.invitationJoin .secondaryButton {
    margin: 20px auto 1px auto;
}

/*
    joinFeedback & joinTerms
*/

.joinFeedback,
.joinTerms {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/

    text-align: center;

    padding: 60px 16px 40px 16px;
}

.joinFeedback__heading {
    padding: 0 8px;
}

.joinTerms a {
    margin-top: 16px;
}

.joinFeedback p {
    margin: 16px 0;
}

.joinFeedback__vippsSection {
    margin: 16px 0;
}

.joinFeedback__vippsSection .vippsLogoLink {
    max-width: 210px;
    margin: 0 auto;
}

.joinFeedback button {
    margin-top: 16px;
}

.joinTerms .termsOrganizationLink {
    font-size: var(--16px);
    line-height: 1.375rem;
    letter-spacing: 0.5px;
}

@media (max-width: 400px) {
    .userJoin .editUser,
    .invitationJoin .editUser {
        padding: 0;
    }
}

@media (min-width: 450px) {
    .invitationJoin {
        padding: 80px 40px;
    }

    .invitationJoin__acceptAndCancelButtons {
        flex-direction: row;
    }

    .membershipTypeInfo__tabContent {
        padding: 32px;
    }
}
