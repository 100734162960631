.chevronClickable {
    display: flex;
    text-decoration: none;
    align-content: flex-end;
    justify-content: center;
    tab-index: 0;
}

.chevronClickable,
.chevronClickable:hover {
    background-color: unset;
}

.chevronClickable--disabled {
    tab-index: -1;
}

.chevronClickable--disabled {
    pointer-events: none;
}

.chevronClickable__content {
    flex: 1;
}

.chevronClickable__chevronWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 20px;
    min-height: 20px;
}

.chevronClickable__chevronWrapper svg {
    transform: rotate(-90deg);
}

.chevronClickable__chevronWrapper svg path {
    fill: #ccc;
}
