.reservationDamagesInfoContent__importantText {
    font-weight: bold;
    margin: 8px 0;
}

.reservationDamagesInfo__content h5 {
    margin: 12px 0 0 0;
}

.reservationDamagesInfo__damageListHeader {
    margin-top: 16px;
}

.reservationKnownDamages {
    margin-top: 8px;
}

.reservationKnownDamages__damage {
    display: block;
}

.reservationKnownDamages__damage--noPictures .reservationMessageListElement {
    cursor: unset;
}

.reservationKnownDamages__damage--noPictures
    .reservationMessageListElement__textWrapper {
    padding: 16px 0;
}

.reservationKnownDamages__damage,
.reservationKnownDamages__damage:hover {
    background-color: unset;
    text-align: left;
}

.reservationKnownDamagesPictureView__description {
    text-align: center;
    margin-bottom: 20px;
    padding: 0 40px;
    width: 100%;

    background-color: var(--typographyWhite);
}

.reservationKnownDamagesPictureView__picturesWrapper {
    flex: 1;
    overflow: auto;
    text-align: center;
}

.reservationKnownDamagesPictureView__picturesWrapper,
.reservationKnownDamagesPictureView__picturesWrapper img {
    max-width: 100%;
}

.reservationDamages {
    overflow: auto;
}

.reservationDamages .editReservationMessage {
    margin-top: -10px;
}

.reservationDamageInstructions {
    margin-bottom: 16px;
}

.reservationDamageInstructions p {
    margin-bottom: 8px;
}

@media (max-width: 1136px) {
    .reservationKnownDamagesPictureView {
    }
}
