.headerWithNavigation {
    height: 100px;
    margin-top: -2px;
}

.headerWithNavigation__heading {
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    color: white;

    text-align: center;
    pointer-events: none;
}

.headerWithNavigation__navigation {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;

    button ~ button {
        margin-left: 10px;
    }
}

.headerWithNavigation > .closeModalButton > svg > path {
    fill: #ffffff;
}

.headerWithNavigation__passwordButton {
    width: 30px;
    height: 32px;
    padding: 3px;
}

.headerWithNavigation__passwordButton > path {
    fill: white;
}
.headerWithNavigation__notificationButton {
    transform: scaleY(-1);
}
