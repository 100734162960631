.adminModal {
    display: grid;
    width: 100%;
    grid-template-areas: 'heading' 'info' 'contents' 'button';
    grid-template-rows: auto auto 1fr auto;
    grid-template-columns: 1fr;
    padding: 16px;
    flex: 1;
    overflow: auto;
    min-height: 400px;
}

.adminModal h4 {
    grid-area: heading;
    text-align: center;
}

.adminModal--overflowVisible {
    overflow: visible;
}

.adminModal__optionalInfoElement {
    width: 100%;
    padding-top: 16px;
    grid-area: info;
}

.adminModal__contents {
    grid-area: contents;
    display: flex;
    flex-direction: column;
}

.adminModalRemove {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px 28px;
}

.adminModalRemove span {
    display: block;
}

.adminModalRemove ul {
    list-style: unset;
    margin-left: 16px;
}

.adminModalRemove li {
    margin: 8px;
}

.adminModal__buttonWrapper {
    display: block;
    text-align: center;
    grid-area: button;
}

.adminModal__buttonWrapper button {
    display: inline;
}

.adminModal__buttonWrapper .primaryButton {
    margin: 8px;
}

.adminModalInputField {
    margin-top: 32px;
    flex: 1;
}
.adminModalInputField--noFlex {
    flex: none;
}
.adminModal__body {
    margin-top: 1rem;
}

/*MOBILE*/
@media (max-width: 1136px) {
    .adminModal {
        max-height: 100vh;
        margin: 0;
    }
}

/*DESKTOP*/
@media (min-width: 1136px) and (min-height: 1100px) {
    .adminEditCarModal {
        overflow: visible;
    }
}
