.carInfo {
    text-align: left;
    padding-bottom: 1rem;
}

.carInfo > .closeModalButton {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
}

.carInfo__header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.carInfo__alert {
    width: 70px;
    height: 70px;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%);
    clip-path: polygon(0 0, 0 100%, 100% 100%);
    rotate: 180deg;
    background-color: var(--links_and_clickables-light);
    position: absolute;
    right: -9rem;
    top: -0.8rem;
    z-index: 1;
}

.carInfo__alert--reservation {
    right: -0.1rem;
    top: 0;
}

.carInfo__alert svg {
    position: absolute;
    top: 2.1rem;
    left: 0.3rem;
}

.carInfo__alert path {
    fill: var(--typographyWhite);
}

.carInfoNameNumberImage {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
}

.carNumberInHeader {
    color: var(--typographyBlack__MediumEmphasis);
    font-size: 0.9rem;
    font-weight: bold;
    margin-left: 0.5rem;
}

.carInfoModelAndLicensePlate {
    padding-top: 8px;
}
.carInfoModelAndLicensePlate__modelName {
    padding-bottom: 8px;
}

.carInfoModelAndLicensePlate__categoryAndSeats {
    font-size: 16px;
    color: var(--grey__darker);
}

.carInfo__infoGroup {
    margin-top: 16px;
    background-color: var(--grey__lighter);
    padding: 1rem;
    border-radius: 4px;
}

.carInfo__infoGroup svg > path {
    fill: var(--purple-softer);
    fill-opacity: 1;
}

.carInfo__infoGroup svg {
    width: 1.25rem;
    height: 1.25rem;
}

.carInfo__infoGroupHeader {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.carInfo__infoGroupHeader__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.carInfo__fuelInfoContentWrapper {
    display: flex;
    margin-top: 1rem;
}

.carInfo__fuelInfoElement {
    margin-right: 16px;
}

.carInfo__infoText {
    margin-top: 1rem;
}

.carInfoSection {
    background-color: var(--grey__light);
    border-radius: 4px;
}

.helpButton {
    margin-top: 16px;
}

@media (max-width: 320px) {
    .carInfoNameNumberImage {
        margin-top: 8px;
    }
}

@media (max-width: 450px) {
    .carInfo__alert--reservation {
        right: 0;
        top: 0;
    }
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .carInfo {
        overflow: auto;
    }
}
