/*TODO mange av disse gjenbrukes av reservationPage og BookingPage, kanskje lage komponenter eller gjennbruke css-klassene*/
.bookingPage {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3;
    padding: 3rem 2rem 1rem 2rem;
    min-height: 100vh;
}

.bookingPage__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.bookingPage__content .closeModalButton {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
}

.bookingPage__locationIcon path {
    fill: var(--typographyBlack__MediumEmphasis);
    fill-opacity: 1;
}

.bookingPageContentWrapper {
    flex: 1;
}

.bookingPage__organizationInfoWithLogo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bookingPage__organizationLogo {
    margin: 0 0 0 4px;
}

.bookingPageContent {
    padding: 1rem;
    display: grid;
    grid-template-columns: auto;
    place-content: center;
    place-items: center;
    row-gap: 1rem;
}

.bookingPageContent__btnWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 0.75rem;
}

.bookingPageContent__flexArea {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
}

.bookingPageContent__availability {
    position: absolute;
    right: 1rem;
    cursor: pointer;
    color: var(--grey__darker);
    z-index: 2;
    text-decoration: none;
}

.bookingPageContent__availability svg path {
    fill: var(--grey__darker);
}

.bookingPageContent__availability:hover svg path {
    fill: var(--links_and_clickables__hover);
}

.bookingPageContent__availability svg {
    width: 2rem;
    height: 2rem;
}

.bookingPageContent__availability p {
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    letter-spacing: 0.5px;
}

.reservationPage__editbtn {
    position: absolute;
    right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reservationPage__editbtn p {
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    color: var(--grey__darker);
    margin-top: 0.5rem;
}

.reservationPage__editbtn path {
    fill: var(--grey__darker);
}

.bookingPage__center-buttons {
    display: flex;
}

.bookingPage__car-image {
    width: 100%;
    display: flex;
    justify-content: center;
}

.bookingPageContent__carInfoButtonWrapper {
    position: relative;
    margin-left: 16px;
}

.bookingPageContent__carInfoButtonWrapper .button_4 {
    min-width: 75px;
}

.bookingPage__locationInfo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bookingPageMembershipInfo {
    width: 100%;
}

.bookingPageMembershipInfo__userError {
    color: var(--alert_error);
}

.bookingPageMembershipInfo__reservationRequirementWarning {
    text-align: center;
}

.bookingPage__loginLink {
    cursor: pointer;
    color: var(--links_and_clickables);
}

.bookingPageMembershipInfo__reservationBlockedWarning {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

.bookingPageMembershipInfo__reservationBlockedWarningText {
    margin-left: 8px;
}

.missingMembershipModal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
}

.bookingPageMembershipInfo__membership {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bookingPageMembershipInfo__membership > svg > path {
    fill: rgba(91, 90, 117, 0.5);
}

.bookingPageMembershipInfo__membership p {
    display: inline-block;
    margin-left: 8px;
}

.bookingPagePriceWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 70px;
}

.bookingPage__priceLoaderWrapper {
    margin-top: 4px;
    height: 24px;
    width: 24px;
}

.bookingPagePriceWrapper__kilometerInputWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.bookingPage .priceSummary {
    padding-right: 0;
}

.bookingPage__kilometerInput input::placeholder {
    color: #333;
    line-height: unset;
    font-size: unset;
}

.bookingPage__kilometerInput,
.bookingPage__kilometerInput .inputField__text {
    height: 24px;
    max-width: 45px;
}

.bookingPage__kilometerInput--4,
.bookingPage__kilometerInput--4 .inputField__text {
    max-width: 55px;
}

.bookingPage__kilometerInput--5,
.bookingPage__kilometerInput--5 .inputField__text {
    max-width: 65px;
}

.bookingPage__kilometerInput--6,
.bookingPage__kilometerInput--6 .inputField__text {
    max-width: 75px;
}

.bookingPage__kilometerInput input::-webkit-inner-spin-button,
.bookingPage__kilometerInput input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.bookingPage__kilometerInput {
    margin: 0 4px;
}

.bookingPagePriceWrapper__kilometerInputWrapper .inputField__text {
    padding: 0 4px 0 0;
    text-align: right;
}

.bookingPage__kilometerInput .inputField__input {
    text-align: center;
}

.bookingPage__priceDisclaimer {
    margin-top: 8px;
}

.bookingPageInsuranceWrapper {
    display: flex;
}

.bookingPage__time {
    flex-direction: column;
}

.bookingPage__time--flexStart {
    align-items: flex-start;
}

.timeLabel {
    height: 20px;
}

.bookingPage__buttonWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 0.75rem;
    margin-bottom: 1rem;
}

.bookingPage dialog .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
}

.bookingPage dialog .buttonContainer button {
    margin: 8px;
}

.carNumberInHeader {
    color: var(--typographyBlack__MediumEmphasis);
    font-size: 0.9rem;
    font-weight: bold;
    margin-left: 0.5rem;
}

.subPageViewSection {
    display: grid;
    row-gap: 0.75rem;
    width: 100%;
    max-width: 346px;
}

@media (max-width: 390px) {
    .bookingPage dialog .buttonContainer {
        flex-direction: column;
    }
}

@media (max-width: 330px) {
    .bookingPageContent {
        row-gap: 1rem;
    }
}

@media (min-width: 1136px) {
    .bookingPage {
        background: white;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: unset;
        right: 24px;
        bottom: unset;
        left: unset;
        width: 27.5rem;
        border: 1px solid rgb(174 174 174 / 20%);
        z-index: 1;
        margin-top: 32px;
        overflow: auto;
        border-radius: 4px;
        min-height: unset;
        max-height: calc(100vh - 6rem);
    }

    .bookingPageCarInfo .carInfo {
        height: 100%;
    }

    .bookingPage__content {
        overflow: auto;
    }
}
