.adminLocationPage div.infoElement__gridItem {
    align-items: flex-start;
}

.adminLocationPage .infoElement ul {
    grid-row-gap: 20px;
}

.editLocationInput__geojsonLinkWrapper {
    display: flex;
}

.editLocationInput__geojsonLink {
    text-decoration: none;
    margin-bottom: 16px;
}

.editLocationInput__geojsonLinkInfo {
    cursor: help;
    margin: 3px 0 0 5px;
}

.adminLocationPage__previewWrapper,
.adminLocationPage__previewWrapper .map {
    min-height: 400px;
}

.editLocationInput__mapEditor {
    margin-bottom: 16px;
}

.editLocation__mapInputSettings {
    margin-bottom: 16px;
    display: flex;
}

.editLocationMapInputSettings__checkBox {
    margin-right: 16px;
    display: flex;
    align-items: center;
}

.editLocationMapInputSettings__checkBox .checkBoxWrapper {
    margin-right: 4px;
}

.editLocationModal .inputField__input--bigTextArea,
.editLocationModal textarea {
    height: 300px;
}

.adminNewLocation .adminNewPageButton {
    margin-top: 20px;
}
