.infoElement {
    margin-bottom: 24px;
    max-width: 480px;
}

.infoElement--wide {
    max-width: unset;
}

.infoElement .button_iconWithLink {
    margin-left: 8px;
}

.infoElement--noPaddingRight {
    padding-right: 0;
}

.infoElement > header,
.infoElement > ul {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
}

.infoElement > header {
    margin-bottom: 8px;
    justify-items: start;
}

.infoElement__gridItem {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}

.infoElement__gridItem--spacer {
    height: 8px;
}

.infoElement__markdownGridItem {
    overflow: hidden;
}

.infoElement__markdownGridItem p {
    margin-top: 8px;
}

.infoElement__optionalButton {
    display: flex;
    margin-bottom: 8px;
}

.telInput,
.emailInput,
.textInput {
    width: 100%;
    background: #f4f4f4;
    border: none;
    border-bottom: 1px solid #0375d4;
    margin: 4px 0;
}

.telInput--invalid,
.emailInput--invalid {
    background: #f4dee1;
}

.infoElement__statusDot {
    width: 8px;
    height: 8px;
    border-radius: 16px;
    margin-right: 8px;
    margin-bottom: 4px;
}

.infoElement__statusDot--green {
    background-color: var(--background_available_green);
}

.infoElement__statusDot--grey {
    background-color: var(--grey);
}
