.prettyMarkdown ul li,
.prettyMarkdown p {
    margin: 0.75rem 0;
}

.prettyMarkdown ul li {
    list-style: disc;
    margin-left: 1rem;
}

.prettyMarkdown h1,
.prettyMarkdown h2,
.prettyMarkdown h3,
.prettyMarkdown h4 {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: var(--16px);
    line-height: 1.125rem;
    letter-spacing: 0.15px;
}
