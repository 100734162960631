.radioButton {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.radioButton > input {
    accent-color: var(--purple__accent);
    width: 1.1rem;
    height: 1.1rem;
}
