.availabilityIndicator {
    display: flex;
    width: 100%;
    margin-top: 6px;
    height: 4px;
}

.availabilityIndicator__beforeIndicator,
.availabilityIndicator__afterIndicator {
    min-width: 16px;
    height: 4px;
}

.availabilityIndicator__startPoint,
.availabilityIndicator__endPoint {
    background: #333333;
    height: 12px;
    min-width: 2px;
    position: relative;
    top: -4px;
    margin: 0 2px;
}
.availabilityIndicator__line {
    display: grid;
    grid-template-columns: repeat(100, auto);
    background-color: #577c37;
    width: 100%;
    height: 4px;
}
