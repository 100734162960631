.linkOrCreateUser {
    position: relative;
    min-height: calc(100vh - 48px);
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 40px 20px;
    text-align: center;
}

.linkOrCreateUser__heading,
.linkOrCreateUser__userInfo {
    margin-bottom: 40px;
}

.linkOrCreateUser__buttons button {
    margin: 16px 0;
}

.linkOrCreateUser__userInfo {
    display: inline-grid;
    grid-template-columns: auto auto;
    text-align: left;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.linkOrCreateUser__backButton {
    text-decoration: underline;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.linkUser .login {
    margin-left: auto;
    margin-right: auto;
}

.linkExistingUser__phoneNoMismatch > * {
    padding-bottom: 16px;
}

.linkOrCreateUser__loaderWrapper,
.linkOrCreateUser__error {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.linkOrCreateUser__loader {
    height: 50px;
    width: 50px;
}

.linkUser__feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.linkUser__feedback button {
    margin-top: 20px;
}

.linkOrCreateUser .login {
    width: 400px;
}

@media (min-width: 1136px) {
    .linkOrCreateUser {
        min-height: calc(100vh - 56px);
    }
}
