/*TODO flytt over all o- c- u- css klasser fra app.css*/
.headingWithButton {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    grid-column: span 2;
}

.adminPageHeader {
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 32px;
}

.adminNewPageHeader {
    display: flex;
    justify-content: center;
    min-height: 32px;
    margin-bottom: 16px;
}
.adminPageHeader__backButton {
    text-decoration: underline;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.adminPageHeader__rightElement {
    position: absolute;
    top: 0;
    right: 0;
}

.toggleShowPasswordButton:hover,
.toggleShowPasswordButton {
    background-color: rgba(0, 0, 0, 0);
}

.toggleShowPasswordButton {
    height: 48px;
    width: 48px;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 4px;
    top: 4px;
}

.timeSpanStartElement {
    -webkit-clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
}

.clearButton {
    border: none;
    background-color: unset;
}

.vippsLogoLink,
.vippsLogoLink:hover,
.vippsLogoLink:active {
    color: white;
}

.vippsLogoLink {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 0.8rem 2rem;
    background-color: var(--vipps-orange-50);
    text-decoration: none;
}

.vippsLogoLink:hover {
    background-color: var(--vipps-orange-60);
}

.vippsLogoLink--small {
    padding: 0.5rem;
}

.vippsLogoLink svg {
    height: 1rem;
    margin-left: 0.5rem;
    margin-top: 0.3rem;
}

.vippsLogoLink span {
    font-size: var(--18px);
    margin-top: 2px;
}

.vippsLogoLink p {
    font-size: var(--18px);
}

.vippsLogoLink__or {
    margin-top: 22px;
    margin-bottom: 8px;
}

.confirmAndCancelButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 16px;
}

.confirmAndCancelButtons a {
    text-decoration: none;
}

@media (max-width: 500px) {
    .confirmAndCancelButtons a {
        margin: 0 auto;
    }

    .confirmAndCancelButtons button {
        margin: 8px 0;
        max-width: unset;
    }
}

@media (min-width: 500px) {
    .confirmAndCancelButtons {
        flex-direction: row;
        align-items: end;
        justify-content: end;
    }

    .confirmAndCancelButtons button {
        margin: 0 8px;
    }
}

@media (min-width: 1136px) {
    .adminPageHeader {
        margin-bottom: 32px;
    }
    .adminNewPageHeader {
        margin: 16px 0 32px;
    }
}
