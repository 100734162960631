.desktopSearchParameters__positionSelect .inputFieldSelector__single-value {
    display: flex;
    align-items: center;
    height: 20px;
}

.inputFieldSelector__control--is-focused .inputFieldSelector__single-value {
    opacity: 0.3;
}

.positionSelectPlaceHolder {
    height: 66px;
}
