.cancel-membership {
    padding: 2rem;
}

.cancel-membership-back-button {
    position: absolute;
    top: 2rem;
    left: 1rem;
}

.cancel-membership__section {
    margin: 1rem;
}
