.reservationListItem {
    border-right: none;
    border-bottom: 1px solid;
    display: flex;
    min-height: 108px;
    width: 100%;
    padding: 7px 20px;
    text-decoration: none;
    flex-direction: column;
    position: relative;
}

.reservationListItem--withNote .carInfoWrapper__carInfo {
    margin-bottom: 24px;
    overflow: hidden;
}

.reservationListItem__reservationInfoWrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.carInfoWrapper__carInfo {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.reservationListItem__carImageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.availabilityWrapper {
    position: absolute;
    top: 13px;
    left: 7px;
}

.reservationListItem__note {
    position: absolute;
    bottom: 8px;
    left: 20px;
    right: 36px;
    font-style: italic;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.reservationListItem__needsEndingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.reservationListItem__needsEndingIndicator {
    position: absolute;
    right: 3px;
    bottom: 3px;
    display: flex;
    align-items: center;
    justify-items: center;
    background-color: var(--softer-orange);
    padding: 0.05rem 0.3rem;
    border-radius: 4px;
}

.reservationNeedsEndingIndicator_iconWrapper svg {
    margin-right: 0.5rem;
    padding-top: 2px;
    width: 1rem;
}

.reservationNeedsEndingIndicator_iconWrapper svg path {
    fill: var(--typographyBlack__Softer);
}

.reservationNeedsEndingIndicator_text {
    color: var(--typographyBlack__Softer);
    font-size: 0.9rem;
}

.reservationListItem__messageIndicator {
    position: absolute;
    top: 0;
    right: 150px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reservationListItem__messageIndicator svg {
    height: 50px;
    width: 50px;
}

.reservationListItem__membership {
    display: none;
    color: var(--purple__accent);
    border: 1px solid var(--purple__accent);
    border-radius: 8px;
    padding: 0.25rem;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: fit-content;
    margin-top: 0.25rem;
}

@media (max-width: 450px) {
    .reservationListItem__messageIndicator {
        right: 10px;
        align-items: flex-start;
    }
}
