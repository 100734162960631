.checkBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.4rem;
    cursor: pointer;
}

.checkBox > input {
    accent-color: var(--button_primary);
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}
