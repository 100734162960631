.membershipStateIndicator {
    color: var(--typographyBlack);
    font-size: 0.8rem;
    border-radius: 4px;
    line-height: 1rem;
    display: flex;
    align-items: center;
    padding: 1px;
}

.stateIndicator-text {
    padding: 0.2rem;
}

.stateIndicator-green {
    background-color: var(--soft-green);
}

.stateIndicator-red {
    background-color: var(--soft-red);
}

.stateIndicator-yellow {
    background-color: var(--soft-yellow);
}

.membershipStateIndicator > svg {
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
    min-height: 1rem;
}

.stateIndicator-iconOnly.stateIndicator-red {
    background-color: var(--red__darker);
}

.stateIndicator-iconOnly.stateIndicator-yellow {
    background-color: var(--yellow);
}

.stateIndicator-iconOnly > svg > path {
    fill: white;
}
