.checkElement {
    display: flex;
    text-decoration: none;
    align-content: flex-end;
    justify-content: center;
    cursor: pointer;
    tab-index: 0;
}

.checkElement--disabled {
    tab-index: -1;
}

.checkElement--disabled {
    cursor: unset;
}

.checkElement__content {
    flex: 1;
}

.checkElement__checkMarkWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 45px;
    overflow: visible;
}

.checkElement__checkMarkWrapper input {
    position: absolute;
    opacity: 0;
}

.checkElement__checkMarkWrapper svg {
    width: 24px;
    height: 24px;
}

.checkElement svg path {
    fill: white;
}

.checkElement__checkMarkWrapper.u-shrinkable--expanded
    svg.checkInCircle
    circle {
    stroke: #9d9ca7;
}

.checkElement--focused .checkElement__checkMarkWrapper svg.checkInCircle circle,
.checkElement:hover svg.checkInCircle circle {
    fill: #ddd;
}

.checkElement--focused .checkElement__checkMarkWrapper svg.checkInCircle path,
.checkElement:hover svg.checkInCircle path {
    fill: #ddd;
}

.checkElement--focused
    .checkElement__checkMarkWrapper--checked
    svg.checkInCircle
    circle,
.checkElement:hover
    .checkElement__checkMarkWrapper--checked
    svg.checkInCircle
    circle {
    stroke: #696969;
}

.checkElement__checkMarkWrapper--checked.u-shrinkable--expanded
    svg.checkInCircle
    circle {
    fill: #9d9ca7;
}

.checkElement__checkMarkWrapper--checked.u-shrinkable--expanded
    svg.checkInCircle
    path {
    fill: white;
}
