.reservationChange {
    border-radius: 5px;
    margin-top: 4px;
}

.reservationChange__header {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.reservationChange__headerTop {
    border-radius: 4px 4px 0 0;
    padding: 4px 8px;
}

.reservationChange__collapsible {
    flex: 1;
}

.reservationChange__collapsible svg path {
    fill: white;
}

.reservationChange__spinner {
    height: 60px;
    width: 60px;
    margin: 16px auto 24px auto;
}

.reservationChangeListElementSimpleView__mainContent {
    display: flex;
    background-color: var(--grey__lighter);
    border: 1px solid var(--grey__mediumLight);
    border-radius: 6px;
}

.reservationChangeListElementSimpleView__mainContentSection {
    /*flex: 1;*/
    padding: 8px 16px;
}

.reservationChangeListElementSimpleView__mainContentSection--new {
    flex: 6;
}

.reservationChangeListElementSimpleView__mainContentSection--old {
    flex: 7;

    background-color: var(--grey__mediumLight);

    -webkit-clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
}

.reservationChangeListElementSimpleView__toggleJsonButton {
    padding: 0;
    height: 32px;
    width: 32px;
}

.reservationChangeListElementSimpleView__toggleJsonButton svg path {
    fill: black;
}
