.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.login__closeModalButton {
    position: absolute;
    top: 0;
    left: 0;
}

.login__header {
    margin-top: 20px;
    height: 50px;
}

.login__forgotPassword {
    width: 80%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px 20px 20px;
}

.login__forgotPasswordInfo {
    text-align: center;
}

.login__forgotPasswordInfo,
.login__forgotPasswordEmailInput {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login__resetPasswordResultMessageWrapper,
.login__loaderWrapper {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login__loader {
    height: 20px;
    width: 20px;
}

.login__resetPasswordResultMessage {
    text-align: center;
}

.login__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.login__passwordInput {
    margin-top: 8px;
}

.login__buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}

.login__rememberCheckDiv {
    width: 100%;
    display: flex;
    align-items: center;
}

.login__rememberCheck {
    margin: 0 0.5rem 0 0.1rem;
}

.login__button {
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    height: 50px;
    width: 200px;

    border-radius: 25px;
    padding: 0.2rem 2rem;
    outline: none;
    cursor: pointer;
}

.login__feedbackBox {
    padding: 8px 0;
    text-align: center;
}

.login__bottomButtons {
    margin-bottom: 20px;
    width: 100%;
    max-width: 250px;
    display: flex;
    justify-content: space-between;
}

.bottomButtons__becomeMemberButton,
.bottomButtons__forgotPasswordButton,
.bottomButtons__becomeMemberButton:hover,
.bottomButtons__forgotPasswordButton:hover,
.bottomButtons__becomeMemberButton:active,
.bottomButtons__forgotPasswordButton:active {
    background-color: rgba(0, 0, 0, 0);
}

@media screen and (max-height: 360px) {
    .login {
        height: calc(100vh - 30px);
        min-height: 228px;
    }

    .login__header {
        display: none;
    }

    .login__form {
        margin-top: 28px;
        padding: 0 8px;
    }
}

@media screen and (max-height: 320px) {
    .login__passwordInput .inputField__input,
    .login__usernameInput .inputField__input {
        height: 40px;
    }

    .login__passwordInput .inputField__input .toggleShowPasswordButton {
        top: 0;
        height: 40px;
    }

    .login__feedbackBox {
        height: 30px;
    }

    .login__button {
        height: 40px;
        width: 160px;
        border-radius: 20px;
    }
    .login__form {
        padding: 0 16px;
    }
}
