.event {
    border-radius: 5px;
    border: 1px solid var(--grey);
    margin-top: 4px;
}

.eventList__header {
    display: flex;
    flex-direction: row;
}

.eventList__title {
    margin-left: 4px;
    display: flex;
    justify-content: flex-start;
}

.eventList__collapse {
    position: relative;
    width: 100%;
    right: 8px;
    top: 0;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.eventList__footerText {
    margin: 8px 4px;
}

.eventTimestamp {
    font-weight: bold;
}

.eventHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.eventHeader__headerTop {
    border-radius: 4px 4px 0 0;
    padding: 4px 8px;
    background-color: var(--grey__dark);
    color: var(--typographyWhite);
}

.eventDescription {
    padding: 4px 8px;
}

.eventBody__container {
    border-top: 1px solid var(--grey);
    padding: 4px 8px;
    display: flex;
}

.eventBody__content {
    width: 1px;
    flex: 1;
    overflow-x: auto;
    font-size: 1.2rem;
}

.eventCollapsible svg path {
    fill: white;
}
