.AdminBillingOverview {
    padding-bottom: 16px;
}

.AdminBillingOverview .adminGridListWrapper {
    margin-bottom: -16px;
}

/*
    INVOICE LINE
*/

.adminBillingAddLine > * {
}
.adminBillingAddLine {
    max-width: 35em;
    display: grid;
    align-items: center;
    grid-template-columns: auto auto 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 4px;
}
.adminBillingAddLine__element--sum,
.adminBillingAddLine__element--quantity,
.adminBillingAddLine__element--vatPercent,
.adminBillingAddLine__element--price,
.adminBillingAddLine .adminInvoiceLineInput {
    max-width: 140px;
}

.adminBillingAddLine__element {
}

.adminBillingAddLine__element--sum,
.adminBillingAddLine__element--price,
.adminBillingAddLine__element--quantity,
.adminBillingAddLine__element--vatPercent,
.adminBillingAddLine__element--invoiced {
    text-align: right;
}

.adminBillingAddLine__element--sum {
    padding-right: 4px;
}

.adminBillingAddLine__element--invoiced .adminBillingLine__input,
.adminBillingAddLine__element--price .adminBillingLine__input,
.adminBillingAddLine__element--quantity .adminBillingLine__input,
.adminBillingAddLine__element--vatPercent .adminBillingLine__input {
    text-align: right;
}

.adminBillingAddLine__element input[type='number']::-webkit-inner-spin-button,
.adminBillingAddLine__element input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.adminBillingAddLine .buttons {
    grid-column-start: 1;
    grid-column-end: 4;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

/*
    PICTURE VIEW
*/
.AdminBillingOverview .adminZoomablePictureView {
    max-width: calc(100vw - 40px);
}

.AdminBillingOverview .inlineZoomPicture {
    margin-bottom: 16px;
}

.AdminBillingOverview .inlineZoomPicture .adminZoomablePictureView {
    height: 400px;
    max-height: 400px;
    overflow: hidden;
}

.adminBillingOverview__pictureNewLineFullscreenView {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #fff;
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.adminBillingOverview__pictureNewLineFullscreenView .adminZoomablePictureView {
    margin-top: 20px;
    width: calc(100vw - 20px);
}

.adminBillingOverview__pictureNewLineFullscreenView__img,
.adminBillingOverview__pictureNewLineFullscreenView__newLine {
    flex: 1 1;
    position: relative;
    display: flex;
    justify-content: center;
}

.adminBillingOverview__pictureNewLineFullscreenView__img {
    align-items: center;
    margin: 16px 0;
}

.adminBillingOverview__pictureNewLineFullscreenView__img
    .adminZoomablePictureView {
    height: calc(100vh - 120px);
}

.adminBillingOverview__pictureNewLineFullscreenView__img--withNewLine
    .adminZoomablePictureView {
    height: calc(100vh - 330px);
}

.adminBillingOverview__pictureNewLineFullscreenView__newLine {
    align-items: flex-start;
}

.adminBillingOverview__pictureNewLineFullscreenView__newLine
    .adminBillingAddLine {
    width: 500px;
    max-width: calc(100vw - 40px);
}

@media (min-width: 1136px) {
}

@media (min-width: 1136px) {
    .AdminBillingOverview .adminZoomablePictureView {
        width: calc(60vw - 60px);
        max-width: 800px;
    }
}
