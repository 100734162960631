.adminNewDamage {
}

.adminNewDamage__contentWrapper {
}

.adminNewDamage__inputWrapper {
    margin-top: 20px;
    width: 400px;
}
