.reservationCarLocation__plainHeader {
    height: 80px;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 50px;
    background-color: white;
}

.reservationCarLocation__plainHeaderBody {
    margin-top: 80px;
}

.reservationCarLocation__plainHeader .closeModalButton {
    position: absolute;
    top: 0;
    left: 0;
}

.reservationCarLocationMap {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    max-width: 1136px;
    max-height: 800px;
    margin: 0 auto;
}

@media (min-width: 1136px) {
    .reservationCarLocationMap {
        position: absolute;
    }
}
