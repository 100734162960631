.carDistance__airDistance {
    margin: 0;
}

.carDistance,
.carDistance__walkDistanceWrapper {
    display: inline-block;
}

.carDistance__walkDistance {
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.carDistance__walkDistance svg {
    fill: #656464;
    height: 18px;
    width: 18px;
}
