.map {
    width: 100%;
    height: 100%;
    z-index: 0;
}

.marker-cluster {
    --opacity: 0.7;
}

.marker-cluster-small {
    background: rgba(181, 226, 140, var(--opacity)) !important;
}

.marker-cluster-small div {
    background: rgba(110, 204, 57, var(--opacity)) !important;
}

.marker-cluster-small div span {
    font-size: 16px !important;
}

.marker-cluster-partial {
    background: linear-gradient(
        90deg,
        rgba(241, 211, 87, var(--opacity)) 50%,
        rgba(181, 226, 140, var(--opacity)) 50%
    ) !important;
}

.marker-cluster-partial div {
    background: linear-gradient(
        90deg,
        rgba(240, 194, 12, var(--opacity)) 50%,
        rgba(110, 204, 57, var(--opacity)) 50%
    ) !important;
}

.marker-cluster-unavailable {
    background-color: rgba(251, 221, 97, var(--opacity)) !important;
}

.marker-cluster-unavailable div {
    background-color: rgba(240, 194, 12, var(--opacity)) !important;
}

.mapChooserContent .leaflet-popup-content-wrapper {
    border-radius: 3px;
}

.mapChooserContent .leaflet-popup-content {
    width: unset !important;
}

.bikePopup {
    padding: 2px 0;
}

.bikePopup__heading {
    text-align: center;
}

.bikePopup__infoGroup {
    display: flex;
    align-items: center;
    margin: 8px 0;
}

.bikePopup__infoGroup > * {
    white-space: nowrap;
    margin: 0 4px;
}

.bikePopup svg {
    height: 32px;
    width: 32px;
}

@media (min-width: 1136px) {
    .map {
        height: calc(100% - 1px);
        overflow: hidden;
    }
}
