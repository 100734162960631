.gcreLanding,
.gcreFeedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.gcrelanding__loaderWrapper {
    width: 100px;
    height: 100px;
}

.gcreFeedback {
    padding: 16px 32px;
    text-align: center;
    background-color: white;
}

.gcreFeedback p {
    max-width: 600px;
    margin-bottom: 16px;
}

.gcreFeedback h4 {
    margin-bottom: 32px;
}

.gcreFeedback button {
    margin-top: 1rem;
    margin-bottom: 10vh;
}

.gcreCreatePersonalMembershipOption {
    min-height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
}

.gcreCreatePersonalMembershipOption .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
}

.gcreCreatePersonalMembershipOption .buttonContainer button {
    margin: 0 1rem;
}

.gcreCreatePersonalMembershipOption .buttonContainer .secondaryButton {
    padding: 0 24px;
    max-width: unset;
}
