.reservationExtensionImpossible {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
}

.reservationExtensionImpossible__info {
    flex: 1;

    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}
