.listItem {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid;
    padding: 12px 15px 12px 15px;
    width: 100%;
    align-items: unset;
}

.listItem__infoWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 2;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 4px;
}

.listItem__availabilityAndCarInfoWrapper {
    display: flex;
    flex-direction: column;
}

.listItem__availability {
    margin-bottom: 4px;
}

.listItem__carInfo__carModel {
    overflow: visible;
    text-overflow: ellipsis;
    text-align: start;
}

.priceInfo {
    display: flex;
}

.priceInfo__loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.listItem__carInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.listItem__carImageWrapper {
    display: flex;
    min-height: 100%;
    width: 100%;
    flex: 1;
}

.listItem__carImage {
    width: 1px;
    flex: 2;
    display: flex;
    align-items: center;
}

.listItem .infoIconContainer {
    position: absolute;
    top: unset;
    left: unset;
    right: 36px;
    bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
}

.listItem .infoIconContainer > div {
    max-height: 24px;
    margin-left: 4px;
}

.infoTriangle {
    width: 0;
    height: 0;
    border-top: 4rem solid var(--links_and_clickables-light);
    border-left: 4rem solid transparent;
    position: absolute;
    right: -9rem;
    top: -0.8rem;
}

.infoTriangle--reservation {
    right: -0.1rem;
    top: 0;
}

.infoTriangle__infoIcon {
    position: absolute;
    left: -1.8rem;
    top: -3.4rem;
}

.infoTriangle__infoIcon path {
    fill: white;
}

.listItem__organizationLogo {
    width: 30px;
    height: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.listItem__organizationLogo svg {
    height: 30px;
    width: 30px;
    margin-bottom: -16px;
}
