.pageLoader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #e6e6e8;
    z-index: 0;
}

.pageLoader__content {
    display: flex;
    justify-content: center;
}
.pageLoaderMenuLoader {
    bottom: 0;
    width: 80px;
    height: 4px;
    background: white;
    position: absolute;
    animation: menuLoading 1s linear;
}

.pageLoader__content {
    max-width: 1440px;
    margin: 0 auto;
    background: #fff;
    height: 100%;
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pageLoaderLoader {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: loaderSpin 1.3s linear infinite;
    border: 2px solid white;
    border-top: 2px solid #cccccc;
}
@keyframes menuLoading {
    from {
        left: 0;
    }
    to {
        left: 50%;
    }
}

@keyframes loaderSpin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
