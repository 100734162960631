.hamburgerMenu__overlay,
.hamburgerMenu__hamburgerMenuWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}

.hamburgerMenu__hamburgerMenuWrapper {
    z-index: 4;
}

.hamburgerMenu {
    position: fixed;
    top: 0;
    display: flex;
    right: 0;
}

.hamburgerMenu__margin {
    width: 48px;
}

.hamburgerMenu__closeButton {
    background-color: transparent;
    border: none;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

.hamburgerMenu__closeIconWrapper {
    height: 14px;
    width: 14px;
}

.hamburgerMenu__menuItems {
    width: calc(100% - 48px);
    /*overflow: scroll;*/
    display: flex;
    flex-direction: column;
}

.hamburgerMenu--show {
    visibility: visible;
    transition: left 300ms, right 300ms, visibility 0ms;
    -webkit-transition: left 300ms, right 300ms, visibility 0ms;
    -moz-transition: left 300ms, right 300ms, visibility 0ms;
}

.hamburgerMenu--hide {
    visibility: hidden;
    transition: left 300ms, right 300ms, visibility 0ms 300ms;
    -webkit-transition: left 300ms, right 300ms, visibility 0ms 300ms;
    -moz-transition: left 300ms, right 300ms, visibility 0ms 300ms;
}

.hamburgerMenu__overlayWrapper {
    transition: 300ms;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
}
.hamburgerMenu__overlayWrapper--show {
    opacity: 1;
}

.hamburgerMenu__overlayWrapper--hide {
    opacity: 0;
}

.toggleHamburgerMenuButton {
    pointer-events: all;
    position: fixed;
    top: 0;
    right: 0;
    height: 2rem;
    width: 2rem;
}

/*DESKTOP*/
@media (min-width: 1136px) {
}
