.availability {
    float: left;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.availability__availabilityDot {
    width: 8px;
    height: 8px;
    border-radius: 16px;
    background-color: #93c531;
}

.availability__availabilityDot--yellow {
    background-color: #e8b900;
}

.availability__availabilityDot--grey {
    background-color: #cccccc;
}

.availability__availabilityText {
    margin-left: 0.3em;
    /*color: #93c531;*/
}

.availability__availabilityText--yellow {
    color: #e8b900;
}

.availability__availabilityText--grey {
    color: #999;
}
