.invoicesPage {
    height: calc(100vh - 48px);
    overflow: auto;
    width: 100vw;
    max-width: 800px;
    margin: 0 auto;
}

.invoicesPage__invoiceList {
    margin: 16px 0;
}

.invoiceListElementWrapper {
    width: 100%;
    padding: 8px 16px 0 16px;
}

.invoiceListElement {
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
}

.invoiceListElement__invoiceInfo {
    flex: 2;
}

.invoiceListElement__invoiceInfo,
.invoiceListElement__links {
    flex: 1;
}

.invoiceListElement__links {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.invoicesPage .vippsMarkLink {
    height: 32px;
}

.invoiceDefinitonlist {
    display: grid;
    grid-template-columns: 6rem 1fr;
    padding: 0.15rem 0;
}

.invoiceDefinitonlist > dt {
    font-weight: normal;
}

.invoiceListElement__paidCheckContainer {
    display: flex;
    align-items: flex-end;
    /*justify-content: center;*/
}

.invoiceListElement__paidCheckContainer svg {
    height: 40px;
    width: 40px;
}

.invoiceListElement__linksContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.invoiceListElement__pdfIcon {
    fill: var(--links_and_clickables);
    width: 2rem;
    height: 2rem;
}

.invoiceListElement__pdfIcon:hover {
    fill: var(--links_and_clickables__hover);
}

.invoiceListElement__pdfLink {
    text-decoration: none;
    font-weight: normal;
    padding: 0;
    margin: 0 0 8px 0;
    max-width: 80px;
}

.invoicesPage .vippsLogoLink {
    display: none;
}

.noInvoice {
    padding: 16px;
}

.invoiceListElement__statusWrapper {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

.invoiceListElement__statusIcon {
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--grey__dark);
    border-radius: 50%;
    margin-right: 0.5rem;
}

.invoiceListElement__statusIcon--paid {
    background-color: var(--background_available_green);
}

.invoiceListElement__statusIcon--pastDue {
    background-color: var(--red__darker);
}

/*LESS TINY MOBILE*/
@media (min-width: 350px) {
    .invoiceListElement__paidCheckContainer svg {
        height: 55px;
        width: 55px;
    }
}

@media (max-width: 400px) {
    .invoiceListElement__linksContainer {
        width: 100%;
        align-items: flex-start;
    }
}

/*NORMAL MOBILE*/
@media (min-width: 450px) {
    .invoiceListElement__paidCheckContainer svg {
        height: 70px;
        width: 70px;
    }

    .invoiceListElement__links {
        flex-direction: row-reverse;
    }

    .invoiceListElement__paidCheckContainer {
        flex: 2;
    }

    .invoiceListElement__linksContainer,
    .invoiceListElement__paidCheckContainer {
        height: 100%;
    }

    .invoiceListElement__paidCheckContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/*WIDISH MOBILE*/
@media (min-width: 500px) {
    .invoicesPage .vippsLogoLink {
        display: flex;
        white-space: nowrap;
        margin-top: 16px;
    }

    .invoicesPage .vippsMarkLink {
        display: none;
    }
}
