.errorPage {
    height: 100%;
    width: 100%;
    padding: 20px;
    background: white;
}
.errorPage__errorHeading {
    margin-bottom: 30px;
}

.errorPage__icon {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}
.errorPage__icon > svg {
    height: 88px;
    width: 88px;
}
.errorPage__icon > svg > path {
    fill: #228650;
}
