.adminInvoice__loader {
    height: 20px;
    width: 20px;
    margin: 0 auto;
}

.adminInvoiceDetails {
    position: relative;
    padding: 8px 16px 0 16px;
}

.adminInvoice_upperButtonWrapper,
.adminInvoice_lowerButtonWrapper {
    position: absolute;
    right: 20px;
    display: flex;
}

.adminInvoice_upperButtonWrapper {
    top: 20px;
}

.adminInvoice_lowerButtonWrapper {
    top: 80px;
    align-items: center;
}

.adminInvoice_lowerButtonWrapper > * {
    margin-left: 16px;
}

.adminInvoiceDetails__infoTables {
    display: flex;
    margin-bottom: 24px;
}

.adminInvoiceDetails__infoTable {
    margin-right: 40px;
}

.adminInvoiceDetailsInfoTable td {
    padding-top: 8px;
}

.adminInvoiceDetailsInfoTable__value {
    padding-left: 8px;
    font-weight: bold;
}

.adminInvoiceDetailsInfoTable__label {
}

.adminInvoiceDetails__linesCollapsible .collapsibleElement__header {
    height: 28px;
}

.adminInvoiceDetailsLine {
    display: flex !important;
    padding: 8px !important;
    border-radius: 6px;
    margin-bottom: 4px;
}

.adminInvoiceDetailsLine__element {
    flex: 1;
    text-align: right;
}

.adminInvoiceDetailsLine__alignLeft {
    text-align: left;
}

.adminInvoiceDetailsLine__long {
    flex: 3;
}
