.entityMessage:not(:last-child) {
    margin-bottom: 32px;
}

.entityMessage > h2 {
    margin-bottom: 16px;
}

.entityMessage > p {
    margin-bottom: 8px;
}
