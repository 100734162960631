.loaderSpinning {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: spin 1.3s linear infinite;
    border: 2px solid #ebebeb;
    border-top: 2px solid black;
}

/*Sjekk om vi kan gjøre denne local også*/
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loaderDots {
    display: flex;
}
.loaderDots--vertical {
    flex-direction: column-reverse;
}

.loaderDots .loaderDot--medium {
    animation: dots 1.4s both infinite;
    margin: 0 1px;
}

.loaderDots .loaderDot--medium:nth-child(2) {
    animation-delay: 0.2s;
}

.loaderDots .loaderDot--medium:nth-child(3) {
    animation-delay: 0.4s;
}

.loaderDot--medium {
    border: 4px solid;
    border-radius: 50%;
}

.centerLoader {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
}

.centerLoader__loaderWrapper {
    width: 40px;
    height: 40px;
}

.loaderGears svg {
    width: 100%;
    height: 100%;
}

.loaderGears .gear1,
.loaderGears .gear2 {
    webkit-animation: spin 2s infinite linear forwards;
    animation: spin 2s infinite linear forwards;
}

.loaderGears .gear1 {
    -webkit-transform-origin: 33.505px 67.195px;
    transform-origin: 33.505px 67.195px;
}

.loaderGears .gear2 {
    -webkit-transform-origin: 69.35px 29.93px;
    transform-origin: 69.35px 29.93px;
    animation-direction: reverse;
}


@keyframes dots {
    0% {
        opacity: 0.3;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}
