.paginationFetchingIndicatorWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
}

.paginationFetchingIndicator {
    height: 60px;
    width: 60px;
}
