.endReservation {
    top: 58px;
    left: 20px;
    right: 20px;
    min-height: 420px;
}

.endReservation__content {
    display: flex;
    flex-direction: column;
}

.endReservation__header {
    font-size: 1.5rem;
}

.endReservation__text {
    padding: 0.6rem 0 1rem;
}

.endReservation__warning {
    display: flex;
    background-color: var(--orange__light);
    border-radius: 10px;
    padding: 0.6rem;
}

.endReservation__radioButtons {
    margin: 1rem 0;
}

.endReservation__radioButtons label {
    margin: 6px 0;
}

.endReservation__radioButtons > legend {
    font-weight: 600;
}

/*DESKTOP*/
@media (min-width: 1136px) {
    /* NOT SO LOW SCREENS */
    @media screen and (min-height: 600px) {
        .endReservation {
            top: 88px !important;
        }
    }
}

/* LOW SCREENS */
@media screen and (max-height: 520px) {
    .endReservation__content {
        padding: 24px 18px;
    }
}

/* MOBILE */
@media (max-width: 1136px) {
    /* LOWER OR REALLY NARROW SCREENS*/
    @media (max-height: 520px), (max-width: 340px) {
        .endReservation {
            overflow: auto;
            min-height: 0 !important;
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0;
            border-radius: 0;
        }
    }
}
