.positionChooser {
    padding: 20px;
}

.positionChooserPageHeading {
    padding-top: 16px;
    display: flex;
    justify-content: center;
}

.positionChooser__searchIcon {
    background-color: transparent;
    float: left;
}

.positionChooser__searchIcon > svg {
    height: 18px;
    width: 18px;
}
.positionChooser__searchIcon > svg > circle {
    stroke: #47a6d2;
}
.positionChooser__searchIcon > svg > rect {
    fill: #47a6d2;
}

.positionChooser__spinnerWrapper {
    padding: 2px;
    height: 100%;
    width: 100%;
}

.positionChooser__input {
    float: left;
    height: 1.5em;
    width: calc(100% - 47px);
    border: none;
    background-color: transparent;
    outline: none;
}

.positionChooser__inputDiv {
    min-height: 3.5em;
    display: flex;
    height: 2em;
    align-items: center;
    padding: 0.3em;
    margin: 0.2em;
    border-radius: 0.5em;
}

.positionChooser_resetInputIcon {
    width: 18px;
    height: 18px;
    background-color: lightgray;
    border-radius: 100%;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.positionChooser__outputDiv {
    padding: 0 1em;
    overflow: auto;
    min-height: 1em;
    margin-bottom: 0.8em;
    flex: 1;
}

.positionChooser__outputLabel {
    border-bottom: 1px solid;
    margin: 0 1em;
}

.positionChooser__locations {
    margin-top: -1px;
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.positionChooser__positionElement {
    display: flex;
    align-items: center;
    height: 3.8em;
    border-top: 1px solid;
    font-weight: bold;
}

.positionChooser__positionElementContent {
    width: 100%;
}

.positionChooser__interanLocationIndicator {
    float: right;
}

.positionChooser_resetInputIcon > svg > path {
    fill: #ffffff;
}
