.editNote {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 100%;
    padding-top: 0.8rem;
}

.editNote__header {
}

.editNote__input {
    margin: 1rem 0;
}

.editNote__buttonWrapper {
    margin-top: 16px;
    margin-bottom: 12px;
}

@media screen and (max-height: 570px) {
    .editNote {
        padding: 12px;
    }
    .editNote__header {
        display: none;
    }

    .editNote__buttonWrapper {
        margin: 8px 0 0 0;
    }
}

@media screen and (min-width: 1136px) {
    .editNote {
        min-height: inherit;
    }
}
