.adminReservationMessage__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;
}
.adminReservationMessage__header div {
    display: flex;
    align-items: center;
}

.adminReservationMessage__toolbar .checkBoxWrapper {
    margin-right: 8px;
}

.adminReservationMessage__pictures {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
}

.adminReservationMessage__pictures .pictureWithText {
    margin: 0 8px 8px 0;
}

.replyIcon path {
    fill: var(--grey);
}

.adminReservationMessage__toolbar {
    display: flex;
    margin: 16px 0 16px 0;
}

.adminReservationMessage__toolbar > * {
    margin-right: 16px;
}

.adminReservationMessageDamage__damageLink {
    display: flex;
    align-items: center;
}

@media (max-width: 1136px) {
    .adminReservationMessage__toolbar {
        flex-wrap: wrap;
    }

    .adminReservationMessage__toolbar > * {
        margin: 8px;
    }

    .adminReservationMessage {
        min-height: calc(100vh - 48px);
    }
}

@media (min-width: 1136px) {
    .adminReservationMessage {
        margin-top: 48px;
        min-height: calc(100vh - 104px);
    }
}
