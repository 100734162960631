.adminListElementWithRemoveButton {
    display: flex;
    align-items: center;
    min-height: 32px;
    border-bottom: 1px solid;
    margin-bottom: 8px;
    grid-column: span 2;
}

.adminListElementWithRemoveButton__text {
    height: 100%;
    padding-left: 4px;

    display: flex;
    align-items: center;
}
