/* lato-300 - latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: local(''),
        url('../resources/fonts/LatoLatin/fonts/LatoLatin-Light.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../resources/fonts/LatoLatin/fonts/LatoLatin-Light.woff')
            format('woff'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
/* lato-300italic - latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 300;
    src: local(''),
        url('../resources/fonts/LatoLatin/fonts/LatoLatin-LightItalic.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../resources/fonts/LatoLatin/fonts/LatoLatin-LightItalic.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-regular - latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('../resources/fonts/LatoLatin/fonts/LatoLatin-Regular.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../resources/fonts/LatoLatin/fonts/LatoLatin-Regular.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-italic - latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: local(''),
        url('../resources/fonts/LatoLatin/fonts/LatoLatin-Italic.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../resources/fonts/LatoLatin/fonts/LatoLatin-Italic.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-semibold - latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    src: local(''),
        url('../resources/fonts/LatoLatin/fonts/LatoLatin-Medium.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../resources/fonts/LatoLatin/fonts/LatoLatin-Medium.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-semibold-italic - latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 500;
    src: local(''),
        url('../resources/fonts/LatoLatin/fonts/LatoLatin-MediumItalic.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../resources/fonts/LatoLatin/fonts/LatoLatin-MediumItalic.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
} /* lato-bold - latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    src: local(''),
        url('../resources/fonts/LatoLatin/fonts/LatoLatin-Semibold.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../resources/fonts/LatoLatin/fonts/LatoLatin-Semibold.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-Bold-italic - latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 600;
    src: local(''),
        url('../resources/fonts/LatoLatin/fonts/LatoLatin-SemiboldItalic.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../resources/fonts/LatoLatin/fonts/LatoLatin-SemiboldItalic.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700 - latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('../resources/fonts/LatoLatin/fonts/LatoLatin-Bold.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../resources/fonts/LatoLatin/fonts/LatoLatin-Bold.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700italic - latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: local(''),
        url('../resources/fonts/LatoLatin/fonts/LatoLatin-BoldItalic.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('../resources/fonts/LatoLatin/fonts/LatoLatin-BoldItalic.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html {
    --12px: 0.75rem;
    --14px: 0.875rem;
    --15px: 0.9375rem;
    --16px: 1rem;
    --17px: 1.0625rem;
    --18px: 1.125rem;
    --19px: 1.1875rem;
    --20px: 1.25rem;
    --21px: 1.3125rem;
    --nav-height: 4rem;
}

body {
    /*font-family: Arial, Helvetica, sans-serif;*/
    /*font-family: 'Lato', sans-serif;*/
    font-family: 'Lato', sans-serif;
    /*font-family: 'Lato', sans-serif;*/
    /*overflow-y: hidden;*/
    -webkit-overflow-scrolling: touch;
    /*background: #fbfbfb;*/
}

.skip-to-main {
    opacity: 0;
    height: 3rem;
    margin-top: -3rem;
    transition: margin-top ease-in-out 100ms;
    display: flex;
    justify-content: center;
    align-items: center;
}

.skip-to-main > a {
    text-decoration: none;
    color: white;
    font-weight: 500;
    outline: 2px solid white;
    padding: 4px;
}

.skip-to-main > a:hover {
    color: white;
}

.skip-to-main:focus-within {
    opacity: 1;
    margin-top: 0;
}

.dele-logo-link {
    margin: 0 2px;
}

.dele-logo-link:focus-visible {
    outline: 2px solid white;
}

p em {
    font-style: italic;
}

p.underline {
    text-decoration: underline;
}

textarea {
    resize: none;
    padding: 8px;
}

.resizable textarea {
    resize: vertical;
}

a {
    color: #016cc5;
    cursor: pointer;
}
a:hover {
    color: #01569e;
}

pre {
    font-family: monospace;
}

.clearFix::after {
    content: '';
    clear: both;
    display: table;
}

#root {
    display: flex;
    width: 100%;
    justify-content: center;
}

.app {
    width: 100%;
}

.nav {
}

.main {
    padding-top: var(--nav-height);
}

.header {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    /*max-width: 1000px;*/
    margin: 0 auto;
}

.header__top {
    height: 24px;
    width: 100%;
}

.header__contents {
    display: flex;
    height: var(--nav-height);
    padding-top: 5px;
    width: 100%;
}

.headerDesktopHeading {
    padding: 0 2rem;
    z-index: 3;
    align-items: center;
    justify-content: center;
}

.headerMenuWrapper {
    display: flex;
    flex: 1;
    margin-left: 16px;
}

.header__logoWrapper {
    margin-left: 12px;
    height: 30px;
}

.headerLogo {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
}

.header__headingWrapper {
    margin-left: 4px;
}

.header__headingWrapperContent--double {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
}

.header__headingWrapperContent--double span {
    display: block;
    text-align: center;
}

.header__headingWrapperContent--double span:first-child {
    margin-bottom: 2px;
}

.header__headingWrapperContent--double span:nth-child(2) {
    font-size: var(--12px);
}

.mobileMenuButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    z-index: 3;
}

.header__buttonsWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 16px;
}

.header__buttonsWrapper > button:focus-visible {
    outline: 2px solid white;
}

.headerOptionButton {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    background: none;
    /*margin-right: 16px;*/
    width: 48px;
}

.headerOptionButton > svg > path {
    fill: white;
}

.headerOptionButton > svg {
    height: 1.5rem;
    width: 1.5rem;
}

.headerOptionButton__activeFiltersIndicator {
    position: absolute;
    top: -4px;
    right: -2px;
}

.menuText {
    margin-top: 3px;
}

.header__label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 100px;
    font-weight: bold;
    font-size: 1em;
}

.mobileNav {
}
.desktopNav {
    display: none;
    align-items: flex-end;
    gap: 0.2rem;
}

.desktopNav > a:focus-visible {
    outline: 2px solid white;
}

.adminNewSubItemsWrapper {
    position: absolute;
    top: 45px;
    right: 0;
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.25);
}
/* TODO Flytt alle disse ut av app.css og inn i en egen fil common-css e.l*/

/* TODO mange av disse er mer c- enn o-*/

/* Objects */
.o-saveButton {
    position: fixed;
    left: 20px;
    right: 20px;
    bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 lightgrey;
    border: none;
    font-size: 1.3em;
    font-weight: bold;
}

/*TODO flytt alle disse ut i en egen css-fil som f.eks importeres av index.js*/

.o-increaseDuration {
    display: flex;
    align-items: center;
    padding-bottom: 2px;
}

.o-increaseDuration__icon {
    /*display: flex;*/
    display: none;
}

.o-increaseDuration__label {
    margin-left: 4px;
    margin-top: 2px;
    display: none;
}

.o-closeButton,
.o-closeButton--right {
    position: absolute;
    top: 0;
    -webkit-tap-highlight-color: initial;
}

.o-closeButton {
    left: 0;
}

.o-closeButton--right {
    right: 0;
}

.o-inputField {
    background: #ffffff;
    border: 1px solid #9d9ca7;
    box-sizing: border-box;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    height: 40px;
}

.o-listWithHeading {
    margin-bottom: 24px;
    max-width: 480px;
    padding-right: 32px;
}

.o-listWithHeading > header {
    margin-bottom: 8px;
    justify-items: start;
}

.o-listWithHeading > ul > li {
    margin-bottom: 4px;
}

/* Utility classes*/
.u-relative-position {
    position: relative;
}

.u-flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.u-flexColumn {
    flex-direction: column !important;
}

.u-fullScreen {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    height: 100%;
    border-radius: 0;
    /*max-width: 1000px;*/
    /*margin: 0 auto;*/
}

.u-shrinkable,
.u-shrinkable > * {
    transition: width 200ms;
}

.u-shrinkable--shrunken,
.u-shrinkable--shrunken > * {
    width: 0 !important;
}

.u-noWrap {
    white-space: nowrap;
}

.u-textBold {
    font-weight: bold;
}

.u-textCapitalized {
    text-transform: capitalize;
}

.u-markdown ul,
.u-markdown ol,
.u-markdown p {
    display: block;
    margin-block-start: 0.2em;
    margin-block-end: 0.6em;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.u-markdown ul,
.u-markdown ol {
    padding-inline-start: 20px;
}

.u-markdown ol {
    list-style: decimal;
}

.u-markdown ul {
    list-style: disc;
}

.u-markdown li {
    display: list-item;
}

.u-shadowContainer {
    box-shadow: 0 3px 8px 0 rgba(238, 238, 238, 1);
}

.u-menuShadow {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.u-shadowTab {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
}

.u-expandWidth8 {
    padding: 8px;
    margin-left: -8px;
    width: calc(100% + 16px);
}

.u-hoverShadow:hover {
    box-shadow: #ddd 0 0 8px 1px;
    cursor: pointer;
}

.u-fullHeight {
    min-height: calc(100vh - var(--nav-height));
}

.u-paddingSidesSmall {
    padding-right: 0.2rem;
    padding-left: 0.2rem;
}

.u-marginTopSmall {
    margin-top: 0.2rem;
}

.o-mobileFullscreen {
    background-color: #fff;
    position: fixed;
    overflow: auto;
    z-index: 4;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}

dialog[open] {
    border: none;
    border-radius: 4px;
    padding: 2rem 3rem;
    min-width: 27rem;
    max-width: 42rem;
    box-shadow: var(--soft-shadow);
}

dialog[open] > .dialog-close {
    position: absolute;
    top: 0;
    left: 0;
}

dialog[open]::backdrop {
    background-color: rgb(0 0 0 / 25%);
    background-color: rgb(115 107 117 / 41%);
}

.o-textAreaWithHeading {
    position: relative;
    /*margin-top: 16px;*/
    width: 100%;
    margin-bottom: 24px;
}

.o-textAreaWithHeading > textarea {
    background: #ffffff;
    box-sizing: border-box;
    border: 1px solid #cccccc;

    border-radius: 4px;
    height: 80px;
    width: 100%;
}

.o-textAreaWithHeading__inlineHeading {
    position: absolute;
    top: -8px;
    left: 8px;
    background-color: #fff;
    padding: 0 4px;
}

.o-textAreaWithHeading--disabledTextArea > textarea {
    background: var(--disabled_background);
}

.o-loadingText {
    margin-top: 16px;
}
.u-displayMobileOnly {
    display: flex !important;
}

.u-displayMobileOnly .navButton:not(.adminNavSubMenu):hover {
    background-color: var(--white__transparent);
    width: 100%;
}

.u-displayDesktopOnly {
    display: none !important;
}
.u-displayDesktopOnly--maxWidth {
    width: 100%;
}
.u-displayDesktopOnly--block {
    display: none !important;
}

.u-multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.appHeaderLoader {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: transform 300ms linear;
}
.appHeaderLoader--loaded {
    display: flex;
    -webkit-transform: none;
    transform: none;
    transition: transform 300ms linear;
}

.inline-block {
    display: inline-block;
}

.alignCenter {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.nonHighlightable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.offlineTag {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    pointer-events: none;
    color: red;
    z-index: 9999;
    text-align: center;
    opacity: 0.5;
}

.info-message {
    margin: 1rem 0;
    background-color: var(--light-blue);
    padding: 0.5rem;
    border-radius: 4px;
    color: var(--typographyBlack);
}

.info-message-important {
    background-color: var(--softer-yellow);
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .u-fullHeight {
        min-height: calc(100vh - 56px);
    }

    .u-fullScreen {
        display: flex;
        flex-direction: column;
        position: relative;
        /* top: -56px; */
        right: 0;
        /* bottom: -56px; */
        left: 0;
        z-index: 3;
        height: 60%;
        min-height: 576px;
        /* height: 100%; */
        /* width: 100%; */
        /* border-radius: 0px; */
        /*max-width: 1000px;*/
        /*margin: 0 auto;*/
    }
    .u-fullScreen--marginTop {
        margin-top: 56px;
    }
    .u-displayDesktopOnly {
        display: flex !important;
        height: 100%;
    }
    .u-displayDesktopOnly--block {
        display: block !important;
    }
    .u-displayMobileOnly {
        display: none !important;
    }
    .u-menuShadowOnlyDesktop {
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    }

    .u-shadowContainer--desktopOnly {
        box-shadow: 0 3px 8px 0 rgba(238, 238, 238, 1);
    }

    .mobileNav  {
        display: none;
    }
    .desktopNav {
        display: flex;
        align-items: center;
    }
    .adminNavBar {
        display: flex;
        background: #5e5e6f;
        position: fixed;
        top: 56px;
        width: 100%;
        height: 50px;
        z-index: 2;
        justify-content: space-between;
    }
    .adminNavBar__leftContent {
        flex: 1;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    ::-webkit-scrollbar {
        display: none;
    }
    .header__contents {
        height: 56px;
        padding-top: 2px;
    }
    .main {
        padding-top: 56px;
        /*max-width: 1440px;*/
        /*margin: 0 auto;*/
        /*background: white;*/
    }
    .desktopBackground {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: #e6e6e8;
    }
    .headerOptionButton {
        margin-right: 32px;
    }
    .u-desktopStickyWrapper {
        position: fixed;
        max-width: 1440px;
        margin: 0 auto;
        top: 56px;
        left: 0;
        right: 0;
        z-index: 1;
    }
    .u-desktopPageWithMargin {
        max-width: 1440px;
        margin: 0 auto;
        background: white;
    }
}

/*MOBILE*/
@media (max-width: 1136px) {
    .u-flexCenter-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/*MOBILE*/
@media (max-width: 1136px) {
    dialog[open] {
        padding: 2rem;
        min-width: unset;
        width: 26rem;
    }
}

@media (max-width: 430px) {
    dialog[open] {
        max-width: 24rem;
    }
}

@media (max-width: 400px) {
    dialog[open] {
        max-width: 20rem;
    }
}
