.membership {
    position: relative;
    z-index: 3;

    background-color: white;
    padding: 16px 20px;
    overflow: auto;
}

.membership__contentWrapper {
    position: relative;
    margin: 0 auto;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.membership .closeModalButton {
    position: absolute;
    top: -12px;
    left: -16px;
}

.membership__name {
    text-align: center;
    padding-top: 2rem;
    /*margin-bottom: 20px;*/
}

.membership__warningBox {
    margin-top: 16px;
    padding: 16px;
    border-radius: 4px;
}

.membershipWarningBox__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.membershipWarningBox__headerText {
    margin-left: 8px;
}

.membership__contentGroup {
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
}

.membershipContactInfo__header {
    margin-bottom: 20px;
}

.membership__inputField {
    margin-top: 20px;
}

.membershipPayment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.membershipPayment > .membershipStateIndicator,
.membershipPayment__vipps > * {
    margin: 10px 0;
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .membership {
        z-index: 0;
    }

    .membership__nameInputField {
        margin-top: 20px;
    }
}

/*MOBILE*/
@media (max-width: 1136px) {
    .membership {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
