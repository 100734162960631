
.imageUploader__content {
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;

    border-radius: 4px;
    overflow: hidden;
}

.imageUploader__content > * {
    height: 100%;
    width: 100%;
}

.imageUploader__working {
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageUploader__working svg {
    fill: #555;
}


.imageUploader__uploadOverlay {
    background-color: rgba(255, 255, 255, 0.5);
}

.imageUploader__uploadOverlay,
.imageUploader__uploadAnimation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.imageUploader__image {
    position: relative;
}

.imageUploader__image button {
    position: absolute;
    top: .5rem;
    right: .5rem;

    width: 2rem;
    height: 2rem;
    border-radius: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--typographyWhite);
}

.imageUploader__image button svg {
    width: 1.1rem;
    height: 1.1rem;
}

.imageUploader__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}