.adminEntityMessage .adminPageContent,
.adminEditEntityMessage {
    width: 400px;
    max-width: calc(100vw - 40px);
    margin: 0 auto;
}

.adminEntityMessage .message {
    border-radius: 5px;
    padding: 16px;
}

.adminEntityMessage .message p {
    margin-bottom: 16px;
}

.adminEntityMessage .adminPageContent {
    width: 500px;
}

.adminEntityMessage .buttonsContainer {
    display: flex;
}

.adminEntityMessage .buttonsContainer > * {
    margin: 0 16px;
}

.adminEditEntityMessage__typeRadio {
    display: flex;
    align-items: center;
    padding: 16px 0;
    flex-wrap: wrap;
}

.adminEditEntityMessage__typeRadio > label {
    margin-right: 20px;
}

.adminEditEntityMessage__typeRadio .radioButton {
    padding-left: 16px;
}

.adminEditEntityMessage .buttonContainer {
    display: flex;
    justify-content: space-around;
}

@media (max-width: 415px) {
    .adminEditEntityMessage .buttonContainer {
        flex-direction: column;
        min-height: 130px;
    }
}

@media (min-width: 1136px) {
    .adminDamage {
        margin-top: 48px;
        min-height: calc(100vh - 104px);
    }
}
