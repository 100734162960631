/*TODO flytt disse nærmere TimeSelect-komponentet*/

.o-dateAndTimeElement {
    margin-bottom: 12px;
}

.o-dateAndTimeElement__label {
    /*display: flex;*/
    /*margin-bottom: 1px;*/
    padding-top: 3px;
}

.o-dateAndTimeElement__time {
    display: flex;
    align-items: flex-end;
}

.o-dateTimeInfo {
    display: flex;
    align-items: center;
}

.o-dateAndTimeInfo--withBorder {
    border-bottom: 1px solid;
    padding: 0 2px;
}

.o-dateTimeInfo__info {
    display: flex;
    align-items: center;
    margin-left: 8px;
    padding-top: 1px;
}

.o-dateTimeInfo__select {
    width: 76px;
}

.o-dateTimeInfo__icon {
    display: flex;
    align-items: center;
    /*padding-bottom: 2px;*/
}

.o-timeInfo {
    display: flex;
}

.o-timeInfo__time {
    display: flex;
    margin-left: 8px;
}

.o-dateAndTimeSelect {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
    width: 185px;
}

.o-dateAndTimeSelect--noMarginBottom {
    margin-bottom: 8px;
}

.o-dateTimeElementWrapper {
    margin-right: 8px;
    position: relative;
    min-width: 95px;
}
.o-dateTimeElementWrapper--big {
    min-width: 137px;
}
.o-dateTimeElementWrapper__border {
    height: 2px;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.o-dateTimeElementWrapper__errorText {
    position: absolute;
    bottom: -20px;
    left: 0px;
}
.o-dateElement {
    padding: 0 4px;
    border: 1px solid;
}

.o-dateElement--noBorder {
    border: none;
}

.o-dateElement__labelAndCloseIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.o-dateLabel {
    padding-top: 3px;
}

.o-dateElement__inputAndCalendarIcon {
    display: flex;
    align-items: center;
}

.o-dateInput {
    display: flex;
    align-items: center;
    margin-left: 8px;
    padding-top: 1px;
}

.o-timeElement {
    display: flex;
    align-items: center;
    padding: 0 2px;
}

.o-timeElement__icon {
    display: flex;
    align-items: center;
    padding-bottom: 2px;
}

.o-timeElement__select {
    width: 76px;
    height: 23px;
}

.o-timeElement__select--big {
    width: 130px;
}

.o-timeElement__info {
    padding-left: 8px;
}
