.dateSelect_datePicker {
    position: absolute;
    top: -2px;
    left: -2px;
}

.dateSelect_datePicker .SingleDatePickerInput__withBorder {
    border: none;
}

.dateSelect_input {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 8px;
}

.dateSelect_input__input {
    display: flex;
    align-items: center;
    margin-left: 8px;
    padding-top: 1px;
}

.selectedDate {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    margin-top: 0.3rem;
    padding: 0.2rem 0.2rem;
}

.dateSelectNoDropdown--disabled {
    border: none;
}

.dateSelectNoDropdown__calendarIcon {
    padding-bottom: 2px;
    margin-right: 8px;
    margin-left: 4px;
}
