.verifyLicenseErrorModal__header {
    height: 50px;
}

.verifyLicenseErrorModal__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 32px 24px 40px 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.verifyLicenseErrorModal__buttonContainer {
    height: 50px;
}
