.removeMemberModal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.removeMemberModal__buttonsWrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 1.5rem;
}
