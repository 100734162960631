.adminImport {
    width: 100%;
    text-align: center;
}

.adminImport__contentWrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.adminImport__fileSelect {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adminImport__fileSelectInfo {
    padding: 0 20px;
}

.adminImport__dropZone {
    width: 100%;
    margin: 16px 0;
}

.adminImport__uploadButton {
    margin: 16px auto 0 auto;
}

.adminImport__errorText {
    margin-top: 8px;
    padding: 16px;
    border-radius: 4px;
}

.adminImportPageFileTypeOptions {
    flex: 1;
}

.adminImportPageFileTypeOptions__header {
    text-align: left;
}

.adminImportPageFileTypeOptions__radio {
    margin: 20px;
}

.adminImportPageFileTypeOptions__radio > label {
    margin: 10px 0;
}

.adminImport .primaryButton svg {
    margin-left: 8px;
}

.adminImportPageFileTypeOptions__info {
    text-align: left;
    margin-bottom: 16px;
}

.adminImport__responses {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.adminImportFile {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 4px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.adminImportFile__name,
.adminImportFile__state,
.adminImportFile__responseFileName {
    text-align: left;
    flex: 1;
    padding-right: 8px;
}

.adminImportFile--header {
    margin-top: 20px;
    font-weight: bold;
}

.adminImportFile--success {
    text-decoration: none;
    cursor: pointer;
}

.adminImportFile__loader {
    height: 20px;
    width: 20px;
}
