
.fullscreenImage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 4;
    overflow: auto;
}

.fullscreenImageWrapper {
    position: relative;
    max-width: 100vw;
    max-height: 100vh;
}

.fullscreenImageWrapper img {
    max-width: 100%;
    max-height: 100%;
}
