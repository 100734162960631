.reservationsFilter__container {
    box-shadow: 0 1px 7px 0px darkgrey;
    display: grid;
    grid-template-columns: 50% 50% auto;
    justify-items: center;
    position: relative;
    background: var(--typographyWhite);
    height: 2.5rem;
    margin: 0;
}
.reservationsFilter__option {
    font-size: 0.75rem;
    /*font-weight: 700;*/
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    text-decoration: none;
    color: var(--grey__darker);
}

.reservationsFilter__option > div {
    margin-bottom: 0.7rem;
}
.reservationsFilter__option:hover {
    background: var(--grey__lighter);
    color: var(--grey__darker);
}
.reservationsFilter__option:after {
    content: '';
    border-bottom: 2px solid var(--typographyWhite);
    width: 0;
    transition: width 0.2s ease-in-out;
    transform: scaleX(0);
    transition: transform 150ms ease-in-out;
}
.reservationsFilter__option--active {
    color: var(--grey__darker);
    font-weight: 500;
}
.reservationsFilter__option--active:after {
    width: 100%;
    border-bottom: 2px solid var(--purple__accent);
    transform: scaleX(1);
}
.reservationsFilter__Option--inactive {
    color: #999999;
}

@media (min-width: 1136px) {
    .reservationsFilter__container {
        grid-template-columns: 15% 15% auto;
    }
}
