/*  OrganizationSelect  */

.organizationSelect {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem 0 1rem;
}

.organizationSelect__options {
    position: relative;

    width: 100%;
    overflow: auto;
}

.organizationSelect__options--content {
    max-height: 80vh;
    overflow: auto;
    padding: 2rem 1rem;
    margin-top: -1rem;
}

.organizationSelect__options:before,
.organizationSelect__options:after {
    content: '';
    position: absolute;

    left: 0;
    right: 0;

    pointer-events: none;

    height: 4rem;
    width: 100%;
}

.organizationSelect__options:before {
    top: 0;
    background-image: linear-gradient(#ffffff, #ffffff00);
}

.organizationSelect__options:after {
    bottom: 0;
    background-image: linear-gradient(#ffffff00, #ffffff);
}

.organizationSelectButton {
    min-height: 10rem;
    min-width: 16rem;
    margin: 1rem auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    border-radius: 8px;
    background-color: var(--typographyWhite);
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    transition: transform 100ms linear;
    padding: 1rem;
}

.organizationSelectButton p {
    font-size: 1.3rem !important;
}

.organizationSelectButton img {
    --size: 5rem;
    height: var(--size);
    width: var(--size);
}

/* MembershipJoin */

.membershipJoinComponent {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
}

.membershipJoinComponent > h4,
.membershipJoinComponent > p {
    margin-bottom: 1rem;
}

.membershipJoinComponent .accordion {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    overflow: hidden;
    width: min(37.5rem, 100vw - 2rem);
}

.membershipJoinComponent .accordion__panel {
    position: relative;
}
.membershipJoinComponent .accordion__panel:before,
.membershipJoinComponent .accordion__panel:after {
    content: '';
    position: absolute;

    left: 0;
    right: 0;

    pointer-events: none;

    height: 0.5rem;
    width: 100vw;
}

.membershipJoinComponent .accordion__panel:before {
    top: 0;
    background-image: linear-gradient(#00000015, #00000000);
}

.membershipJoinComponent .accordion__panel:after {
    bottom: 0;
    background-image: linear-gradient(#00000000, #00000007);
}

.membershipJoinComponent .accordion__button {
    padding: 1rem 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.membershipJoinComponent h4 {
    text-align: center;
}

.membershipJoinCard {
    padding: 0.5rem 1.5rem 2rem 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.5rem;
    grid-template-areas:
        'prices prices'
        'deposit deposit'
        'info info'
        'button button';
}

.membershipJoinCard__prices {
    grid-area: prices;
}

.membershipJoinCard__depositInfo {
    grid-area: deposit;
    display: flex;
    align-items: center;
}

.membershipJoinCard__infoSection {
    grid-area: info;
}

.membershipJoinCard__buttonWrapper {
    grid-area: button;
}

.membershipJoinCard__buttonWrapper button {
    margin: 1rem auto 0 auto;
}

.membershipJoinCard__buttonWrapper p {
    text-align: center;
}

.membershipJoinCard__prices,
.membershipJoinCard__depositInfo,
.membershipJoinCard__infoSection {
    padding: 1rem;
}

.membershipJoinCard__prices {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
}

.membershipJoinCard__prices p:nth-child(even) {
    justify-content: flex-end;
}

.membershipJoinCard__prices p {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0.3rem 0;
}

.membershipJoinCard__prices p span {
    white-space: nowrap;
}

.membershipJoinCard__prices p span:first-child {
    margin-right: 0.5rem;
}

.membershipJoinCard__depositInfo svg {
    margin-bottom: -2px;
    margin-right: 0.5rem;
    height: 16px;
    width: 16px;
    min-height: 16px;
    min-width: 16px;
}

.membershipJoinCard__infoSection p {
    display: flex;
    align-items: center;
}

.membershipJoinCard__infoSection p svg {
    --size: 22px;
    height: var(--size);
    width: var(--size);
    min-height: var(--size);
    min-width: var(--size);

    margin-right: 0.5rem;
}

.membershipJoinCard__infoSection p:not(:first-child) {
    margin-top: 0.5rem;
}

/* IdUploadPage */

.idUploadPage {
    position: relative;
    padding: 1rem;
}

.idUploadPage__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.idUploadPage__loader svg {
    fill: #3a3a4f;
}

.idUploadPage__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.idUploadPage__explanationText {
    max-width: 35rem;
    margin-bottom: 1rem;
}

.idUploadPage__info {
    background-color: var(--light-blue);
    border-radius: 4px;
    padding: 0.4rem;
    align-self: center;
}

.idUploadPage .inputField {
    max-width: 400px;
}

.idUploadPage > p {
    margin: 1rem;
}

.idUploadPage__dropZonesContainer {
    position: relative;
    --dropSize: 200px;
    align-self: center;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    border: 1px solid #b2b2b2;
    border-radius: 4px;

    width: var(--dropSize);
}

.idUploadPage__dropZonesContainer:hover {
    border-color: #737272;
}

.idUploadPage__dropZonesContainer > p {
    text-transform: capitalize;
    position: absolute;
    top: -9px;
    left: 10px;
    padding: 0 4px;
    background-color: var(--typographyWhite);
    z-index: 0;
}

.idUploadPage__dropZone,
.idUploadPage .imageUploader {
    height: var(--dropSize);
    width: var(--dropSize);
    position: relative;
}

.idUploadPage__dropZone {
    height: var(--dropSize);
    width: var(--dropSize);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 4px;

    cursor: pointer;
    z-index: 1;
}

.idUploadPage__dropZone:hover {
    background-color: #008eff11;
}

.idUploadPage__dropZone > svg {
    height: 40px;
    width: 40px;
    fill: #3a3a4f;
}

.membershipDetailForm {
    position: relative;
    padding: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 1136px) {
    .organizationSelectButton:hover {
        transform: scale(1.05);
    }
}

@media (min-width: 630px) {
    .idUploadPage .inputField {
        max-width: 500px;
    }

    .idUploadPage__dropZonesContainer {
        --dropSize: 250px;
    }
}

@media (min-width: 500px) {
    .membershipJoinCard {
        grid-template-areas:
            'prices info'
            'deposit deposit'
            'button button';
    }
}

@media (min-width: 530px) {
    .idUploadPage__dropZonesContainer {
        width: calc((var(--dropSize) * 2) + 2);
    }
}

@media (max-width: 340px) {
    .membershipJoinCard {
        padding: 0 0.5rem 1rem 0.5rem;
    }

    .membershipJoinCard .primaryButton {
        margin: 0 1rem;
    }
}
