.adminMenuOrgChooser {
    position: relative;
    height: 100%;
    margin-right: 2rem;
    z-index: 3;
}

.adminMenuOrgChooser--active .adminMenuOrgChooser__button {
    background-color: var(--white__transparent);
}

.adminMenuOrgChooser.u-displayMobileOnly {
    position: absolute;
    left: 4rem;
}

.adminMenuOrgChooser__header {
    display: flex;
    min-width: 135px;
}

.adminMenuOrgChooser__header--showingAdminLink .adminMenuOrgChooser__button {
    padding-left: 1rem;
}

.adminMenuOrgChooser__header--showingAdminLink .navButton {
    padding: 0;
}

.adminMenuOrgChooser__header--showingAdminLink
    .navButton
    .adminMenuOrgChooser__linkBorderWrapper {
    padding: 0 1rem;
    margin: 0.5rem 0;
    border-right: 1px solid var(--typographyWhite);
    height: calc(100% - 1rem);
}

.adminMenuOrgChooser__header--showingAdminLink .navButton .navButton__navLabel {
    padding-top: 12px;
}

.adminMenuOrgChooser__label {
    padding-left: 1rem;
    height: 100%;
    display: flex;
}
.adminMenuOrgChooser__button {
    position: relative;
    display: block;
    padding-right: 3.5rem;
    padding-left: 2rem;
    height: 100%;
    text-align: left;
    background: none;
    color: var(--typographyWhite);
}

.adminMenuOrgChooser__orgName {
    align-self: center;
    text-align: left;
    color: var(--typographyWhite);
}

.adminMenuOrgChooser__button p {
    display: block;
}

.adminMenuOrgChooser__buttonPreLabel {
    font-size: 12px;
}

.adminMenuOrgChooser__button svg {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
}

.adminMenuOrgChooser__closer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.adminMenuOrgChooser__options {
    pointer-events: none;
    overflow-y: hidden;
    transition: height 300ms linear;

    position: absolute;
    top: 48px;
    right: 0;
    color: var(--links_and_clickables);
    width: max-content;
    min-width: 361px;
}

.adminMenuOrgChooser__optionsWrapper {
    pointer-events: all;
    position: absolute;
    background-color: var(--typographyWhite);

    transition: top 300ms linear;
    right: 0;

    min-width: 135px;
    width: fit-content;
    max-width: 100%;

    border-radius: 0 0 8px 8px;
    border: 1px solid var(--links_and_clickables);
    border-top: none;
}

.adminMenuOrgChooser__optionsWrapper > p {
    color: var(--grey__dark);
    font-size: 12px;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
}

.adminMenuOrgChooser__option {
    list-style: none;
}

.adminMenuOrgChooser__option > * {
    height: 40px;
    padding: 0 1rem;
    text-align: left;
    width: 100%;
    color: var(--links_and_clickables);
}

.adminMenuOrgChooser__option > button {
    display: block;
    background: none;
}

.adminMenuOrgChooser__option > a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.adminMenuOrgChooser__option a:hover,
.adminMenuOrgChooser__option button:hover {
    background-color: var(--blueish-white);
}
.adminMenuOrgChooser__option a:last-child,
.adminMenuOrgChooser__option button:last-child {
    border-radius: 0 0 8px 8px;
}

.adminMenuOrgChooser__needToChooseWarning {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
}

.adminMenuOrgChooser__needToChooseWarningMessage {
    background-color: var(--typographyWhite);
    padding: 3rem;
    border-radius: 8px;
    margin: 1rem;
    text-align: center;
}

@media (min-width: 1136px) {
    .adminMenuOrgChooser__button svg {
        top: 1.5rem;
    }

    .adminMenuOrgChooser__options {
        top: 56px;
    }
}
