.subPageToggleIcons {
    position: fixed;
    right: 0;
    display: flex;
    background-color: white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
}
.subPageToggleIcons__iconWrapper {
    display: flex;
    width: 56px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid;
}
.subPageToggleIcons__iconWrapper--active {
}
.subPageToggleIcons__iconWrapper--active > svg > path {
    fill: var(--links_and_clickables-light);
}
.subPageToggleIcons__iconWrapper:hover {
    background-color: #f5f5f5;
}
