/* BIG TIME SELECT*/
.timeSelect {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.timeSelect > .inputField__label {
    font-size: 0.75rem;
    left: 5px;
}

.timeSelect__label {
    padding-left: 8px;
}

.timeSelect__select {
    width: 100%;
}

/*These classes override class in react-select*/
.timeSelector__control {
    border: none !important;
    box-shadow: none !important;

    background: none !important;
}

.timeSelector__value-container {
    font-size: var(--16px);
    line-height: 1.375rem;
    letter-spacing: 0.5px;
}
.timeSelector__indicators {
}
.timeSelector__indicator-separator {
    display: none;
}

.timeSelector__dropdown-indicator {
    padding-left: 0 !important;
}
.timeSelector__menu {
    top: 32px !important;
    left: -20px !important;
    border-radius: 0px !important;
    width: 114px !important;
}
.timeSelector__menu-list {
}
.timeSelector__option {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--16px) !important;
    line-height: 1.375rem;
    letter-spacing: 0.5px;
    padding-left: 31px !important;
}

.timeSelector__select {
    width: 84px;
    border: none !important;
    /*border-radius: 0px;*/
}

/*SMALL TIME SELECT*/
.smallTimeSelect__select {
    width: 100%;
    top: -1px;
}
.smallTimeSelector__control {
    border: none !important;
    box-shadow: none !important;

    background: none !important;
}

.smallTimeSelector__value-container {
    font-size: var(--15px);
    line-height: 1.3125rem;
    letter-spacing: 0.5px;
}
.smallTimeSelector__indicators {
}
.smallTimeSelector__indicator-separator {
    display: none;
}

.smallTimeSelector__dropdown-indicator {
    padding-left: 0 !important;
}
.smallTimeSelector__menu {
    z-index: 10 !important;
    top: 32px !important;
    left: -20px !important;
    border-radius: 0px !important;
    width: 100px !important;
    height: 180px;
}
.smallTimeSelector__menu-list {
    z-index: 100 !important;
    height: 180px;
}
.smallTimeSelector__option {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--15px) !important;
    line-height: 1.3125rem;
    letter-spacing: 0.5px;
    padding-left: 31px !important;
}

.smallTimeSelector__select {
    width: 84px;
    border: none !important;
    /*border-radius: 0px;*/
}

/* SMALL TIME SELECT WITHOUT BORDER*/
.smallTimeSelectWithoutBorder__select {
    width: 100%;
    top: -1px;
}
.smallTimeSelectorWithoutBorder__control {
    border: none !important;
    box-shadow: none !important;

    background: none !important;
}

.smallTimeSelectorWithoutBorder__value-container {
    font-size: var(--15px);
    line-height: 1.3125rem;
    letter-spacing: 0.5px;
}
.smallTimeSelectorWithoutBorder__indicators {
}
.smallTimeSelectorWithoutBorder__indicator-separator {
    display: none;
}

.smallTimeSelectorWithoutBorder__dropdown-indicator {
    padding-left: 0 !important;
}
.smallTimeSelectorWithoutBorder__menu {
    z-index: 10 !important;
    top: 24px !important;
    left: -20px !important;
    border-radius: 0px !important;
    width: 142px !important;
    height: 180px;
}
.smallTimeSelectorWithoutBorder__menu-list {
    z-index: 100 !important;
    height: 180px;
}
.smallTimeSelectorWithoutBorder__option {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--15px) !important;
    line-height: 1.3125rem;
    letter-spacing: 0.5px;
    padding-left: 31px !important;
}

.smallTimeSelectorWithoutBorder__select {
    width: 84px;
    border: none !important;
    /*border-radius: 0px;*/
}
