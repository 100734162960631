.adminMembershipExternalIdValidation {
    border-radius: 4px;
    margin-bottom: 1rem;
    overflow: hidden;
    max-width: 512px;
}

.adminMembershipExternalIdValidation__header {
    cursor: default;
    display: inline-block;
}

.adminMembershipExternalIdValidation__header > * {
    display: inline-block;
}

.adminMembershipExternalIdValidation__header p {
    margin: 0 1rem 0 .5rem;
}


.adminMembershipExternalIdValidation__expansion {
    background-color: var(--grey__lighter);
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    margin-top: .5rem;
}

.picturesContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1rem;
}

.picturesContainer .thumbnail {
    height: 10rem;
    width: 15rem;
    overflow: hidden;
    border-radius: 4px;
    margin: .25rem;
}

.picturesContainer .thumbnail img {
    height: 100%;
    width: 100%;

    object-fit: cover;
    object-position: center;
}

.adminMembershipExternalIdValidation .checkBox__container {
    display: inline-block;
}

.adminMembershipExternalIdValidation__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.adminMembershipExternalIdValidation__button {
    margin: 1rem;
    display: flex;
    align-items: flex-end;
    font-size: 1.2rem;
    line-height: 1rem;
    border-radius: 6px;
    padding: .5rem 1rem;
    background: none;
}

.adminMembershipExternalIdValidation__button svg {
    margin-right: .3rem;
}

.adminMembershipExternalIdValidation__button--safe {
    color: var(--text_available_green);

}

.adminMembershipExternalIdValidation__button--safe:hover {
    background-color: var(--text_available_green_transparent);
}

.adminMembershipExternalIdValidation__button--safe svg {
    width: 1.5rem;
    fill: var(--text_available_green);
}


.adminMembershipExternalIdValidation__button--dangerous {
    color: var(--red__darker);
}


.adminMembershipExternalIdValidation__button--dangerous:hover {
    background-color: var(--red-transparent);
}

.adminMembershipExternalIdValidation__button--dangerous svg {
    width: 1.2rem;
    height: 1.2rem;
}

.adminMembershipExternalIdValidation__button--dangerous svg path {
    fill: var(--red__darker)
}

.adminMembershipExternalIdValidation .o-modal {
    min-height: unset;
}

.adminMembershipExternalIdValidation .adminModal__body p {
    text-align: center;
}