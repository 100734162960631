.adminModalMessage__content {
    display: flex;
    height: 100%;
    margin-top: 20px;
}

.adminModalMessage__message,
.adminModalMessage__recipients {
    flex: 1;
}

.adminModalMessage__recipientCheckWrapper {
    display: flex;
    flex-direction: column;
}

.adminModalMessage__message {
    display: flex;
    flex-direction: column;
}

.adminModalMessage .inputField {
    flex: 1;
}

.adminModalMessage .inputField__input {
    height: 100%;
    max-height: 500px;
}

.adminModalMessage__recipientGroup {
    margin-bottom: 8px;
}

.adminModalMessage__recipientGroup label {
    padding: 4px;
}

/*MOBILE*/
@media (max-width: 700px) {
    .adminModalMessage__content {
        flex-direction: column-reverse;
    }
}
