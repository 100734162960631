.adminZoomablePictureView {
    position: relative;

    border: 1px solid var(--grey);
    background-color: var(--grey__lighter);

    overflow: hidden;
}

.adminZoomablePictureView__closeButton,
.adminZoomablePictureView__fullScreenButton {
    position: absolute;
    top: 8px;
    z-index: 2;
    border-radius: 4px;
}

.adminZoomablePictureView__fullScreenButton {
    right: 8px;
}

.adminZoomablePictureView__closeButton {
    left: 8px;
    padding: 6px;
}

.adminZoomablePictureView__closeButton svg path {
    fill: black;
}
