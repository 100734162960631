.profileMenuSection {
    background-color: white;
    border-bottom: 1px solid #d8d8d8;
}

.profileMenuSection__header {
}

.profileMenuSection__label {
    font-size: var(--12px);
    color: var(--typographyBlack__MediumEmphasis);
    padding: 0 0.5rem;
}

.profileMenuSection__content {
}
