.adminCarDamagesOverview {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.adminCarDamagesOverview__damageLink {
    margin-bottom: 4px;
}

.adminCarDamagesOverview h5 {
    display: flex;
    align-items: center;
}

.adminCarDamagesOverview__loaderWrapper {
    height: 80px;
    width: 80px;
}
