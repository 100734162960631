.newItemNotification {
    height: 1rem;
    min-width: 1rem;
    border-radius: 1rem;

    padding: 0 0.3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    font-size: var(--12px);
}
