.adminEntityMessageList h5 {
    display: flex;
    align-items: center;
}

.adminEntityMessageList h5 span {
    margin-left: 8px;
    display: inline-block;
}

.adminEntityMessageList__messages a {
    display: block;
    margin-bottom: 4px;
}
