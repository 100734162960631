.searchPage {
    width: 100%;
    min-height: 100vh;
    --messageBarHeight: 0px;
}

.desktopSearchPageWrapper {
    display: flex !important;
    flex: 1;
}

.messageBar {
    position: fixed;
    top: 148px;
    height: var(--messageBarHeight);
    left: 0;
    right: 0;
    z-index: 2;
    padding: 8px 15px 12px 15px;

    background-color: #ebfcf9;
}

.searchParameters {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 1;
    height: 110px;
    padding: 5px 15px 12px 15px;
    display: flex;
    flex-direction: column;
}

.searchResults {
    padding-top: calc(106px + var(--messageBarHeight));
}

.searchResults__results {
    position: relative;
    display: flex;
}
.searchPageMapWrapper {
    flex: 1;
}

.desktopBookingPagePlaceHolder {
    width: 488px;
    height: 60%;
    min-height: 576px;
    background: white;
    padding: 32px 16px;
}

.searchResults__toggleWrapper {
    position: fixed;
    top: calc(170px + var(--messageBarHeight));
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .searchPage {
        min-height: calc(100vh - 56px);
    }
    .searchParameters {
        display: none;
    }
    .searchResults {
        position: relative;
        padding-top: 0;
    }
    .searchResults__toggleWrapper {
        position: absolute;
        top: 34px;
        right: 490px;
    }

    .searchPageWhiteTopMargin {
        height: 32px;
        background: white;
        z-index: 2;
    }

    .searchPageMapWrapper {
        height: calc(100vh - 104px);
        position: relative;
        top: 32px;
    }

    .searchPage .carList__listItemsWrapper {
        min-height: calc(100vh - 104px);
    }

    .messageBar {
        top: 52px;
        height: 36px;
        padding: 12px 15px 12px 24px;
    }
}
