.newReservationDamages {
    margin-bottom: 28px;
}

.newReservationDamages .collapsibleElement__header {
    cursor: pointer;
}

.newReservationDamages__check {
    padding: 4px;
}

.newReservationDamages__check .checkBoxWrapper {
    margin-right: 8px;
}
.newReservationDamages__connectedDamage {
    margin-top: 3px;
    padding-left: 28px;
}
