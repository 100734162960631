.featureTogglesPage {
    padding-top: 72px;
    flex-direction: column;
}

.featureTogglesPage .simpleExpandable,
.featureTogglesPage .simpleExpandable__header,
.featureTogglesPage .simpleExpandable__content {
    width: 300px;
}

.featureTogglesPage .simpleExpandable__header {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.featureTogglesPage .simpleExpandable__content {
    padding: 16px;
}

.featureToggles,
.featureTargetGroups {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.featureToggles__toggles,
.featureTargetGroups__groups {
    padding: 20px;
}

.featureToggle,
.featureTargetGroup {
    border: 1px solid lightgrey;
    border-radius: 4px;
}

.featureTargetGroup__removableElement {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
}

.featureTargetGroup__removableElement .button_iconOnly {
    margin-left: 4px;
}

.featureToggleDeleteButton svg {
    pointer-events: none;
}

.featureTargetGroup__removableElement:last-child {
    margin-bottom: 16px;
}

.featureTogglesPage .inputField__helpText {
    display: none;
}

/*
    TOGGLES
*/

.featureToggle {
    margin: 16px 0;
    display: flex;
    justify-content: flex-end;
}

.featureToggle > button {
    height: 32px;
    width: 32px;
    background-color: hotpink !important;
}

.createFeatureToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    margin-left: -10px;
}

.createFeatureToggle__input {
    max-width: 100%;
}

.featureToggles__addButton {
    padding: 8px;
    margin-left: -40px;
    z-index: 1;
}

.createFeatureToggle__input input {
    padding-right: 48px;
}

/*
    GROUPS
*/

.featureTargetGroup {
    width: 300px;
    margin: 16px 0;
}

.featureTargetGroup .addButtonWithPlus {
    margin: 16px auto;
}

@media (min-width: 650px) {
    .featureTogglesPage {
        flex-direction: row;
    }

    .featureTargetGroups {
        width: 300px;
    }
}

@media (min-width: 1136px) {
    .featureTogglesPage {
        padding-top: 64px;
    }

    .featureToggle__modal {
        padding-top: 20px;
    }

    .featureToggle__modal .closeModalButton {
        top: 0;
    }
}
