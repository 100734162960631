.desktopSearchParameters {
    width: 340px;
    padding: 3rem 24px;
    flex-direction: column;
    margin-right: 4px;
}

.desktopSearchParameters .checkBox {
    padding: 0.35rem 0;
}

.desktopSearchParameters__heading--withResetButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.desktopSearchFiltersSliderHeadings {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.desktopSearchFiltersSlider {
    margin: 0 8px 60px;
}

.desktopSearchFiltersSlider
    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px var(--purple__accentTransparent);
}

.desktopSearchFiltersSlider .rc-slider-handle:active {
    box-shadow: 0 0 5px var(--purple__accent);
}

.searchParameterLabel {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    height: 40px;
    padding: 8px;
    border: 1px solid;
    border-radius: 8px;
    text-decoration: none;
    background-color: white;
    margin-bottom: 8px;
}

.searchPageFilterLoader {
    display: none;
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .searchPageFilterLoader {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 320px;
    }
}
