.profilePage {
    background-color: white;

    overflow: auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.profilePage__contentWrapper {
    width: 400px;
    max-width: calc(100vw - 40px);
    position: relative;
    margin: 0 auto;
    padding-bottom: 20px;
}

.profilePage__section,
.profilePage__contentGroup {
    width: 100%;
    margin: 1rem 0;
}

.profilePage__contentWrapper > .closeModalButton {
    top: -20px;
    left: -20px;
}

.profilePage__closeButton {
    position: absolute;
    left: 0;
}

.profilePage__contactLink {
    display: inline-block;
}

.profilePageInfoBox {
    margin-top: 2rem;
    padding: 1.5rem;
    border-radius: 4px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    background-color: var(--softer-yellow);
}

.profilePageInfoBox > p {
    color: var(--typographyBlack__MediumEmphasis);
    font-size: 1rem;
    line-height: 1.2rem;
    margin: 0.4rem 0;
    position: relative;
}

.profilePageInfoBox > p svg {
    display: inline-block;
    margin-bottom: -5px;
    height: 24px;
    width: 24px;
    z-index: -1;
}

.profilePageInfoBox .createMembershipButton {
    margin-top: 8px;
}

.profilePageInfoBox__header {
    display: flex;
    align-items: center;
}

.profilePageInfoBox__text {
    margin-top: 8px;
}

.profilePage__settingsButtonWrapper {
    background-color: white;
    position: absolute;
    top: -4px;
    right: 0;
}
.profilePage__settingsButton {
    height: 45px;
    width: 45px;
}

.contactInfoElement {
    display: flex;
    margin-bottom: 4px;
}

.contactInfoElement__label {
    width: 3.75rem;
}

.profilePage__driversLicenseInfo p {
    margin-top: 4px;
}

.profileMemberships__noMembershipInfo {
    margin: 20px 0;
}

.profileMemberships__subList {
    margin-top: 20px;
}

.profilePage__memberships {
    padding: 2rem 0;
}

.createMembershipButton {
    float: left;
    cursor: pointer;
}

.profileMemberships__addMembershipButtonWrapper {
    width: 100%;
}

.profileMemberships .primaryButton {
    margin: 0 auto;
}

.administratedMembership {
    width: 100%;
    padding-left: 4px;
    display: flex;
}

.administratedMembership__main {
    padding: 1rem 0 1rem 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.profileMemberships_membership {
    border-radius: 4px;
    margin: 0.4rem 0;
}

.profileMemberships_membership:nth-child(odd) {
    background-color: var(--lighter-blue);
}

.profileMemberships_membership:nth-child(even) {
    background-color: var(--lightest-blue);
}

.profileMemberships_membership:hover {
    background-color: var(--light-blue);
}

.administratedMembership__link {
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.administratedMembership__membershipIconAndName {
    display: flex;
    align-items: center;
    margin: 1rem 0;
}

.administratedMembership__membershipIconAndName > svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.3rem;
}

.administratedMembership__membershipIconAndName > svg > path {
    fill: var(--purple-softer);
}

.administratedMembership__statusText {
    margin-left: 2px;
}

.administratedMembership__memberSummaryAndAddButton {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.administratedMembership__memberSummaryAndAddButton .addButtonWithPlus {
    min-width: unset;
}

.administratedMembership__memberSummaryText {
    flex: 1;
    text-align: right;
}

.connectedMembership {
    margin-top: 4px;
    height: 21px;
    display: flex;
    align-items: center;
}

.profileMemberships_connectedMembershipText {
    flex: 1;
    display: flex;
    max-width: calc(100% - 16px);
    justify-content: space-between;
}

.connectedMembership__name,
.connectedMembership__state {
    white-space: nowrap;
}

.connectedMembership__state {
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;

    min-width: 0;
    min-height: 0;
}

.connectedMembership__name {
    padding-left: 8px;
}

.administratedMembership__membershipStateAndType {
    display: flex;
    justify-content: space-between;
}

.administratedMembership__membershipType {
    display: flex;
    align-items: center;
}

.profileMembership__editButton {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profileMembership__editButton,
.profileMembership__editButton:hover {
    background-color: #00000000;
}

.membershipIcon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profilePage__membershipCard {
    margin-bottom: 8px;
}

.addCardModal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
}

.lostCardButton svg {
    width: 16px;
    margin-right: 0.5rem;
}

.lostCardButton div {
    margin: 0;
    text-transform: uppercase;
}

.lostCardButton > svg > path {
    fill: var(--links_and_clickables__hover);
}

.lostCardButton {
    border: 1px solid var(--links_and_clickables__hover);
    color: var(--links_and_clickables__hover);
    background: var(--typographyWhite);
}

.externalIdMissingNotice {
    margin: 1rem 0;
    padding: 1rem;
    background-color: var(--light-pink);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
}

.externalIdMissingNotice button {
    margin-top: 1rem;
}

.submitIdCard__feedback {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
}

.uploadDriversLicenseButton {
    margin: 0 auto;
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    color: white;
    background-color: var(--purple--lighter);
}

.uploadDriversLicenseButton:hover {
    background-color: var(--purple_button--hover);
}
/*DESKTOP*/
@media (min-width: 1136px) {
    .submitIdCard .o-modal {
        width: 580px;
    }

    .profilePage {
        min-height: calc(100vh - 56px);
        z-index: 0;
    }

    .profilePage__leftContent {
        padding: 20px 0;
    }

    .profilePage__leftContent,
    .profilePage__rightContent {
        flex: 1;
    }
}

/*MOBILE*/
@media (max-width: 1136px) {
    .profilePage {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        padding-top: 20px;
    }

    .profilePage__leftContent,
    .profilePage__rightContent {
        max-width: 100%;
    }

    .submitIdCard .closeModalButton {
        position: fixed;
    }
}
