/* Hiding the date input field supplied with react-dates*/

.SingleDatePickerInput .DateInput {
    opacity: 0;
}

.SingleDatePickerInput {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0);
}

/* Making sure the hidden dateInput is the same as our own overlay */
.DateInput {
    width: 100%;
    height: 2.5rem;
    border-radius: 8px;
}

/* Positioning the dropdown calendar*/
.SingleDatePicker_picker {
    top: 2.6rem !important;
    left: 1px !important;
}

/* Custom month navigation buttons*/
.DayPickerNavigation {
    display: flex;
    justify-content: space-between;
    top: 1.1rem;
    padding: 0 22px 0 23px;
}

/* Wrapper around month text*/
.CalendarMonth_caption {
    /*padding-top: 0px;*/
    /*position: relative;*/
    /*top: 17px;*/

    padding-top: 1rem;
    padding-bottom: 1.5rem;
}

/* The calendar table, spacing above*/
.CalendarMonth_table {
    /*margin-top: 37px;*/
}

/* Day names-header*/
.DayPicker_weekHeader {
    top: 2.4rem;
}

/* Text inside day boxes*/
.CalendarDay {
    /*font-size: 13px;*/
    border: 1px solid #e0e0e0 !important;
    margin: 2px;
}

.availabilityPageContent {
    max-width: 100vw;
    overflow: auto;
}

.availabilityPageContent .DayPicker,
.availabilityPageContent .DayPicker * {
    max-width: min(100vw, 346px) !important;
}

.availabilityPageContent .CalendarMonthGrid {
    max-width: 300vw !important;
}
