.editMembership {
    z-index: 2;

    background-color: white;
    overflow: auto;

    margin: 0 auto;
    width: 600px;
    max-width: calc(100vw - 4rem);

}

.editMembership__contentWrapper {
    position: relative;
    margin: 0 auto 88px auto;
}

.editMembership__header {
    text-align: center;
    margin-bottom: 20px;
}

.editMembership__contentGroup {
    width: 100%;
    margin-top: 16px;
}

.editMembership__typeRadio {
    margin-top: 8px;
}

.editMembership__inputField {
    margin-top: 20px;
}

.editMembership__nameInputFieldWrapper {
    width: 100%;
}

.editMembership__terms {
    margin-top: 28px;
}

.membershipTermsAgreementListItem {
    display: flex;
    margin-bottom: 12px;
}

.membershipTermsAgreementListItem__decoration {
    width: 8px;
    height: 8px;
    border-radius: 42px;
    margin: 8px 8px 0 1px;
    background-color: black;
}

.membershipTermsAgreementListItem__text {
    flex: 1;
}

.membershipTermsAgreementCheck {
    display: flex;
    align-items: center;
}

.membershipTermsAgreementCheck .checkBoxWrapper {
    margin-right: 8px;
}

.editMembership__createButtonWrapper {
    min-height: 88px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editMembership__createButtonWrapper .primaryButton {
    margin: 0 auto;
}

@media (min-width: 1136px) {
    .editMembership {
        z-index: 0;
    }
}
