.expenseExpansion {
    padding: 20px;
}

.expenseExpansion__searchAndDelete,
.expenseExpansion__info,
.expenseExpansion__infoGrid {
    display: flex;
}

.expenseExpansion__info,
.expenseExpansion__searchAndDelete {
    justify-content: space-between;
}

.expenseExpansion__values {
    margin: 0 20px 0 8px;
    font-weight: bold;
}

.expense__deleteModalText {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.adminExpenseSelectReservation .smallRoundedButton {
    margin: 0 0 8px 0;
}

.adminExpenseSelectReservation {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    background-color: white;
    border-radius: 4px;
    padding: 8px;
}

.adminExpenseSelectReservation__reservations {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.adminExpenseSelectReservation__reservationsHeader {
    min-width: 100%;
    margin-bottom: 8px;
}

.adminExpenseSelectReservation__loaderWrapper {
    height: 48px;
    width: 48px;
}

.adminExpenseSelectReservation__reservation {
    display: flex;
    align-items: center;
}

.adminExpenseSelectReservation__reservation .smallRoundedButton {
    margin-left: 8px;
}

.adminExpenseSelectReservation__modalButtons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.adminExpenseSelectReservation__modalButtons > * {
    margin: 0 8px;
}
