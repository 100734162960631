.editReservationMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
}

.editReservationMessage__info {
    margin-bottom: 20px;
}

.editReservationMessage__damageOccurredOnReservation {
    width: 100%;
}

.editReservationMessage__damageOccurredOnReservationHeader {
    font-weight: bold;
}

.editReservationMessage__damageOccurredOnReservationRadioWrapper {
    display: flex;
    padding: 8px 0 16px 0;
}

.editReservationMessage__damageOccurredOnReservationRadioWrapper > * {
    margin-right: 16px;
}

.editReservationMessage__input .inputField__input {
    min-height: 10rem;
}

.editReservationMessage .inputField__helpText {
    display: none;
}

/*PICTURES*/
.editReservationMessage__pictures {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
}

.editReservationMessage__pictures .pictureWithText {
    margin-bottom: 16px;
}

.editReservationMessage .button_4 {
    margin-top: 16px;
    padding: 0 8px;
}

.editReservationMessage .button_4 svg {
    height: 19px;
    width: 19px;
}

.editReservationMessage__loadingText {
    margin-top: 16px;
    white-space: nowrap;
}

.editReservationMessage__temporaryPicture {
    position: relative;
    width: 100%;
    max-width: 320px;
}

.editReservationMessage__temporaryPicture img {
    max-width: 100%;
}
