.situationList_body {
    padding: 0px 1rem;
    overflow: auto;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.situationList__button {
    /* animation: 1s ease-out 0s 1 slideInFromLeft; */
    background-color: transparent;
    border: 1px solid var(--links_and_clickables);
    justify-content: start;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    color: var(--links_and_clickables);
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 1px;

    /* TODO: fjerne margin-bottom, fyfy */
    margin-bottom: 0.75rem;
}

.situationList__button:focus-visible {
    border-color: var(--links_and_clickables__hover);
    color: var(--links_and_clickables__hover);
}

.situationList__button:hover,
.situationList__button:focus-visible {
    background-color: var(--light-blue);
}

.situationList__link:link {
    text-decoration: none;
}

/* @media (max-width: 1136px) {
    .situationList_body {
        animation: 1s ease-out 0s 1 slideInFromLeft;
    }
} */
/* @media (max-width: 1136px) {
    .situationList_body {
        animation: 1s ease-out 0s 1 slideInFromLeft;
    }
} */
.text_box {
    margin-top: 10px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    border-radius: 4px;
    text-align: left;
    align-content: center;
}

.text_box__bullets {
    margin-top: 10px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    border-radius: 4px;
    text-align: left;
    align-content: center;
}
.text_box_header {
    display: flex;
    align-items: center;
}
.text_box_HeaderText {
    margin-left: 4px;
    margin-top: 2px;
}
.solutionPage__text {
    white-space: break-spaces;
    line-height: 2rem;
}

.solutionPage__bullets {
    white-space: break-spaces;
    line-height: 2rem;
}

.parking__description {
    max-height: 280px;
    overflow: auto;
}

.button__box {
    flex-direction: row;
    display: flex;
    width: inherit;
    justify-content: space-evenly;
    align-items: baseline;
}

.button__wrapper {
    margin: 1.5rem 0;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding: 0.5rem;
    border-radius: 8px;
    color: var(--links_and_clickables);
    background-color: var(--light-blue);
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 1px;
    overflow: auto;
    flex-direction: column;
}

.buttonBox__title {
    color: var(--links_and_clickables__hover) !important;
    padding-bottom: 10px;
}

.buttons__all {
    display: flex;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    background-color: var(--grey__light);
    border-radius: 8px;
    padding: 0.5rem;
}

.titleSolution {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.memberCard__image {
    width: 35%;
}

.carHeaderWrapper {
    justify-content: space-around;
    display: flex;
    margin-top: 10px;
}

.carHeader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.solution {
    width: 90%;
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
    background: white;
}

.location__box {
    height: 100%;
    width: 100%;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
}

.solution__preview {
    pointer-events: none;
    box-shadow: 2px 2px 2px 2px #888888;
    margin-bottom: 1.5rem;
}

.solutionPage__mapContainer {
    height: 50%;
    width: 100%;
}

.solutionPage__mapContainer .map {
    height: 360px;
}

@media (min-width: 1136px) {
    .solution {
        overflow: auto;
    }

    .location__box {
        height: unset;
    }
}

@media (max-width: 320px) {
    .headerIcon {
        margin-top: 8px;
        color: #9042c3;
    }
}
