.selectDrivers .closeModalButton {
    position: absolute;
    top: 0;
    left: 0;
}

.selectDrivers__header {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectDrivers__driverLimitInfo {
    margin-bottom: 8px;
}

.selectDrivers_driverSelect {
    position: relative;
    flex: 1;
    width: 100%;
}

.driverSelect__contentWrapper {
    padding: 0 1rem 0.5rem 1rem;
}

.selectDrivers_drivers {
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.selectDrivers__driver {
    min-height: 30px;
}

.selectDrivers__driver .checkBoxWrapper {
    margin: 0 8px 2px 4px;
}

.selectDrivers__saveSuccessMessage {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectDrivers__saveButtonWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .selectDrivers {
        overflow: auto;
    }
}
