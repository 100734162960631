.adminGridListWrapper {
    position: relative;
}

.adminGridList {
    width: 100%;
    margin-bottom: 16px;
}

.adminGridList__cell {
    padding: 4px 8px;
    margin-top: 4px;
    overflow-x: auto;
}

.adminGridList__row {
    display: contents;
    color: var(--grey__darker);
}
.adminGridList__row:hover {
    color: black;
}

/*MOBILE*/
@media (max-width: 1136px) {
    .adminGridListWrapper {
        overflow: auto;
    }

    .adminPage__trips {
        white-space: nowrap;
    }
}
