.searchFilters {
    background-color: #fff;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 11;
}

.searchFilters-inline {
    display: flex;
    flex-direction: column;
}

.searchFilters__header {
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
}

.searchFilters__outer {
    position: fixed;
    background: var(--typographyWhite);
    width: 100%;
    left: 0;
}

.searchFilters__outer::after {
    content: '';
    display: block;
    height: 1px;
    margin: 0 -2rem;
    background-color: var(--grey__mediumDark);
}

.searchFilters__buttonContainer {
    margin-bottom: 1rem;
}
.searchFilters__buttonContainer > .searchFilters__optionElement {
    width: 100%;
}

.searchFilters__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
}

.searchFilters__spacefiller {
    content: '';
    width: 3rem;
}

.searchFilters__content {
    padding-top: 5rem;
    padding-bottom: 4rem;
}
.searchFilters__title {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
}

.searchFilters__optionsGridWrapper {
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    grid-row-gap: 8px;
}

@media (max-width: 300px) {
    .searchFilters__optionsGridWrapper {
        grid-template-columns: 98%;
    }
}

.searchFilters__heading {
    padding: 4px;
}

.searchFilters__matchingCars {
    text-align: center;
    padding: 0.3rem 0;
}

.searchFilters__optionElement:hover,
.searchFilters__optionElement {
    height: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--18px);
    text-align: center;
    border: 1px solid var(--purple__accent);

    background-color: white;
    color: var(--grey__darker);
    font-weight: 400;
}

.selected:hover,
.selected {
    background-color: var(--purple__accent);
    color: var(--typographyWhite);
    font-weight: 500;
}

.searchFilters__seatsSliderWrapper {
    margin: 0 12px 60px;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px var(--purple__accentTransparent);
}

.searchFilters__seatsSliderWrapper .rc-slider-handle:active {
    box-shadow: 0 0 5px var(--purple__accent);
}

.searchFilters__sliderHeadings {
    display: flex;
    justify-content: space-between;
    padding: 10px 6px 10px 4px;
    font-size: 0.85em;
}

.searchFilters__buttonWrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: var(--typographyWhite);
    padding: 0 1rem 1rem;
}

.searchFilters__buttonWrapperInner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
}

.searchFilters__buttonWrapper::before {
    content: '';
    margin: 0 -2rem;
    display: block;
    height: 1px;
    background-color: var(--grey__mediumDark);
}
