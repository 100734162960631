/* normalising button and general styling*/
button,
.aButton {
    cursor: pointer;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    font-weight: 400;
}

button:hover {
    background-color: var(--white__transparent);
}

.primaryButton {
    border-radius: 6px;
    padding: 0.8rem 1.2rem;
    -webkit-tap-highlight-color: initial;
    letter-spacing: 0.06rem;
    transition: background-color 0.1s ease;
}

.secondaryButton {
    border-radius: 6px;
    padding: 0.65rem 1.2rem;
    border: 2px solid;
    /*TODO sjekk og evt fjern?*/
    -webkit-tap-highlight-color: initial;
    transition: background-color 0.1s ease;
}

.secondaryButton:hover {
    /*background: #efffef;*/
    background-color: rgba(94, 127, 2, 0.1); /* Slightly tinted hover effect */
}

.optionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: 24px;
    text-decoration: none;
    flex: 1;
}

.profileMenuOption--link {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.disabled.profileMenuOption--link {
    cursor: default;
}

.profileMenuOption {
    position: relative;
    min-height: 45px;
    width: 256px;
    justify-content: flex-start;
    font-size: 1.05rem;
}
.profileMenuOption > svg > path {
    fill: var(--grey__dark);
}

.profileMenuOption--active > svg > path {
    fill: #016cc5;
}

.greenButton {
    background-color: var(--button_primary);
    color: var(--typographyWhite);
}

.greenButton:hover {
    background-color: var(--button_primary__dark);
}

.redButton {
    background-color: var(--redButton);
}

.redButton:hover {
    background-color: var(--redButton__hover);
}

.greyButton {
    background-color: var(--grey__mediumLight);
}

.greyButton:hover {
    background-color: var(--grey);
}

.smallRoundedButton {
    height: 33px;
    padding: 8px 16px;
    margin-left: 8px;
    border-radius: 4px;
    -webkit-box-shadow: var(--soft-shadow);
    -moz-box-shadow: var(--soft-shadow);
    box-shadow: var(--soft-shadow);
    border: var(--soft-border);
}

.smallRoundedButton:active {
    transform: translate(0px, 3px);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.smallRoundedButton--icon {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 33px;
    padding: 0 16px;
}

.smallRoundedButton--icon .smallRoundedButton__label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
}

.smallRoundedButton--icon .smallRoundedButton__labelText {
    margin: 2px 0 0 4px;
}

.navButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    height: 48px;
    color: white;
    border-bottom: 1px solid;
    cursor: pointer;
    background: #3a3a4f;
    padding-left: 8px;
}

.navButton--external svg {
    margin-left: 8px;
    height: 18px;
    width: 18px;
}

.navButton--disabled {
    display: flex;
    color: #ffffff88 !important;
    cursor: default;
}

.navButton--disabled svg path {
    fill: #ffffff88 !important;
}

a.navButton:not(.navButton--disabled):hover {
    background-color: var(--white__transparent);
}

.navButton:not(.navButton--disabled):hover .navButton__navLabel {
    color: var(--typographyWhite);
}

.navButton::after {
    content: '';
    display: block;
    border-bottom: 2px solid var(--typographyWhite);
    width: 0;
    transition: width 0.15s ease-in;
}

.external-link {
    margin: 0 0 0 10px;
}

.navButton--subItem {
    background: #777690 !important;
}

.navButton--whiteItem {
    background: white !important;
    color: #016cc5 !important;
    position: relative;
    min-height: 45px;
    width: 256px;
    border-bottom: 1px solid #d8d8d8;
    justify-content: flex-start;
}

.navButton--active {
    background: #5e5e6f;
}

.navButton--active::after {
    width: calc(100% + 48px);
    border-bottom: 4px solid #ffffff;
}

.navButton__underline {
    height: 4px;
    width: calc(100% + 64px);
}

.buttonWithIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 88px;
    height: 64px;
    border-radius: 4px;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid #cecece;
    text-decoration: none;
}
.buttonWithIcon:hover {
    border: 1px solid var(--links_and_clickables-light);
}
.buttonWithIcon--rounded {
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 50px;
    text-decoration: none;
    padding-bottom: 5px;
}

.buttonWithIcon--small .buttonWithIcon__text {
    text-transform: uppercase;
    margin: 0 0 0 4px;
    /*height: 14px;*/
}

.buttonWithIcon__text {
    margin-top: 8px;
    margin-bottom: 6px;
}

.button_2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 88px;
    height: 64px;
    border-radius: 4px;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid #ffffff00;
    text-decoration: none;
    color: #016cc5;
}

.button_grid {
    background: transparent;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #016cc5;
    text-decoration: none;
    width: 33.3%;
}

.button_grid svg {
    width: 1.75rem;
    height: 1.75rem;
}

.button_grid:hover {
    color: #01569e;
    background-color: transparent;
}

.button_grid:hover path {
    fill: #01569e;
}

.button_2:hover {
    border: 1px solid #01569e;
    color: #01569e;
}

.button_2:hover > svg > path,
.button_2:hover > svg > circle {
    fill: #01569e;
}

.button_2--noHoverOnSVGpath:hover > svg > path {
    fill: white;
}
.button_2--disabled {
    cursor: default;
    border: 1px solid #b2b2b2;
}
.button_2--disabled > svg > path,
.button_2--disabled > svg > circle {
    fill: #b2b2b2;
}
.button_2--disabled:hover {
    border: 1px solid #cecece;
}
.button_2--disabled:hover > svg > path,
.button_2--disabled:hover > svg > circle {
    fill: #cecece;
}
.button_2--rounded {
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100px;
    text-decoration: none;
    padding-bottom: 5px;
    background-color: white;
}

.button_2--grey {
    background-color: #efeff1;
}

.button_2--small .button_2__text {
    text-transform: uppercase;
    margin: 0 0 0 4px;
}

.button_2__text {
    margin-top: 8px;
    margin-bottom: 6px;
}

.editReservationButton svg {
    height: 23px;
    width: 24px;
}

.button_3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    height: 52px;
    width: 100px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
    justify-content: flex-start;
    margin: 0 4px;
    border-bottom: 4px solid transparent;
}
.button_3--active {
    border-bottom: 4px solid white;
}

.button_3__text {
    padding-top: 1px;
}

.button_4 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    width: auto;
    border: 1px solid var(--links_and_clickables);
    color: var(--links_and_clickables);
    border-radius: 8px;
    padding: 0.2rem 0.6rem;
    text-decoration: none;
    background-color: #00000000;
}

a.button_4 {
    width: fit-content;
}

.button_4:focus-visible {
    border-color: var(--links_and_clickables__hover);
    color: var(--links_and_clickables__hover);
}

.button_4:hover,
.button_4:focus-visible {
    background-color: var(--light-blue);
}

.button_4.smallSmsButton svg {
    padding: 3px;
}

.button_icon_only,
.button_icon_only:hover {
    background-color: rgba(0, 0, 0, 0);
}

.button_icon_only svg {
    height: 36px;
    width: 36px;
    padding: 0;
}

.button_icon_only:hover svg path {
    fill: var(--links_and_clickables__hover);
}

.smallFiltersButton {
    position: relative;
    min-width: 0;
    padding: 0 8px;
}

.smallFiltersButton > svg {
    padding: 1px;
}
.smallFiltersButton > svg path {
    fill: #016cc5;
}

.smallFiltersButton__activeFiltersIndicator {
    position: absolute;
    top: -8px;
    right: -8px;
}

.smallFiltersButton__activeFiltersIndicator svg circle {
    stroke: #8bbe00;
}

.button_4:hover > svg > path {
    fill: #01569e;
}

.button_4--disabled:hover > svg > path {
    fill: #737272;
}

.button_4--disabled:hover,
.button_4--disabled {
    cursor: default;
    border: 1px solid #b2b2b2;
    color: #b2b2b2;
}

.button_4--noMinWidth {
    min-width: initial;
}
.button_4--active > svg > path {
    fill: #016cc5;
}

.button_4__text {
    display: flex;
    align-items: center;
    padding-left: 2px;
    white-space: nowrap;
}

.button_4__text--center {
    margin: 0 auto;
}

.button_4__text--paddingLeft {
    padding-left: 8px;
}

.button_iconOnly {
    flex-direction: column;
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    height: 32px;
    width: 32px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1px;
}
.button_iconOnly:hover {
    background-color: #f5f5f5;
}

.button_iconOnly--noHover {
    flex-direction: column;
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    height: 32px;
    width: 32px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1px;
}

.button_iconOnly--small {
    height: 19px;
    width: 19px;
    margin-bottom: 0;
}

.button_iconWithLink {
    /* border: 1px solid; */
    /* border-radius: 8px; */
    /* height: 24px; */
    /* width: 48px; */
    border-bottom: 1px solid;
    /* height: 24px; */
    /* width: 24px; */
    -ms-flex-direction: column;
    /* flex-direction: column; */
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    background-color: transparent;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 2px;
}

.button_iconWithLink__text {
    color: #016cc5;
}

.button_iconWithLink:hover {
    background-color: #f5f5f5;
}

.button_iconWithLink__text {
    margin-left: 4px;
    padding-right: 4px;
}

.button_listWithLabel svg {
    padding: 3px;
}

.button_listWithLabel svg path {
    fill: #016cc5;
}

.button_iconWithLink:hover svg path {
    fill: #01569e;
}

.button_iconAndText {
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1px;
}
.button_iconAndText:hover {
    background-color: #f5f5f5;
}
.button_iconAndText__icon {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button_iconAndText__text {
    padding: 2px 16px 0 3px;
}

.button__nextTo {
    text-decoration: none;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 0;
    background-color: #fff;
    color: var(--links_and_clickables);
}

.button__nextTo path {
    fill: var(--links_and_clickables);
}

.button__nextTo--text {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-left: 1rem;
}

.button__nextTo svg {
    width: 20px;
}

.button__nextTo:hover {
    background-color: var(--links_and_clickables);
    color: #fff;
}

.button__nextTo:hover path {
    fill: #fff;
}

.button_back {
    background-color: var(--links_and_clickables);
    color: white;
    text-decoration: none;
    margin-top: 1rem;
    width: 100%;
    border: 2px solid var(--links_and_clickables);
    justify-content: center;
    padding: 0.5rem;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0.75rem;
    overflow: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.button_back:hover,
.button_back:focus-visible {
    background-color: var(--links_and_clickables__hover);
}

.button_back svg {
    width: 1rem;
    height: 1rem;
}

.button_back path {
    fill: white;
}

.button_simple {
    color: var(--links_and_clickables-light);
    font-size: 0.9rem;
    padding: 0.2rem;
    background: transparent;
    border-radius: 4px;
    transition: background-color 100ms ease-in-out;
}
.button_simple:hover {
    background-color: var(--light-blue);
}

@media (min-width: 1136px) {
    .navButton {
        padding: 0 24px;
        border: none;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        background: #ffffff00;
        position: relative;
        height: 100%;
    }

    .navButton--disabled {
        cursor: default;
    }

    .navButton--external .navButton__navLabel {
        margin-right: 32px;
    }

    .navButton--external .navButton__navLabel::first-letter {
        text-transform: capitalize;
    }

    .navButton--external svg {
        position: absolute;
        right: 32px;
        top: 19px;
    }

    .navButton--subItem,
    .navButton--whiteItem {
        justify-content: center;
        align-items: flex-start;
        border-bottom: 1px solid;
    }
    .navButton--whiteItem {
        background: initial;
        color: initial;
        border-color: #d8d8d8;
    }
    .navButton--whiteItem:hover {
        background: #f5f5f5 !important;
        color: #01569e !important;
    }
    .navButton--active {
        background: #ffffff00;
    }
    .navButton__navLabel {
        padding-top: 20px;
    }
}

@media (max-width: 1136px) {
    .navButton::after {
        content: '';
        display: block;
        border-bottom: 2px solid var(--typographyWhite);
        width: 0;
        transition: width 0.1s ease-in;
    }

    .navButton--active::after {
        width: 0;
    }
}

@media (max-height: 550px) {
    .button_2 {
        height: 3.75rem;
        width: 3.75rem;
    }

    .button_2 .button_2__text {
        margin-top: 4px;
        margin-bottom: 6px;
    }
}
