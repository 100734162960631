.modalHeader {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

.modalHeader__heading {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalHeader__closeModalButtonArea {
    flex: 1;
}

.modalHeader__rightElement {
    flex: 1;
}
