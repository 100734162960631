.waypointLink {
    cursor: pointer;
}

.waypointLink__close {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    width: 40px;
    border-radius: 40px;
}
