.carPosition {
    margin-bottom: 16px;
    border-radius: 4px;
    border: 1px solid;
    overflow: hidden;
}

.carPositionHeader {
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.carPosition svg {
    transition: transform 200ms;
}

.carPosition:not(.hidden) svg {
    transform: rotateX(180deg);
}

.carPositionMapWrapper {
    overflow: hidden;
    height: 400px;
    transition: height 200ms;
}

.carPosition.hidden .carPositionMapWrapper {
    height: 0;
}

.carPositionSection .button_4 {
    padding-left: 6px;
    margin: 0 0 4px 8px;
}
