.adminSearchPage {
    justify-content: space-between;
}

.adminSearchPage {
    justify-content: space-between;
    -webkit-tap-highlight-color: transparent;
    padding: 0 16px;
}

.adminSearchPage__contents {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.adminSearchPage_Heading {
    display: flex;
    justify-content: center;
    margin: 16px 0 32px 0;
}

.adminSearchPage_button {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    -webkit-tap-highlight-color: initial;
}

/*TODO add more*/
@media (min-width: 1136px) {
    .adminSearchPage {
        padding-top: 48px;
    }
}
