.partnerListItem {
    display: inline-block;
    padding: 0.7rem 1rem;
    border-radius: 4px;
    width: 100%;
    max-width: 346px;
    background-color: var(--blueish-white);
    border: 1px solid var(--links_and_clickables-light);
    margin-top: 0.5rem;
}

.partnerListItem p {
    margin-bottom: 0.3rem;
}
