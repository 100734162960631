.dateAndTimeSelectLabel {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}
.dateAndTimeSelectLabel__area {
    width: 48%;
}
.dateAndTimeSelectLabel__area--marginLeft {
    margin-left: 6px;
}

.dateAndTimeSelectLabel__divider {
    position: relative;
    top: 2px;
}

.dateAndTimeSelectLabelIcon > svg > path {
    fill: var(--purple__accent);
    fill-opacity: 0.5;
}

.dateAndTimeSelectLabelIcon > svg {
    width: 1rem;
    height: 1rem;
}

.dateAndTimeSelectLabelIcon {
    margin-top: 2px;
    margin-right: 4px;
}
