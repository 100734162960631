.adminCarAssignments {
    margin: 16px 20px 16px 0;
    max-height: 380px;
    overflow-y: auto;
}

.adminCarAssignment__addButton {
    height: 50px;
    width: 160px;
    margin: 0 auto 16px auto;

    border-radius: 5px;
}

/* Nonlexical button "label" alternative */
/*.adminCarAssignmentAddButton__plusSign {*/
/*    height: 40px;*/
/*    width: 40px;*/
/*    -webkit-clip-path: polygon(0% 40%,0% 60%,40% 60%,40% 100%,60% 100%,60% 60%,100% 60%,100% 40%,60% 40%,60% 0%,40% 0%,40% 40%);*/
/*    clip-path: polygon(0% 40%,0% 60%,40% 60%,40% 100%,60% 100%,60% 60%,100% 60%,100% 40%,60% 40%,60% 0%,40% 0%,40% 40%);*/
/*    !*background-color: #016CC5;*!*/
/*    background-color: white;*/
/*}*/

.adminCarAssignment {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 6px;
}

.adminCarAssignment__nameAndButtons,
.adminCarAssignment__time {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
}

.adminCarAssignment__locationName {
    flex: 1;
    margin-left: 16px;
}

.adminCarAssignment__deleteButton,
.adminCarAssignment__editButton {
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.adminCarAssignment__deleteButton:hover,
.adminCarAssignment__deleteButton:active,
.adminCarAssignment__deleteButton,
.adminCarAssignment__editButton:hover,
.adminCarAssignment__editButton:active,
.adminCarAssignment__editButton {
    background-color: unset;
}

.adminCarAssignment__editButton:hover svg path {
    fill: #01569e;
}

.adminCarAssignment__deleteButton:hover svg path {
    fill: #911616;
}

.adminCarAssignment__parkingDescription {
    padding: 0 0 8px 16px;
    margin-top: -8px;
}

.adminCarAssignment__times {
    display: flex;
    background-color: pink;
}

.adminCarAssignment__hoursMinutes {
    margin-right: 4px;
}

.adminCarAssignment__date {
}

.adminCarAssignment__startTime {
    flex: 10;
}

.adminCarAssignment__endTime {
    flex: 9;
}

.adminCarAssignments__modalFields {
    flex: 1;
}

.confirmDeleteAssignmentModal__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    text-align: center;
}

.adminCarAssignmentModal__locationSelect .inputField__helpText {
    display: none;
}

.adminCarAssignmentModal__timeSelect .SingleDatePicker_picker {
    z-index: 3;
}

.adminCarAssignmentModal__parkingDescription {
    margin-top: 16px;
}

.adminCarAssignmentModal__chargingBay {
    margin-top: 16px
}