.adminEnumSelect {
    display: flex;
    align-items: center;
    justify-content: center;
}

.adminEnumSelect__label {
    margin-right: 8px;
}

.adminEnumSelect__selectWrapper {
    border: 1px solid var(--grey);
    background-color: var(--typographyWhite);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.adminEnumSelect__selectWrapper select {
    background-color: unset;
    border: unset;
    padding: 0 4px;
}
