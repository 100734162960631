.paymentFeedbackPage {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 40px 20px;
    text-align: center;
}

.paymentFeedbackPage__loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
}

.paymentFeedbackPage h4,
.paymentFeedbackPage h5,
.paymentFeedbackPage p {
    margin-bottom: 20px;
}

.paymentFeedbackPage .waypointLink__close {
    position: absolute;
    left: 8px;
    top: 8px;
}

.paymentFeedbackPage .waypointLink {
    text-decoration: none;
}
