.errorModal {
    position: relative;
    flex: 1;
}

.errorModal__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
}
.errorModal__body {
    padding: 16px;
    margin-left: 32px;
}
.errorModal__optionalInfo {
    padding: 10px;
}
.errorModal__buttons {
    display: flex;
    margin-top: 20px;
}
