.reservationsPage {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
}
.desktopReservationPlaceTaker {
    width: 35.5rem;
    background: white;
    height: 60%;
    min-height: calc(100vh - 3.5rem);
}

.reservationsPage__body {
    padding-top: 2.5rem;
    border-right: 2px solid #f1f0f0;
}

.reservationsPage .reservationListLoader {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.reservationsPage .reservationListLoader p {
    margin-bottom: 16px;
}

.reservationsPage__topButtons {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 2;
}

.reservationsPage__header {
    font-size: 1.5em;
    border-bottom: 1px solid;
    padding: 10px;
}
.reservationsPage__content {
    height: calc(100vh - 110px);
    overflow: scroll;
}

.noReservations {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    text-align: center;
}

.reservationsPage__body--pagination,
.reservationsPage > .reservationListLoader {
    height: calc(100vh - 3rem);
}

.reservationsPage__body--pagination {
    overflow-x: auto;
}

@media (min-width: 1136px) {
    .reservationsPage {
        position: relative;
    }
    .reservationsPage__topButtons {
        max-width: 1440px;
        margin: 0 auto;
        left: 0;
        right: 0;
        z-index: 1;
    }
}
