.availabilityPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.availabilityPage > .closeModalButton {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
}

.availabilityPageContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow: auto;
}
.availabilityPageContent__dateAndTimePickers {
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

.availabilityPageDateAndTimePicker {
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    width: 19rem;
    min-height: 34px;
}
.availabilityPageDateAndTimePicker__label {
    padding-left: 3px;
    padding-top: 2px;
    display: flex;
    align-items: flex-end;
}
.availabilityPageDateAndTimePicker__selectors {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    width: 100%;
}

.availabilityPageTimeSelect {
    height: 1.4rem;
    /* width: 104px; */
    width: 143px;
    border-bottom: 2px solid;
    margin-top: 1px;
    /* border-radius: 4px; */
}

.availabilityPageTimeSelect .inputField__label {
    top: -12px;
    left: -2px;
}

.availabilityPageTimeSelect .timeSelect__label {
    padding-left: 6px;
}

.availabilityPageTimeSelect--disabled {
    border: none;
}

.availablePeriodListItem__wrapper {
    list-style: none;
    display: flex;
    justify-content: center;
}

.availablePeriodsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.availablePeriodsContainer__availabilityInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    min-height: 24px;
}

.availablePeriodInfo {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;

    /*margin-left: 24px;*/
}
.availablePeriodInfo__indicator {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    margin-right: 8px;
}

.availablePeriodsContainer__listHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    min-height: 20px;

    /*margin-left: 24px;*/
}

.availablePeriodsContainer__list {
    width: 100%;
    /*overflow: scroll;*/
    margin-bottom: 16px;
}

.availablePeriodListItem {
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--background_light_green);
    position: relative;
    border-radius: 0 0 8px 8px;
    max-width: 346px;
    min-width: 346px;
    background: var(--background_light_green);
}

.availablePeriodListItem--smallScreenFix {
    margin-bottom: 0.5rem;
}

.availablePeriodListItem__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 0.5rem 0.5rem;
}

.availablePeriodListItem__block svg path {
    fill: var(--grey__darker);
}

.availablePeriodListItem__block p {
    color: var(--grey__darker);
}

.availablePeriodListItem__end {
    width: 100%;
}

.availablePeriodListItem__start {
    background: var(--background_lighter_green);
    width: 100%;
    clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
}

.availablePeriodListItem__add {
    background-color: var(--links_and_clickables);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
    width: 100%;
}

.availablePeriodListItem__add:hover,
.availablePeriodListItem__add:focus-visible {
    background-color: var(--links_and_clickables__hover);
}

.availablePeriodListItem__add p {
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 1.5px;
    color: white;
    margin-left: 1rem;
    margin-bottom: 0;
}

.availablePeriodListItem__changeTime {
    background-color: var(--links_and_clickables);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
    text-decoration: none;
}

.availablePeriodListItem__changeTime:hover,
.availablePeriodListItem__changeTime:focus-visible {
    background-color: var(--links_and_clickables__hover);
}

.availablePeriodListItem__changeTime p {
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 1.5px;
    color: white;
    margin-left: 1rem;
    margin-bottom: 0;
}

.availablePeriodListItem__changeTime path {
    fill: white;
}

.availablePeriodListItem__changeTimeDisabled {
    background-color: var(--grey__dark);
    pointer-events: none;
}

.availablePeriodListItem__addIcon path {
    fill: white;
}

.availabilityPage .primaryButton {
    margin-bottom: 1rem;
}

@media screen and (max-height: 600px) {
    .availabilityPage {
        padding: 0.5rem 0 1rem 0;
    }
}

@media screen and (max-width: 380px) {
    .availablePeriodListItem__block {
        flex-wrap: wrap;
    }

    .availablePeriodListItem {
        width: 100%;
        max-width: none;
        min-width: auto;
    }

    .availablePeriodListItem--smallScreenFix {
        max-width: 300px;
        min-width: 280px;
    }
}

@media (min-width: 1136px) {
    .availabilityPage {
        height: fit-content;
    }
}
