.adminReservationMessageListElement {
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 8px;
    border: solid 1px;
}

.adminReservationMessageListElement p {
    margin-right: 8px;
}

.adminReservationMessageListElement--DAMAGE {
    border-color: var(--red__dark);
    background-color: var(--red__dark);
}

.adminReservationMessageListElement--DAMAGE:hover {
    border-color: var(--red__darker);
    background-color: var(--red__darker);
}

.adminReservationMessageListElement--DAMAGE svg path {
    fill: var(--red__dark);
}

.adminReservationMessageListElement--PROBLEM {
    border-color: var(--orange__dark);
    background-color: var(--orange__dark);
}

.adminReservationMessageListElement--PROBLEM:hover {
    border-color: var(--orange__darker);
    background-color: var(--orange__darker);
}

.adminReservationMessageListElement--PROBLEM svg path {
    fill: var(--orange__dark);
}

.adminReservationMessageListElement--UNHANDLED svg path {
    fill: var(--typographyWhite);
}

.adminReservationMessageListElement--UNHANDLED,
.adminReservationMessageListElement--UNHANDLED:hover {
    color: var(--typographyWhite);
}

.adminReservationMessageListElement--HANDLED,
.adminReservationMessageListElement--HANDLED:hover {
    color: var(--typographyBlack);
    background-color: var(--typographyWhite);
}
