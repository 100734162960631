.adminBillingDetails__topContent {
    padding: 16px;
    display: flex;
}

.adminBillingRow__details {
    width: 100%;
    margin-bottom: 16px;
}

.adminBillingDetails__main {
    padding: 16px;
}

.adminBillingLines {
    padding: 0 16px 16px 16px;
}

.adminBilling__buttonWrapper {
    display: flex;
    flex-direction: column;
}

.adminBilling__buttonWrapper--noReservation {
    width: 100%;
}

.adminBillingDetails__buttonText {
    color: white;
}

.adminBillingDetails__addToInvoiceButton {
    margin-bottom: 8px;
}

.adminBillingReservation {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    min-height: 115px;
}

.adminBillingRow__systemNote {
    margin-left: 8px;
    padding: 8px;
}

.adminBillingReservation__adminNote {
    padding: 8px;
}

.adminBillingReservation__info,
.adminBillingReservation__details {
    margin-right: 16px;
    display: flex;
}

.adminBillingLines__header,
.adminBillingLine__flexElement,
.adminBillingReservationInfo {
    display: flex;
}

.adminBillingRow__systemNote,
.adminBillingReservation__adminNote {
    border-radius: 4px;
    padding: 8px 16px;
    margin: 0 8px;
    max-width: 350px;
}

.adminBillingAddLineButton {
    position: relative;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    display: flex;
    font-weight: bold;
}

.adminBillingAddLineButton__icon,
.adminBillingAddLineButton__text {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.adminBillingAddLineButton__icon {
    width: 40px;
}
