.adminPageWrapper {
    min-height: calc(100vh - 48px);
    display: flex;
    justify-content: center;
}

.adminPageWrapper--fullScreen {
    height: unset;
    position: absolute;
    z-index: 2;
    top: 48px;
    left: 0;
    right: 0;
    background-color: white;
    padding-top: 0;
}

.adminPage {
    width: 100%;
    padding: 16px 20px 32px 20px;
    background: white;
}

.adminPageContent {
    display: flex;
    flex-direction: column;
}

.adminPage__horizontalLimit--400 {
    max-width: 400px;
    margin: 0 auto;
}

.adminNewPageContent {
    display: flex;
    flex-direction: column;
}

.adminStartPageHeader {
    border-bottom: 1px solid #b2b2b2;
}

.adminStartPageHeader--withRefreshButton {
    display: flex;
    align-items: center;
}

.adminPageLinks {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.adminPageLinks__link {
    margin-bottom: 8px;
}

.adminPageContent__left,
.adminPageContent__right {
    flex: 1;
}

.adminPageContent__left--desktopMargin {
    margin-right: 0;
}

.adminPageContent__right--desktopMargin {
    margin-left: 0;
}

.adminPage__divider {
    height: 1px;
    background-color: var(--grey__mediumLight);
    margin: 16px 0;
}

.adminNewPageButton {
    display: flex;
    justify-content: center;
}

.feedbackLink {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
}

.adminNewPageSuperDamageWaiver {
    margin: 0 0 32px 0;
    position: relative;
    top: -18px;
}

.adminListElementAdd {
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 8px;

    grid-column: span 2;
}

.adminPageActionButtons__heading {
    display: flex;
    margin-bottom: 8px;
    margin-top: 16px;
}
.adminPageActionButtons {
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
}

.adminPageActionButtons__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
}

.adminModalList {
    margin-top: 16px;
    padding: 16px;
    width: 100%;
}
.adminModalList__element {
    margin-bottom: 8px;
}
.adminModalListElement {
    grid-column: span 2;
}

.adminNewElementDateInputsWrapper {
    display: flex;
    flex-direction: column;
}

.adminPageTwoInputs {
    display: flex;
    flex-direction: column;
}

.adminPageInput--monospace textarea {
    font-family: monospace, monospace;
}

.adminPageInput--carServiceIntervalKm {
    margin-right: 4px;
}

.adminPageDateInputWrapper {
    width: initial;
    margin-right: 32px;
}

.adminCarProperties {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding: 16px;
}

.adminCarProperties__grouping {
    flex: 1;
    overflow: auto;
}

.adminCarProperties__grouping--overflowVisible {
    overflow: visible;
}

.adminPageContent__damage {
    margin-bottom: 24px;
}
.adminReservationDamage__header {
    margin-bottom: 8px;
}

.adminPage__check {
    display: flex;
}

.adminReservationProblemCheck {
    margin-left: 16px;
    display: flex;
    align-items: center;
}

.adminReservationProblemCheck__checkLabel {
    margin-top: 2px;
}

.adminReservationProblemCheck__checkLabel,
.adminReservationDamage__checkLabel {
    margin-left: 8px;
}

.adminCarIcon {
    display: flex;
    align-items: center;
}
.adminCarIcon p {
    margin-left: 16px;
}

.adminPageCheck {
    margin-bottom: 20px;
}

.adminPage__carServiceInterval {
    display: flex;
    flex-direction: column;
}

.adminNewCar_successBannerWrapper {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
}

.adminNewCar_successBanner {
    padding: 8px 32px;
    border: 1px solid;
    transition: opacity 0.5s;
    -webkit-transition: opacity 0.5s; /* Safari */
    border-radius: 3px;
    z-index: 40;

    font-size: 1.5rem;
    cursor: pointer;
}

.adminNewCar_successBanner--hidden {
    opacity: 0;
    pointer-events: none;
}

.adminNewCar_successBanner--visible {
    opacity: 1;
}

.adminNewCar_mainButtonContainer .adminNewPageButton {
    margin: 0 16px 0 0;
}

.adminPageContent__billingLineHeader {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}

.adminPageContent__billingLineHeader .button_iconWithLink {
    margin-left: 8px;
}

.adminPageContent__billingLineCell {
    padding: 4px 8px;
    margin-top: 4px;
    border-top: 1px solid;
    border-bottom: 1px solid;
}

.adminPageContent__billingLineCell--header {
    border: none;
}

.adminPageContent__billingSumFooterCell {
    margin: 8px 8px;
}

.adminPageContent__editBillingLink {
    text-align: left;
    padding: 4px 8px;
    display: flex;
    align-items: flex-end;
    /*justify-content: flex-end;*/
}

.adminPAge__loaderWrapper {
    height: 30px;
    width: 30px;
    margin: 0 auto;
}

.adminPageConflict {
    margin-top: 8px;
    padding: 8px;
    display: grid;
    grid-template-areas: 'part1 part2';
    grid-template-columns: 1fr 3fr;
    text-decoration: none;
    border-radius: 4px;
}

.adminPageConflict__part1 {
    grid-area: part1;
}

.adminPageConflict__part2 {
    grid-area: part2;
}

.adminInvoiceCollapse {
    position: relative;
    width: 100%;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.adminInvoiceInfoText {
    position: relative;
    left: 0;
    top: -1.5rem;
}

.adminChangesCollapse {
    position: relative;
    width: 100%;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.adminPageOrgInfo p {
    margin: 0.5rem 0;
}

.adminChangesInfoText {
    position: relative;
    left: 0;
}

.adminExternalIdCardsPending header {
    margin-bottom: 8px;
}

.adminExternalIdCardsPending .adminExternalIdCardsPending__element {
    margin-bottom: 8px;
    padding: 6px 0;
}

.adminExternalIdCardsPending .adminExternalIdCardsPending__element a {
    background-color: var(--softer-orange) !important;
    color: var(--typographyBlack);
    text-decoration: none;
    padding: 8px;
    border-radius: 4px;
}

.adminExternalIdCardsPending .adminExternalIdCardsPending__element a:hover {
    background-color: var(--soft-orange) !important;
}

@media (max-width: 500px) {
    .adminPageConflict {
        grid-template-areas: 'part1' 'part2';
        grid-template-columns: 1fr;
        row-gap: 4px;
    }

    .adminPageConflict__part1 > * {
        display: inline;
        margin-right: 1ex;
    }
}

@media (max-width: 600px) {
    .adminNewCar_successBanner {
        padding: 8px 16px;
        font-size: 16px;
    }
}

@media (max-width: 420px) {
    .adminNewCar_successBanner {
        padding: 8px 8px;
        font-size: 12px;
    }

    .adminNewCar_mainButtonContainer {
        flex-direction: column;
    }

    .adminNewCar_mainButtonContainer .adminNewPageButton {
        margin: 0 0 8px 0;
    }
}

@media (min-width: 1136px) {
    .adminPage {
        padding: 16px 32px 32px 32px;
    }

    .adminPage .adminStartPageHeader {
        min-height: 34px;
    }

    .adminPageWrapper {
        min-height: calc(100vh - 56px);
        padding-top: 48px;
    }

    .adminPageWrapper--fullScreen {
        top: unset;
        z-index: 0;
    }

    /*.adminPageWrapper--fullScreen {*/
    /*min-height: calc(100vh);*/
    /*position: absolute;*/
    /*z-index: 2;*/
    /*top: 0;*/
    /*left: 0;*/
    /*right: 0;*/
    /*background-color: white;*/
    /*padding-top: 0;*/
    /*}*/
    .adminPageContent {
        flex-direction: row;
    }
    .adminPageContent__left--desktopMargin {
        margin-right: 16px;
    }
    .adminPageContent__right--desktopMargin {
        margin-left: 16px;
    }
    .adminNewElementDateInputsWrapper {
        flex-direction: row;
    }
    .adminCarProperties {
        flex-direction: row;
    }
    .adminPageTwoInputs {
        flex-direction: row;
    }

    .adminPage__carServiceInterval {
        flex-direction: row;
    }

    .adminNewCar_successBannerWrapper {
        top: 68px;
        right: 32px;
    }

    .adminNewCar_successBanner {
        padding: 16px 32px;
    }
}
