.reservationMessageListElement {
    display: flex;
    cursor: pointer;
}

.reservationMessageListElement__picture {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid white;
}

.reservationMessageListElement__textWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 8px;
}

.reservationListInfoElementWrapper {
    margin-bottom: 16px;
}
