.carList__carLocationHeader {
    width: 100%;
    min-height: 40px;
    display: flex;
    flex-direction: row;

    padding: 0.2rem 0.4rem;
    background-color: rgb(238 235 244);

    align-items: center;
}

.carList__carLocationHeaderText {
    width: calc((100% - 1.6rem) - 116px);
}

.carList__carLocationHeaderName {
    font-size: 0.8rem;
    padding-right: 0.5rem;
    text-transform: uppercase;
}

.carList__locationIconWrapper {
    width: 1.6rem;
    display: flex;
    justify-content: center;
}

.carList__locationIcon {
    height: 1.2rem;
    margin: 0;
}

.carList__listItemsWrapper {
    position: relative;
    z-index: 0;
    padding-bottom: 160px;
    flex: 1;
    background: white;
}

.carList__noCarsFeedback {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.carList__noCarsFeedbackText {
    width: 70%;
    position: fixed;
    top: 50%;
}

.carList__carLocationHeader--sticky {
    position: -webkit-sticky;
    position: sticky;
    top: calc(170px + var(--messageBarHeight));

    z-index: 2;
}

.carList__carLocationHeader {
}

.carList__loaderWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25%;
}

/*DESKTOP*/
@media (min-width: 1136px) {
    .carList__carLocationHeader {
        top: 88px;
    }
    .carList__listItemsWrapper {
        padding-bottom: 0;
        border: 2px solid var(--grey__light);
        margin-top: 32px;
    }

    .carList__noCarsFeedbackText {
        width: 260px;
        position: fixed;
        top: 20%;
    }
}
