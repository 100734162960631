.render_if_authorized {
    display: inline-block;
}

.is_authorized {
    border: solid 0.08rem lightgreen;
}

.is_unauthorized {
    border: solid 2px salmon;
}

.is_disabled {
    border: solid 2px yellow;
}
