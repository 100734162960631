.reservationBillingOverview {
    display: flex;
    flex-direction: column;
}

.reservationBillingOverview .closeModalButton {
    z-index: 2;
}

.reservationBillingOverview__header {
    text-align: center;
    margin: 15px;
}

.reservationBillingOverview__header h4 {
    margin: 0;
}

.reservationBillingOverview__content {
    flex: 1;
    overflow: auto;
    padding: 8px 20px;
}

.reservationBillingOverviewInvoiceLine {
    min-height: 56px;
    margin-bottom: 8px;
    background-color: white;
    padding: 8px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reservationBillingOverviewInvoiceLine__descriptionAndTotal {
    display: flex;
    justify-content: space-between;
}

.reservationBillingOverviewInvoiceLine__description {
    font-weight: bold;
}

.reservationBillingOverview__footer {
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
    font-weight: bold;
}

@media (min-width: 1136px) {
    .reservationBillingOverview {
        overflow: auto;
    }
}
