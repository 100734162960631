.editReservation {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.editReservation__header {
    z-index: 2;
}

.editReservation__body {
    height: 100%;
}

@media (min-width: 1136px) {
    .editReservation {
        overflow: auto;
    }
}
